/*
// ANCHOR: variabili farmerbit
*/
/**
* Grid Mixin
*
* Applies a flexbox grid to a container element. All direct children will be modified to create a column based grid.
*/
@use "sass:math";
/*
// ANCHOR: Font size
*/
.font-10, .font-10 * {
  font-size: 1rem;
  line-height: calc(1.325rem + 0.45vw); }
  @media (min-width: 1200px) {
    .font-10, .font-10 * {
      line-height: 2rem; } }

.font-11, .font-11 * {
  font-size: 1.1rem;
  line-height: calc(1.335rem + 0.51vw); }
  @media (min-width: 1200px) {
    .font-11, .font-11 * {
      line-height: 2.1rem; } }

.font-12, .font-12 * {
  font-size: 1.2rem;
  line-height: calc(1.345rem + 0.57vw); }
  @media (min-width: 1200px) {
    .font-12, .font-12 * {
      line-height: 2.2rem; } }

.font-13, .font-13 * {
  font-size: calc(1.255rem + 0.03vw);
  line-height: calc(1.355rem + 0.63vw); }
  @media (min-width: 1200px) {
    .font-13, .font-13 * {
      font-size: 1.3rem; } }
  @media (min-width: 1200px) {
    .font-13, .font-13 * {
      line-height: 2.3rem; } }

.font-14, .font-14 * {
  font-size: calc(1.265rem + 0.09vw);
  line-height: calc(1.365rem + 0.69vw); }
  @media (min-width: 1200px) {
    .font-14, .font-14 * {
      font-size: 1.4rem; } }
  @media (min-width: 1200px) {
    .font-14, .font-14 * {
      line-height: 2.4rem; } }

.font-15, .font-15 * {
  font-size: calc(1.275rem + 0.15vw);
  line-height: calc(1.375rem + 0.75vw); }
  @media (min-width: 1200px) {
    .font-15, .font-15 * {
      font-size: 1.5rem; } }
  @media (min-width: 1200px) {
    .font-15, .font-15 * {
      line-height: 2.5rem; } }

.font-17, .font-17 * {
  font-size: calc(1.295rem + 0.27vw);
  line-height: calc(1.395rem + 0.87vw); }
  @media (min-width: 1200px) {
    .font-17, .font-17 * {
      font-size: 1.7rem; } }
  @media (min-width: 1200px) {
    .font-17, .font-17 * {
      line-height: 2.7rem; } }

.font-18, .font-18 * {
  font-size: calc(1.305rem + 0.33vw);
  line-height: calc(1.405rem + 0.93vw); }
  @media (min-width: 1200px) {
    .font-18, .font-18 * {
      font-size: 1.8rem; } }
  @media (min-width: 1200px) {
    .font-18, .font-18 * {
      line-height: 2.8rem; } }

.font-19, .font-19 * {
  font-size: calc(1.315rem + 0.39vw);
  line-height: calc(1.415rem + 0.99vw); }
  @media (min-width: 1200px) {
    .font-19, .font-19 * {
      font-size: 1.9rem; } }
  @media (min-width: 1200px) {
    .font-19, .font-19 * {
      line-height: 2.9rem; } }

.font-20, .font-20 * {
  font-size: calc(1.325rem + 0.45vw);
  line-height: calc(1.425rem + 1.05vw); }
  @media (min-width: 1200px) {
    .font-20, .font-20 * {
      font-size: 2rem; } }
  @media (min-width: 1200px) {
    .font-20, .font-20 * {
      line-height: 3rem; } }
  @media (max-width: 991px) {
    .font-20, .font-20 * {
      font-size: 1.75rem;
      line-height: 2.75rem; } }

.font-21, .font-21 * {
  font-size: calc(1.335rem + 0.51vw);
  line-height: calc(1.435rem + 1.11vw); }
  @media (min-width: 1200px) {
    .font-21, .font-21 * {
      font-size: 2.1rem; } }
  @media (min-width: 1200px) {
    .font-21, .font-21 * {
      line-height: 3.1rem; } }

.font-22, .font-22 * {
  font-size: calc(1.345rem + 0.57vw);
  line-height: calc(1.445rem + 1.17vw); }
  @media (min-width: 1200px) {
    .font-22, .font-22 * {
      font-size: 2.2rem; } }
  @media (min-width: 1200px) {
    .font-22, .font-22 * {
      line-height: 3.2rem; } }

.font-23, .font-23 * {
  font-size: calc(1.355rem + 0.63vw);
  line-height: calc(1.455rem + 1.23vw); }
  @media (min-width: 1200px) {
    .font-23, .font-23 * {
      font-size: 2.3rem; } }
  @media (min-width: 1200px) {
    .font-23, .font-23 * {
      line-height: 3.3rem; } }

.font-24, .font-24 * {
  font-size: calc(1.365rem + 0.69vw);
  line-height: calc(1.465rem + 1.29vw); }
  @media (min-width: 1200px) {
    .font-24, .font-24 * {
      font-size: 2.4rem; } }
  @media (min-width: 1200px) {
    .font-24, .font-24 * {
      line-height: 3.4rem; } }

.font-25, .font-25 * {
  font-size: calc(1.375rem + 0.75vw);
  line-height: calc(1.475rem + 1.35vw); }
  @media (min-width: 1200px) {
    .font-25, .font-25 * {
      font-size: 2.5rem; } }
  @media (min-width: 1200px) {
    .font-25, .font-25 * {
      line-height: 3.5rem; } }

.font-26, .font-26 * {
  font-size: calc(1.385rem + 0.81vw);
  line-height: calc(1.485rem + 1.41vw); }
  @media (min-width: 1200px) {
    .font-26, .font-26 * {
      font-size: 2.6rem; } }
  @media (min-width: 1200px) {
    .font-26, .font-26 * {
      line-height: 3.6rem; } }

.font-27, .font-27 * {
  font-size: calc(1.395rem + 0.87vw);
  line-height: calc(1.495rem + 1.47vw); }
  @media (min-width: 1200px) {
    .font-27, .font-27 * {
      font-size: 2.7rem; } }
  @media (min-width: 1200px) {
    .font-27, .font-27 * {
      line-height: 3.7rem; } }

.font-28, .font-28 * {
  font-size: calc(1.405rem + 0.93vw);
  line-height: calc(1.505rem + 1.53vw); }
  @media (min-width: 1200px) {
    .font-28, .font-28 * {
      font-size: 2.8rem; } }
  @media (min-width: 1200px) {
    .font-28, .font-28 * {
      line-height: 3.8rem; } }

.font-29, .font-29 * {
  font-size: calc(1.415rem + 0.99vw);
  line-height: calc(1.515rem + 1.59vw); }
  @media (min-width: 1200px) {
    .font-29, .font-29 * {
      font-size: 2.9rem; } }
  @media (min-width: 1200px) {
    .font-29, .font-29 * {
      line-height: 3.9rem; } }

.font-30, .font-30 * {
  font-size: calc(1.425rem + 1.05vw);
  line-height: calc(1.525rem + 1.65vw); }
  @media (min-width: 1200px) {
    .font-30, .font-30 * {
      font-size: 3rem; } }
  @media (min-width: 1200px) {
    .font-30, .font-30 * {
      line-height: 4rem; } }

.font-31, .font-31 * {
  font-size: calc(1.435rem + 1.11vw);
  line-height: calc(1.535rem + 1.71vw); }
  @media (min-width: 1200px) {
    .font-31, .font-31 * {
      font-size: 3.1rem; } }
  @media (min-width: 1200px) {
    .font-31, .font-31 * {
      line-height: 4.1rem; } }

.font-32, .font-32 * {
  font-size: calc(1.445rem + 1.17vw);
  line-height: calc(1.545rem + 1.77vw); }
  @media (min-width: 1200px) {
    .font-32, .font-32 * {
      font-size: 3.2rem; } }
  @media (min-width: 1200px) {
    .font-32, .font-32 * {
      line-height: 4.2rem; } }

.font-33, .font-33 * {
  font-size: calc(1.455rem + 1.23vw);
  line-height: calc(1.555rem + 1.83vw); }
  @media (min-width: 1200px) {
    .font-33, .font-33 * {
      font-size: 3.3rem; } }
  @media (min-width: 1200px) {
    .font-33, .font-33 * {
      line-height: 4.3rem; } }

.font-34, .font-34 * {
  font-size: calc(1.465rem + 1.29vw);
  line-height: calc(1.565rem + 1.89vw); }
  @media (min-width: 1200px) {
    .font-34, .font-34 * {
      font-size: 3.4rem; } }
  @media (min-width: 1200px) {
    .font-34, .font-34 * {
      line-height: 4.4rem; } }

.font-35, .font-35 * {
  font-size: calc(1.475rem + 1.35vw);
  line-height: calc(1.575rem + 1.95vw); }
  @media (min-width: 1200px) {
    .font-35, .font-35 * {
      font-size: 3.5rem; } }
  @media (min-width: 1200px) {
    .font-35, .font-35 * {
      line-height: 4.5rem; } }

.font-36, .font-36 * {
  font-size: calc(1.485rem + 1.41vw);
  line-height: calc(1.585rem + 2.01vw); }
  @media (min-width: 1200px) {
    .font-36, .font-36 * {
      font-size: 3.6rem; } }
  @media (min-width: 1200px) {
    .font-36, .font-36 * {
      line-height: 4.6rem; } }

.font-37, .font-37 * {
  font-size: calc(1.495rem + 1.47vw);
  line-height: calc(1.595rem + 2.07vw); }
  @media (min-width: 1200px) {
    .font-37, .font-37 * {
      font-size: 3.7rem; } }
  @media (min-width: 1200px) {
    .font-37, .font-37 * {
      line-height: 4.7rem; } }

.font-38, .font-38 * {
  font-size: calc(1.505rem + 1.53vw);
  line-height: calc(1.605rem + 2.13vw); }
  @media (min-width: 1200px) {
    .font-38, .font-38 * {
      font-size: 3.8rem; } }
  @media (min-width: 1200px) {
    .font-38, .font-38 * {
      line-height: 4.8rem; } }

.font-39, .font-39 * {
  font-size: calc(1.515rem + 1.59vw);
  line-height: calc(1.615rem + 2.19vw); }
  @media (min-width: 1200px) {
    .font-39, .font-39 * {
      font-size: 3.9rem; } }
  @media (min-width: 1200px) {
    .font-39, .font-39 * {
      line-height: 4.9rem; } }

.font-40, .font-40 * {
  font-size: calc(1.525rem + 1.65vw);
  line-height: calc(1.625rem + 2.25vw); }
  @media (min-width: 1200px) {
    .font-40, .font-40 * {
      font-size: 4rem; } }
  @media (min-width: 1200px) {
    .font-40, .font-40 * {
      line-height: 5rem; } }

.font-41, .font-41 * {
  font-size: calc(1.535rem + 1.71vw);
  line-height: calc(1.635rem + 2.31vw); }
  @media (min-width: 1200px) {
    .font-41, .font-41 * {
      font-size: 4.1rem; } }
  @media (min-width: 1200px) {
    .font-41, .font-41 * {
      line-height: 5.1rem; } }

.font-42, .font-42 * {
  font-size: calc(1.545rem + 1.77vw);
  line-height: calc(1.645rem + 2.37vw); }
  @media (min-width: 1200px) {
    .font-42, .font-42 * {
      font-size: 4.2rem; } }
  @media (min-width: 1200px) {
    .font-42, .font-42 * {
      line-height: 5.2rem; } }

.font-43, .font-43 * {
  font-size: calc(1.555rem + 1.83vw);
  line-height: calc(1.655rem + 2.43vw); }
  @media (min-width: 1200px) {
    .font-43, .font-43 * {
      font-size: 4.3rem; } }
  @media (min-width: 1200px) {
    .font-43, .font-43 * {
      line-height: 5.3rem; } }

.font-44, .font-44 * {
  font-size: calc(1.565rem + 1.89vw);
  line-height: calc(1.665rem + 2.49vw); }
  @media (min-width: 1200px) {
    .font-44, .font-44 * {
      font-size: 4.4rem; } }
  @media (min-width: 1200px) {
    .font-44, .font-44 * {
      line-height: 5.4rem; } }

.font-45, .font-45 * {
  font-size: calc(1.575rem + 1.95vw);
  line-height: calc(1.675rem + 2.55vw); }
  @media (min-width: 1200px) {
    .font-45, .font-45 * {
      font-size: 4.5rem; } }
  @media (min-width: 1200px) {
    .font-45, .font-45 * {
      line-height: 5.5rem; } }

.font-46, .font-46 * {
  font-size: calc(1.585rem + 2.01vw);
  line-height: calc(1.685rem + 2.61vw); }
  @media (min-width: 1200px) {
    .font-46, .font-46 * {
      font-size: 4.6rem; } }
  @media (min-width: 1200px) {
    .font-46, .font-46 * {
      line-height: 5.6rem; } }

.font-47, .font-47 * {
  font-size: calc(1.595rem + 2.07vw);
  line-height: calc(1.695rem + 2.67vw); }
  @media (min-width: 1200px) {
    .font-47, .font-47 * {
      font-size: 4.7rem; } }
  @media (min-width: 1200px) {
    .font-47, .font-47 * {
      line-height: 5.7rem; } }

.font-48, .font-48 * {
  font-size: calc(1.605rem + 2.13vw);
  line-height: calc(1.705rem + 2.73vw); }
  @media (min-width: 1200px) {
    .font-48, .font-48 * {
      font-size: 4.8rem; } }
  @media (min-width: 1200px) {
    .font-48, .font-48 * {
      line-height: 5.8rem; } }

.font-49, .font-49 * {
  font-size: calc(1.615rem + 2.19vw);
  line-height: calc(1.715rem + 2.79vw); }
  @media (min-width: 1200px) {
    .font-49, .font-49 * {
      font-size: 4.9rem; } }
  @media (min-width: 1200px) {
    .font-49, .font-49 * {
      line-height: 5.9rem; } }

.font-50, .font-50 * {
  font-size: calc(1.625rem + 2.25vw);
  line-height: calc(1.725rem + 2.85vw); }
  @media (min-width: 1200px) {
    .font-50, .font-50 * {
      font-size: 5rem; } }
  @media (min-width: 1200px) {
    .font-50, .font-50 * {
      line-height: 6rem; } }

.font-51, .font-51 * {
  font-size: calc(1.635rem + 2.31vw);
  line-height: calc(1.735rem + 2.91vw); }
  @media (min-width: 1200px) {
    .font-51, .font-51 * {
      font-size: 5.1rem; } }
  @media (min-width: 1200px) {
    .font-51, .font-51 * {
      line-height: 6.1rem; } }

.font-52, .font-52 * {
  font-size: calc(1.645rem + 2.37vw);
  line-height: calc(1.745rem + 2.97vw); }
  @media (min-width: 1200px) {
    .font-52, .font-52 * {
      font-size: 5.2rem; } }
  @media (min-width: 1200px) {
    .font-52, .font-52 * {
      line-height: 6.2rem; } }

.font-53, .font-53 * {
  font-size: calc(1.655rem + 2.43vw);
  line-height: calc(1.755rem + 3.03vw); }
  @media (min-width: 1200px) {
    .font-53, .font-53 * {
      font-size: 5.3rem; } }
  @media (min-width: 1200px) {
    .font-53, .font-53 * {
      line-height: 6.3rem; } }

.font-54, .font-54 * {
  font-size: calc(1.665rem + 2.49vw);
  line-height: calc(1.765rem + 3.09vw); }
  @media (min-width: 1200px) {
    .font-54, .font-54 * {
      font-size: 5.4rem; } }
  @media (min-width: 1200px) {
    .font-54, .font-54 * {
      line-height: 6.4rem; } }

.font-55, .font-55 * {
  font-size: calc(1.675rem + 2.55vw);
  line-height: calc(1.775rem + 3.15vw); }
  @media (min-width: 1200px) {
    .font-55, .font-55 * {
      font-size: 5.5rem; } }
  @media (min-width: 1200px) {
    .font-55, .font-55 * {
      line-height: 6.5rem; } }

.font-56, .font-56 * {
  font-size: calc(1.685rem + 2.61vw);
  line-height: calc(1.785rem + 3.21vw); }
  @media (min-width: 1200px) {
    .font-56, .font-56 * {
      font-size: 5.6rem; } }
  @media (min-width: 1200px) {
    .font-56, .font-56 * {
      line-height: 6.6rem; } }

.font-57, .font-57 * {
  font-size: calc(1.695rem + 2.67vw);
  line-height: calc(1.795rem + 3.27vw); }
  @media (min-width: 1200px) {
    .font-57, .font-57 * {
      font-size: 5.7rem; } }
  @media (min-width: 1200px) {
    .font-57, .font-57 * {
      line-height: 6.7rem; } }

.font-58, .font-58 * {
  font-size: calc(1.705rem + 2.73vw);
  line-height: calc(1.805rem + 3.33vw); }
  @media (min-width: 1200px) {
    .font-58, .font-58 * {
      font-size: 5.8rem; } }
  @media (min-width: 1200px) {
    .font-58, .font-58 * {
      line-height: 6.8rem; } }

.font-59, .font-59 * {
  font-size: calc(1.715rem + 2.79vw);
  line-height: calc(1.815rem + 3.39vw); }
  @media (min-width: 1200px) {
    .font-59, .font-59 * {
      font-size: 5.9rem; } }
  @media (min-width: 1200px) {
    .font-59, .font-59 * {
      line-height: 6.9rem; } }

.font-60, .font-60 * {
  font-size: calc(1.725rem + 2.85vw);
  line-height: calc(1.825rem + 3.45vw); }
  @media (min-width: 1200px) {
    .font-60, .font-60 * {
      font-size: 6rem; } }
  @media (min-width: 1200px) {
    .font-60, .font-60 * {
      line-height: 7rem; } }

.font-61, .font-61 * {
  font-size: calc(1.735rem + 2.91vw);
  line-height: calc(1.835rem + 3.51vw); }
  @media (min-width: 1200px) {
    .font-61, .font-61 * {
      font-size: 6.1rem; } }
  @media (min-width: 1200px) {
    .font-61, .font-61 * {
      line-height: 7.1rem; } }

.font-62, .font-62 * {
  font-size: calc(1.745rem + 2.97vw);
  line-height: calc(1.845rem + 3.57vw); }
  @media (min-width: 1200px) {
    .font-62, .font-62 * {
      font-size: 6.2rem; } }
  @media (min-width: 1200px) {
    .font-62, .font-62 * {
      line-height: 7.2rem; } }

.font-63, .font-63 * {
  font-size: calc(1.755rem + 3.03vw);
  line-height: calc(1.855rem + 3.63vw); }
  @media (min-width: 1200px) {
    .font-63, .font-63 * {
      font-size: 6.3rem; } }
  @media (min-width: 1200px) {
    .font-63, .font-63 * {
      line-height: 7.3rem; } }

.font-64, .font-64 * {
  font-size: calc(1.765rem + 3.09vw);
  line-height: calc(1.865rem + 3.69vw); }
  @media (min-width: 1200px) {
    .font-64, .font-64 * {
      font-size: 6.4rem; } }
  @media (min-width: 1200px) {
    .font-64, .font-64 * {
      line-height: 7.4rem; } }

.font-65, .font-65 * {
  font-size: calc(1.775rem + 3.15vw);
  line-height: calc(1.875rem + 3.75vw); }
  @media (min-width: 1200px) {
    .font-65, .font-65 * {
      font-size: 6.5rem; } }
  @media (min-width: 1200px) {
    .font-65, .font-65 * {
      line-height: 7.5rem; } }

.font-66, .font-66 * {
  font-size: calc(1.785rem + 3.21vw);
  line-height: calc(1.885rem + 3.81vw); }
  @media (min-width: 1200px) {
    .font-66, .font-66 * {
      font-size: 6.6rem; } }
  @media (min-width: 1200px) {
    .font-66, .font-66 * {
      line-height: 7.6rem; } }

.font-67, .font-67 * {
  font-size: calc(1.795rem + 3.27vw);
  line-height: calc(1.895rem + 3.87vw); }
  @media (min-width: 1200px) {
    .font-67, .font-67 * {
      font-size: 6.7rem; } }
  @media (min-width: 1200px) {
    .font-67, .font-67 * {
      line-height: 7.7rem; } }

.font-68, .font-68 * {
  font-size: calc(1.805rem + 3.33vw);
  line-height: calc(1.905rem + 3.93vw); }
  @media (min-width: 1200px) {
    .font-68, .font-68 * {
      font-size: 6.8rem; } }
  @media (min-width: 1200px) {
    .font-68, .font-68 * {
      line-height: 7.8rem; } }

.font-69, .font-69 * {
  font-size: calc(1.815rem + 3.39vw);
  line-height: calc(1.915rem + 3.99vw); }
  @media (min-width: 1200px) {
    .font-69, .font-69 * {
      font-size: 6.9rem; } }
  @media (min-width: 1200px) {
    .font-69, .font-69 * {
      line-height: 7.9rem; } }

.font-70, .font-70 * {
  font-size: calc(1.825rem + 3.45vw);
  line-height: calc(1.925rem + 4.05vw); }
  @media (min-width: 1200px) {
    .font-70, .font-70 * {
      font-size: 7rem; } }
  @media (min-width: 1200px) {
    .font-70, .font-70 * {
      line-height: 8rem; } }

.font-71, .font-71 * {
  font-size: calc(1.835rem + 3.51vw);
  line-height: calc(1.935rem + 4.11vw); }
  @media (min-width: 1200px) {
    .font-71, .font-71 * {
      font-size: 7.1rem; } }
  @media (min-width: 1200px) {
    .font-71, .font-71 * {
      line-height: 8.1rem; } }

.font-72, .font-72 * {
  font-size: calc(1.845rem + 3.57vw);
  line-height: calc(1.945rem + 4.17vw); }
  @media (min-width: 1200px) {
    .font-72, .font-72 * {
      font-size: 7.2rem; } }
  @media (min-width: 1200px) {
    .font-72, .font-72 * {
      line-height: 8.2rem; } }

.font-73, .font-73 * {
  font-size: calc(1.855rem + 3.63vw);
  line-height: calc(1.955rem + 4.23vw); }
  @media (min-width: 1200px) {
    .font-73, .font-73 * {
      font-size: 7.3rem; } }
  @media (min-width: 1200px) {
    .font-73, .font-73 * {
      line-height: 8.3rem; } }

.font-74, .font-74 * {
  font-size: calc(1.865rem + 3.69vw);
  line-height: calc(1.965rem + 4.29vw); }
  @media (min-width: 1200px) {
    .font-74, .font-74 * {
      font-size: 7.4rem; } }
  @media (min-width: 1200px) {
    .font-74, .font-74 * {
      line-height: 8.4rem; } }

.font-75, .font-75 * {
  font-size: calc(1.875rem + 3.75vw);
  line-height: calc(1.975rem + 4.35vw); }
  @media (min-width: 1200px) {
    .font-75, .font-75 * {
      font-size: 7.5rem; } }
  @media (min-width: 1200px) {
    .font-75, .font-75 * {
      line-height: 8.5rem; } }

.font-76, .font-76 * {
  font-size: calc(1.885rem + 3.81vw);
  line-height: calc(1.985rem + 4.41vw); }
  @media (min-width: 1200px) {
    .font-76, .font-76 * {
      font-size: 7.6rem; } }
  @media (min-width: 1200px) {
    .font-76, .font-76 * {
      line-height: 8.6rem; } }

.font-77, .font-77 * {
  font-size: calc(1.895rem + 3.87vw);
  line-height: calc(1.995rem + 4.47vw); }
  @media (min-width: 1200px) {
    .font-77, .font-77 * {
      font-size: 7.7rem; } }
  @media (min-width: 1200px) {
    .font-77, .font-77 * {
      line-height: 8.7rem; } }

.font-78, .font-78 * {
  font-size: calc(1.905rem + 3.93vw);
  line-height: calc(2.005rem + 4.53vw); }
  @media (min-width: 1200px) {
    .font-78, .font-78 * {
      font-size: 7.8rem; } }
  @media (min-width: 1200px) {
    .font-78, .font-78 * {
      line-height: 8.8rem; } }

.font-79, .font-79 * {
  font-size: calc(1.915rem + 3.99vw);
  line-height: calc(2.015rem + 4.59vw); }
  @media (min-width: 1200px) {
    .font-79, .font-79 * {
      font-size: 7.9rem; } }
  @media (min-width: 1200px) {
    .font-79, .font-79 * {
      line-height: 8.9rem; } }

.font-80, .font-80 * {
  font-size: calc(1.925rem + 4.05vw);
  line-height: calc(2.025rem + 4.65vw); }
  @media (min-width: 1200px) {
    .font-80, .font-80 * {
      font-size: 8rem; } }
  @media (min-width: 1200px) {
    .font-80, .font-80 * {
      line-height: 9rem; } }

.font-81, .font-81 * {
  font-size: calc(1.935rem + 4.11vw);
  line-height: calc(2.035rem + 4.71vw); }
  @media (min-width: 1200px) {
    .font-81, .font-81 * {
      font-size: 8.1rem; } }
  @media (min-width: 1200px) {
    .font-81, .font-81 * {
      line-height: 9.1rem; } }

.font-82, .font-82 * {
  font-size: calc(1.945rem + 4.17vw);
  line-height: calc(2.045rem + 4.77vw); }
  @media (min-width: 1200px) {
    .font-82, .font-82 * {
      font-size: 8.2rem; } }
  @media (min-width: 1200px) {
    .font-82, .font-82 * {
      line-height: 9.2rem; } }

.font-83, .font-83 * {
  font-size: calc(1.955rem + 4.23vw);
  line-height: calc(2.055rem + 4.83vw); }
  @media (min-width: 1200px) {
    .font-83, .font-83 * {
      font-size: 8.3rem; } }
  @media (min-width: 1200px) {
    .font-83, .font-83 * {
      line-height: 9.3rem; } }

.font-84, .font-84 * {
  font-size: calc(1.965rem + 4.29vw);
  line-height: calc(2.065rem + 4.89vw); }
  @media (min-width: 1200px) {
    .font-84, .font-84 * {
      font-size: 8.4rem; } }
  @media (min-width: 1200px) {
    .font-84, .font-84 * {
      line-height: 9.4rem; } }

.font-85, .font-85 * {
  font-size: calc(1.975rem + 4.35vw);
  line-height: calc(2.075rem + 4.95vw); }
  @media (min-width: 1200px) {
    .font-85, .font-85 * {
      font-size: 8.5rem; } }
  @media (min-width: 1200px) {
    .font-85, .font-85 * {
      line-height: 9.5rem; } }

.font-86, .font-86 * {
  font-size: calc(1.985rem + 4.41vw);
  line-height: calc(2.085rem + 5.01vw); }
  @media (min-width: 1200px) {
    .font-86, .font-86 * {
      font-size: 8.6rem; } }
  @media (min-width: 1200px) {
    .font-86, .font-86 * {
      line-height: 9.6rem; } }

.font-87, .font-87 * {
  font-size: calc(1.995rem + 4.47vw);
  line-height: calc(2.095rem + 5.07vw); }
  @media (min-width: 1200px) {
    .font-87, .font-87 * {
      font-size: 8.7rem; } }
  @media (min-width: 1200px) {
    .font-87, .font-87 * {
      line-height: 9.7rem; } }

.font-88, .font-88 * {
  font-size: calc(2.005rem + 4.53vw);
  line-height: calc(2.105rem + 5.13vw); }
  @media (min-width: 1200px) {
    .font-88, .font-88 * {
      font-size: 8.8rem; } }
  @media (min-width: 1200px) {
    .font-88, .font-88 * {
      line-height: 9.8rem; } }

.font-89, .font-89 * {
  font-size: calc(2.015rem + 4.59vw);
  line-height: calc(2.115rem + 5.19vw); }
  @media (min-width: 1200px) {
    .font-89, .font-89 * {
      font-size: 8.9rem; } }
  @media (min-width: 1200px) {
    .font-89, .font-89 * {
      line-height: 9.9rem; } }

.font-90, .font-90 * {
  font-size: calc(2.025rem + 4.65vw);
  line-height: calc(2.125rem + 5.25vw); }
  @media (min-width: 1200px) {
    .font-90, .font-90 * {
      font-size: 9rem; } }
  @media (min-width: 1200px) {
    .font-90, .font-90 * {
      line-height: 10rem; } }

.font-91, .font-91 * {
  font-size: calc(2.035rem + 4.71vw);
  line-height: calc(2.135rem + 5.31vw); }
  @media (min-width: 1200px) {
    .font-91, .font-91 * {
      font-size: 9.1rem; } }
  @media (min-width: 1200px) {
    .font-91, .font-91 * {
      line-height: 10.1rem; } }

.font-92, .font-92 * {
  font-size: calc(2.045rem + 4.77vw);
  line-height: calc(2.145rem + 5.37vw); }
  @media (min-width: 1200px) {
    .font-92, .font-92 * {
      font-size: 9.2rem; } }
  @media (min-width: 1200px) {
    .font-92, .font-92 * {
      line-height: 10.2rem; } }

.font-93, .font-93 * {
  font-size: calc(2.055rem + 4.83vw);
  line-height: calc(2.155rem + 5.43vw); }
  @media (min-width: 1200px) {
    .font-93, .font-93 * {
      font-size: 9.3rem; } }
  @media (min-width: 1200px) {
    .font-93, .font-93 * {
      line-height: 10.3rem; } }

.font-94, .font-94 * {
  font-size: calc(2.065rem + 4.89vw);
  line-height: calc(2.165rem + 5.49vw); }
  @media (min-width: 1200px) {
    .font-94, .font-94 * {
      font-size: 9.4rem; } }
  @media (min-width: 1200px) {
    .font-94, .font-94 * {
      line-height: 10.4rem; } }

.font-95, .font-95 * {
  font-size: calc(2.075rem + 4.95vw);
  line-height: calc(2.175rem + 5.55vw); }
  @media (min-width: 1200px) {
    .font-95, .font-95 * {
      font-size: 9.5rem; } }
  @media (min-width: 1200px) {
    .font-95, .font-95 * {
      line-height: 10.5rem; } }

.font-96, .font-96 * {
  font-size: calc(2.085rem + 5.01vw);
  line-height: calc(2.185rem + 5.61vw); }
  @media (min-width: 1200px) {
    .font-96, .font-96 * {
      font-size: 9.6rem; } }
  @media (min-width: 1200px) {
    .font-96, .font-96 * {
      line-height: 10.6rem; } }

.font-97, .font-97 * {
  font-size: calc(2.095rem + 5.07vw);
  line-height: calc(2.195rem + 5.67vw); }
  @media (min-width: 1200px) {
    .font-97, .font-97 * {
      font-size: 9.7rem; } }
  @media (min-width: 1200px) {
    .font-97, .font-97 * {
      line-height: 10.7rem; } }

.font-98, .font-98 * {
  font-size: calc(2.105rem + 5.13vw);
  line-height: calc(2.205rem + 5.73vw); }
  @media (min-width: 1200px) {
    .font-98, .font-98 * {
      font-size: 9.8rem; } }
  @media (min-width: 1200px) {
    .font-98, .font-98 * {
      line-height: 10.8rem; } }

.font-99, .font-99 * {
  font-size: calc(2.115rem + 5.19vw);
  line-height: calc(2.215rem + 5.79vw); }
  @media (min-width: 1200px) {
    .font-99, .font-99 * {
      font-size: 9.9rem; } }
  @media (min-width: 1200px) {
    .font-99, .font-99 * {
      line-height: 10.9rem; } }

.font-100, .font-100 * {
  font-size: calc(2.125rem + 5.25vw);
  line-height: calc(2.225rem + 5.85vw); }
  @media (min-width: 1200px) {
    .font-100, .font-100 * {
      font-size: 10rem; } }
  @media (min-width: 1200px) {
    .font-100, .font-100 * {
      line-height: 11rem; } }

/* END */
.font-title {
  font-family: "Baskerville Regular" !important;
  font-weight: 400 !important; }

/**
* 0.0 Root
*
* Top level styles every element should inherit.
*/
@font-face {
  font-display: swap;
  font-family: "Baskerville Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Baskerville-Bold.woff2") format("woff2"), url("../assets/fonts/Baskerville-Bold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Baskerville Italic";
  font-style: italic;
  font-weight: 400;
  src: url("../assets/fonts/Baskerville-Italic.woff2") format("woff2"), url("../assets/fonts/Baskerville-Italic.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Baskerville Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Baskerville.woff2") format("woff2"), url("../assets/fonts/Baskerville.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Barlow Light";
  font-style: normal;
  font-weight: 300;
  src: url("../assets/fonts/Barlow-Light.woff2") format("woff2"), url("../assets/fonts/Barlow-Light.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Barlow Regular";
  font-style: normal;
  font-weight: 400;
  src: url("../assets/fonts/Barlow-Regular.woff2") format("woff2"), url("../assets/fonts/Barlow-Regular.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Barlow Medium";
  font-style: normal;
  font-weight: 500;
  src: url("../assets/fonts/Barlow-Medium.woff2") format("woff2"), url("../assets/fonts/Barlow-Medium.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Barlow SemiBold";
  font-style: normal;
  font-weight: 600;
  src: url("../assets/fonts/Barlow-SemiBold.woff2") format("woff2"), url("../assets/fonts/Barlow-SemiBold.woff") format("woff"); }

@font-face {
  font-display: swap;
  font-family: "Barlow Bold";
  font-style: normal;
  font-weight: 700;
  src: url("../assets/fonts/Barlow-Bold.woff2") format("woff2"), url("../assets/fonts/Barlow-Bold.woff") format("woff"); }

html {
  background-color: #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  color: #000000;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  height: 100%;
  line-height: 62.5%;
  font-size: 62.5%;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  overflow-wrap: break-word;
  word-wrap: break-word;
  overflow-x: hidden; }
  html *,
  html *::before,
  html *::after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit; }

body {
  min-height: 100%;
  overflow-x: hidden;
  font-size: 1.6rem;
  line-height: 2.6rem; }
  @media (min-width: 768px) {
    body {
      font-size: 1.8rem;
      line-height: 3rem; } }

/**
* 1.0 Typography
*
* Styling all likely used content flow elements.
*/
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
  color: #0b1016;
  line-height: 1.45;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: unset; }
  h1 a,
  h1 a:hover,
  h1 a:focus, h2 a,
  h2 a:hover,
  h2 a:focus, h3 a,
  h3 a:hover,
  h3 a:focus, h4 a,
  h4 a:hover,
  h4 a:focus, h5 a,
  h5 a:hover,
  h5 a:focus, h6 a,
  h6 a:hover,
  h6 a:focus, .h1 a,
  .h1 a:hover,
  .h1 a:focus, .h2 a,
  .h2 a:hover,
  .h2 a:focus, .h3 a,
  .h3 a:hover,
  .h3 a:focus, .h4 a,
  .h4 a:hover,
  .h4 a:focus, .h5 a,
  .h5 a:hover,
  .h5 a:focus, .h6 a,
  .h6 a:hover,
  .h6 a:focus {
    color: inherit;
    text-decoration: none; }

h1,
.h1 {
  font-size: 2.25rem;
  line-height: 1.175; }
  @media (min-width: 480px) {
    h1,
    .h1 {
      font-size: 2.75rem; } }

h2,
.h2 {
  font-size: 1.5rem;
  line-height: 1.25; }
  @media (min-width: 480px) {
    h2,
    .h2 {
      font-size: 2.5rem; } }

h3,
.h3 {
  font-size: 1.125rem; }
  @media (min-width: 480px) {
    h3,
    .h3 {
      font-size: 1.25rem; } }

h4,
.h4 {
  font-size: 1rem; }
  @media (min-width: 480px) {
    h4,
    .h4 {
      font-size: 1.125rem; } }

h5,
.h5 {
  font-size: 1rem;
  font-weight: 400; }

h6,
.h6 {
  font-size: 1rem; }

a {
  color: inherit;
  -webkit-transition: opacity 0.2s ease-out;
  transition: opacity 0.2s ease-out;
  text-decoration: none; }
  a:hover, a:focus {
    opacity: 0.8; }

p,
.paragraph,
table,
figure {
  margin: 0 0 1.2rem; }

hr {
  background-color: #d8d8d8;
  border: 0;
  display: block;
  height: 1px;
  margin: 2.4rem 0; }

img {
  display: block;
  max-width: 100%; }

figcaption {
  font-size: 0.875rem;
  margin-top: 0.625rem; }

code {
  background-color: rgba(0, 0, 0, 0.05);
  display: inline-block;
  padding: 0 0.3rem; }

blockquote,
.blockquote {
  border-left: 4px solid #f96417;
  font-size: 1.125rem;
  font-style: italic;
  font-weight: 400;
  letter-spacing: 0.16px;
  line-height: 1.55;
  margin: 2.4rem auto;
  max-width: 28rem;
  padding: 0.8rem 1.5rem; }
  blockquote p:last-child,
  .blockquote p:last-child {
    margin-bottom: 0; }

/**
* 1.1 Lists & Tables
*
* Default and custom styled list and table elements.
*/
ul,
ol {
  margin: 0 0 1.2rem;
  padding-left: 1.5rem; }
  ul ul,
  ul ol,
  ol ul,
  ol ol {
    margin-bottom: 0; }

/* Icon Lists */
.iconList {
  list-style: none;
  padding: 0; }
  .iconList li {
    margin-bottom: 0.5rem;
    padding-left: 1.5rem;
    position: relative; }
    .iconList li::before {
      background-size: contain;
      content: '';
      display: block;
      height: 1rem;
      left: 0;
      position: absolute;
      top: 0.25rem;
      width: 1rem; }
  .iconList--checkCircle li::before {
    background-image: url("../assets/icons/check-circle.svg"); }

/* Tables */
table {
  border-collapse: collapse;
  border-spacing: 0;
  max-width: 100%; }

td,
th {
  padding: 1.25rem;
  text-align: inherit;
  vertical-align: top; }

thead th {
  border-bottom: 2px solid #f96417;
  color: #0b1016;
  padding-bottom: 10px;
  vertical-align: bottom;
  white-space: nowrap; }

tr:nth-child(even) {
  background-color: #f2f6fe; }

/**
* 1.2 Buttons
*
* Global button elements, including reset on standard button element.
*/
.color-grey {
  color: #484747 !important; }

.section-i {
  margin-bottom: 3rem; }
  @media (min-width: 768px) {
    .section-i {
      margin-bottom: 4rem; } }
  @media (min-width: 992px) {
    .section-i {
      margin-bottom: 5rem; } }
  @media (min-width: 1200px) {
    .section-i {
      margin-bottom: 6rem; } }

@media (max-width: 767px) {
  .section-ii {
    margin-bottom: 3rem; }
  .section-iii {
    margin-bottom: 2.5rem; } }

@media (min-width: 768px) and (max-width: 991px) {
  .section-ii {
    margin-bottom: 3.5rem; }
  .section-iii {
    margin-bottom: 2.5rem; } }

@media (min-width: 992px) {
  .section-ii {
    margin-bottom: 4rem; }
  .section-iii {
    margin-bottom: 3rem; } }

.button-theme {
  background: none;
  display: inline-block;
  z-index: 1;
  position: relative;
  left: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid #000;
  border-top: 2px solid #000;
  border-right: 2px solid #000;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }
  .button-theme span {
    position: relative;
    color: #000;
    display: block;
    font-family: "Barlow Bold";
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: unset;
    padding: 8px 20px;
    letter-spacing: 1.9px;
    top: -1px;
    left: -40px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  .button-theme::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 2px;
    height: 15%;
    background-color: #000;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  .button-theme::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 2px;
    height: 15%;
    background-color: #000;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  @media (max-width: 991px) {
    .button-theme span {
      font-size: 1.1rem;
      padding: 5px 14px;
      letter-spacing: 1.5px;
      left: -28px; } }
  .button-theme:hover {
    left: 0;
    border-bottom: 2px solid #484747;
    border-top: 2px solid #484747;
    border-right: 2px solid #484747; }
    .button-theme:hover span {
      left: 0;
      color: #484747; }
    .button-theme:hover::before {
      height: 50%;
      background-color: #484747; }
    .button-theme:hover::after {
      height: 50%;
      background-color: #484747; }

.button-theme__white {
  background: none;
  display: inline-block;
  z-index: 1;
  position: relative;
  left: 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  border-bottom: 2px solid #fff;
  border-top: 2px solid #fff;
  border-right: 2px solid #fff;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out; }
  .button-theme__white span {
    color: #fff;
    position: relative;
    display: block;
    font-family: "Barlow Bold";
    font-size: 1.4rem;
    text-transform: uppercase;
    line-height: unset;
    padding: 8px 20px;
    letter-spacing: 1.9px;
    top: -1px;
    left: -40px;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  .button-theme__white::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 2px;
    height: 15%;
    background-color: #fff;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  .button-theme__white::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: 0;
    width: 2px;
    height: 15%;
    background-color: #fff;
    -webkit-transition: all .3s ease-out;
    transition: all .3s ease-out; }
  @media (max-width: 991px) {
    .button-theme__white span {
      font-size: 1.1rem;
      padding: 5px 14px;
      letter-spacing: 1.5px;
      left: -28px; } }
  .button-theme__white:hover {
    left: 0;
    border-bottom: 2px solid #ffffffbe;
    border-top: 2px solid #ffffffbe;
    border-right: 2px solid #ffffffbe; }
    .button-theme__white:hover span {
      left: 0;
      color: #ffffffbe; }
    .button-theme__white:hover::before {
      height: 50%;
      background-color: #ffffffbe; }
    .button-theme__white:hover::after {
      height: 50%;
      background-color: #ffffffbe; }

button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  color: inherit;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 0;
  text-align: inherit; }

.button, .ctaFixed .ctaFixed__button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #f96417;
  border: 1px solid #f96417;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: inherit;
  padding: 1rem 1.5rem;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  -webkit-transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
  transition: background-color 0.2s ease, border-color 0.2s ease, color 0.2s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none; }
  .button:disabled, .ctaFixed .ctaFixed__button:disabled, .button--disabled {
    cursor: default;
    opacity: 0.65;
    pointer-events: none; }
  .button:hover, .ctaFixed .ctaFixed__button:hover, .button:focus, .ctaFixed .ctaFixed__button:focus {
    background-color: #091a41;
    border-color: #091a41;
    color: #fff; }
  .button--ghost {
    background-color: transparent;
    border-color: #f96417;
    color: #f96417; }
    .button--ghost:hover, .button--ghost:focus {
      background-color: transparent;
      border-color: #091a41;
      color: #091a41; }
  .button--small {
    font-size: 0.8rem;
    padding: 0.5rem 1rem; }
  .button--link {
    background-color: transparent;
    border: 0;
    color: #f96417;
    padding: 0;
    position: relative;
    text-transform: none;
    -webkit-transition: color 0.2s ease-out;
    transition: color 0.2s ease-out; }
    .button--link:hover, .button--link:focus {
      background-color: transparent;
      color: #091a41; }
      .button--link:hover::before, .button--link:focus::before {
        background-color: #091a41; }
      .button--link:hover::after, .button--link:focus::after {
        background-position: right center; }
    .button--link::before {
      background-color: #f96417;
      bottom: 0;
      content: '';
      height: 1px;
      left: 0;
      position: absolute;
      -webkit-transition: background-color 0.2s ease-out;
      transition: background-color 0.2s ease-out;
      width: 100%; }
    .button--link::after {
      background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.586 5L.293 8.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4A1 1 0 1 0 .293 1.707L3.586 5z' fill='rgb(249, 100, 23)'/%3E%3Cpath d='M13.586 5l-3.293 3.293a1 1 0 0 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L13.586 5z' fill='rgb(9, 26, 65)'/%3E%3C/svg%3E");
      background-position: left center;
      background-repeat: no-repeat;
      content: '';
      display: inline-block;
      height: 10px;
      margin-left: 10px;
      -webkit-transition: background-position 0.2s ease-out;
      transition: background-position 0.2s ease-out;
      vertical-align: baseline;
      width: 6px; }

/* Pills */
.pill {
  background-color: #091a41;
  border-radius: 1rem;
  color: #fff;
  display: inline-block;
  font-size: 0.8rem;
  max-width: 100%;
  overflow: hidden;
  padding: 0.125rem 1rem;
  text-decoration: none;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap; }
  .pill:hover, .pill:focus {
    color: #fff; }

/* Icons */
.feather {
  color: #f96417;
  position: relative;
  top: -2px;
  vertical-align: middle; }

/**
* 1.3 Forms
*
* Global form elements, including structering wrapper elements.
*/
form {
  margin: 0; }

fieldset {
  border: 0;
  margin: 0 0 1.5rem;
  padding: 0; }
  fieldset *:last-child {
    margin-bottom: 0; }
  fieldset input,
  fieldset select,
  fieldset textarea {
    margin: 0 0 1.875rem; }

legend {
  display: inline-block;
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 0.9375rem; }

label {
  display: inline-block;
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0.5rem; }

[type="tel"], [type="url"], [type="text"], [type="date"], [type="email"], [type="search"], [type="number"], [type="password"], textarea, select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #fff;
  border: 1px solid #0b100c;
  border-radius: 0;
  color: #0b1016;
  font-family: inherit;
  font-size: inherit;
  min-height: 50px; }

input,
select,
textarea {
  margin: 0 0 1rem;
  padding: 10px 20px; }

textarea {
  min-height: 150px;
  padding: 20px;
  resize: vertical;
  width: 100%; }

select {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='32' height='32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpolyline fill='none' stroke='rgb(65, 71, 81)' stroke-width='5'  points='2,9 16,25 30,9 '/%3E%3C/svg%3E");
  background-position: calc(100% - 18px) center;
  background-repeat: no-repeat;
  background-size: 9px;
  line-height: 1.75;
  padding-right: 45px; }
  select::-ms-expand {
    display: none; }

[type='checkbox'],
[type='radio'] {
  opacity: 0;
  pointer-events: none;
  position: absolute;
  width: 1px; }
  [type='checkbox'], [type='checkbox']:hover, [type='checkbox']:focus,
  [type='radio'],
  [type='radio']:hover,
  [type='radio']:focus {
    border-color: #d8d8d8;
    outline: 0; }
  [type='checkbox'] + label,
  [type='radio'] + label {
    display: inline-block;
    padding-left: 1.875rem;
    position: relative;
    width: 100%; }
    [type='checkbox'] + label::before,
    [type='radio'] + label::before {
      background-color: #fff;
      border: 1px solid #d8d8d8;
      border-radius: 2px;
      content: '';
      display: block;
      height: 15px;
      left: 0;
      margin: 0;
      padding: 0;
      position: absolute;
      top: 4px;
      -webkit-transition: border-color 0.2s ease, background-color 0.2s ease;
      transition: border-color 0.2s ease, background-color 0.2s ease;
      width: 15px; }
  [type='checkbox']:checked + label::before,
  [type='radio']:checked + label::before {
    background-color: #f96417;
    border-color: #f96417;
    -webkit-box-shadow: inset 0 0 0 2px #fff;
            box-shadow: inset 0 0 0 2px #fff; }

[type='radio'] + label::before {
  border-radius: 50%; }

/**
* 2.0 Helpers
*
* Global layout classes for a consistent appearance.
*/
.pageWrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 100%; }
  .admin-bar .pageWrapper {
    padding-top: 46px; }
    @media (min-width: 782px) {
      .admin-bar .pageWrapper {
        padding-top: 32px; } }

.mainContent {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
          flex: 1 0 auto; }

.flyntComponent {
  position: relative; }

.componentSpacing {
  margin: 40px 0; }
  @media (min-width: 768px) {
    .componentSpacing {
      margin: 80px 0; } }
  @media (min-width: 1280px) {
    .componentSpacing {
      margin: 120px 0; } }
  .componentSpacing[class*='theme'] {
    margin: 0;
    padding: 40px 0; }
    @media (min-width: 768px) {
      .componentSpacing[class*='theme'] {
        padding: 80px 0; } }
    @media (min-width: 1280px) {
      .componentSpacing[class*='theme'] {
        padding: 120px 0; } }

.centerMaxWidthContainer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
  padding-left: 15px;
  padding-right: 15px; }
  @media (min-width: 768px) {
    .centerMaxWidthContainer {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 1440px;
      padding-left: 40px;
      padding-right: 40px; } }
  @media (min-width: 1280px) {
    .centerMaxWidthContainer {
      display: block;
      margin-left: auto;
      margin-right: auto;
      max-width: 1440px;
      padding-left: 60px;
      padding-right: 60px; } }

.centerContentMaxWidth {
  margin: 0 auto;
  max-width: 600px; }

.preContent {
  margin: 0 auto 32px;
  max-width: 600px;
  text-align: center; }
  .preContent > :first-child {
    margin-top: 0;
    padding-top: 0; }
  .preContent > :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  @media (min-width: 768px) {
    .preContent {
      margin-bottom: 40px; } }
  @media (min-width: 1280px) {
    .preContent {
      margin-bottom: 60px; } }

.postContent {
  margin: 32px auto 0;
  max-width: 600px;
  text-align: center; }
  .postContent > :first-child {
    margin-top: 0;
    padding-top: 0; }
  .postContent > :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  @media (min-width: 768px) {
    .postContent {
      margin-top: 40px; } }
  @media (min-width: 1280px) {
    .postContent {
      margin-top: 60px; } }

.scrollableTableWrapper {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto; }

/* Class for text that should only be visible to screen readers */
.visuallyHidden {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  position: absolute !important;
  white-space: nowrap;
  width: 1px !important; }

/* Box Helpers */
.boxPadding {
  padding: 20px; }
  .boxPadding > :first-child {
    margin-top: 0;
    padding-top: 0; }
  .boxPadding > :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  @media (min-width: 1024px) {
    .boxPadding {
      padding: 30px; } }

.boxShadow {
  -webkit-box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06);
          box-shadow: 0 1px 26px 0 rgba(0, 0, 0, 0.06); }

/* Custom styling for single post layouts */
.post-main .componentSpacing {
  margin: 20px 0; }
  @media (min-width: 768px) {
    .post-main .componentSpacing {
      margin: 40px 0; } }
  @media (min-width: 1280px) {
    .post-main .componentSpacing {
      margin: 60px 0; } }
  .post-main .componentSpacing[class*='theme'] {
    margin: 0;
    padding: 20px 0; }
    @media (min-width: 768px) {
      .post-main .componentSpacing[class*='theme'] {
        padding: 40px 0; } }
    @media (min-width: 1280px) {
      .post-main .componentSpacing[class*='theme'] {
        padding: 60px 0; } }

.post-main > .componentSpacing:first-child {
  margin-top: 0; }

.post-main > .componentSpacing:last-child {
  margin-bottom: 0; }

/* Skip link behaviour */
.skipLink {
  position: fixed;
  top: -100px;
  z-index: 999; }
  .skipLink:focus {
    top: 0; }

/**
* 3.0 Themes
*
* Color themes for backgrounds and content elements.
*/
.themeLight {
  background-color: #f2f6fe; }

.themeDark,
.themeDark h1, .themeDark h2, .themeDark h3, .themeDark h4, .themeDark h5, .themeDark h6, .themeDark .h1, .themeDark .h2, .themeDark .h3, .themeDark .h4, .themeDark .h5, .themeDark .h6 {
  color: #fff; }

.themeDark .button:hover, .themeDark .ctaFixed .ctaFixed__button:hover, .ctaFixed .themeDark .ctaFixed__button:hover, .themeDark .button:focus, .themeDark .ctaFixed .ctaFixed__button:focus, .ctaFixed .themeDark .ctaFixed__button:focus {
  background-color: #0d8eff;
  border-color: #0d8eff;
  color: #fff; }

.themeDark .button--ghost:hover, .themeDark .button--ghost:focus {
  background-color: transparent;
  border-color: #0d8eff;
  color: #0d8eff; }

.themeDark .button--link:hover, .themeDark .button--link:focus {
  background-color: transparent;
  color: #fff; }
  .themeDark .button--link:hover::before, .themeDark .button--link:focus::before {
    background-color: #fff; }

.themeDark .button--link::after {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.586 5L.293 8.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4A1 1 0 1 0 .293 1.707L3.586 5z' fill='rgb(249, 100, 23)'/%3E%3Cpath d='M13.586 5l-3.293 3.293a1 1 0 0 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L13.586 5z' fill='rgb(255, 255, 255)'/%3E%3C/svg%3E"); }

.themeDark .pill {
  background-color: #0d8eff; }

.themeHero {
  background-color: #0d8eff; }
  .themeHero,
  .themeHero h1, .themeHero h2, .themeHero h3, .themeHero h4, .themeHero h5, .themeHero h6, .themeHero .h1, .themeHero .h2, .themeHero .h3, .themeHero .h4, .themeHero .h5, .themeHero .h6,
  .themeHero a,
  .themeHero .feather {
    color: #fff; }
  .themeHero a:hover,
  .themeHero a:focus {
    color: #091a41; }
  .themeHero .button, .themeHero .ctaFixed .ctaFixed__button, .ctaFixed .themeHero .ctaFixed__button {
    background-color: #091a41;
    border-color: #091a41;
    color: #fff; }
    .themeHero .button:hover, .themeHero .ctaFixed .ctaFixed__button:hover, .ctaFixed .themeHero .ctaFixed__button:hover, .themeHero .button:focus, .themeHero .ctaFixed .ctaFixed__button:focus, .ctaFixed .themeHero .ctaFixed__button:focus {
      background-color: #fff;
      border-color: #fff;
      color: #091a41; }
    .themeHero .button--ghost {
      background-color: transparent;
      border-color: #091a41;
      color: #091a41; }
      .themeHero .button--ghost:hover, .themeHero .button--ghost:focus {
        background-color: transparent;
        border-color: #fff;
        color: #fff; }
    .themeHero .button--link {
      background-color: transparent;
      color: #fff; }
      .themeHero .button--link::before {
        background-color: #fff; }
      .themeHero .button--link:hover, .themeHero .button--link:focus {
        background-color: transparent;
        color: #091a41; }
        .themeHero .button--link:hover::before, .themeHero .button--link:focus::before {
          background-color: #091a41; }
      .themeHero .button--link::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.586 5L.293 8.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4A1 1 0 1 0 .293 1.707L3.586 5z' fill='rgb(255, 255, 255)'/%3E%3Cpath d='M13.586 5l-3.293 3.293a1 1 0 0 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L13.586 5z' fill='rgb(9, 26, 65)'/%3E%3C/svg%3E"); }
  .themeHero .pill:hover, .themeHero .pill:focus {
    color: #fff; }

.themeReset {
  background-color: #fff;
  color: #000000; }
  .themeReset h1, .themeReset h2, .themeReset h3, .themeReset h4, .themeReset h5, .themeReset h6, .themeReset .h1, .themeReset .h2, .themeReset .h3, .themeReset .h4, .themeReset .h5, .themeReset .h6 {
    color: #0b1016; }
    .themeReset h1 a,
    .themeReset h1 a:hover,
    .themeReset h1 a:focus, .themeReset h2 a,
    .themeReset h2 a:hover,
    .themeReset h2 a:focus, .themeReset h3 a,
    .themeReset h3 a:hover,
    .themeReset h3 a:focus, .themeReset h4 a,
    .themeReset h4 a:hover,
    .themeReset h4 a:focus, .themeReset h5 a,
    .themeReset h5 a:hover,
    .themeReset h5 a:focus, .themeReset h6 a,
    .themeReset h6 a:hover,
    .themeReset h6 a:focus, .themeReset .h1 a,
    .themeReset .h1 a:hover,
    .themeReset .h1 a:focus, .themeReset .h2 a,
    .themeReset .h2 a:hover,
    .themeReset .h2 a:focus, .themeReset .h3 a,
    .themeReset .h3 a:hover,
    .themeReset .h3 a:focus, .themeReset .h4 a,
    .themeReset .h4 a:hover,
    .themeReset .h4 a:focus, .themeReset .h5 a,
    .themeReset .h5 a:hover,
    .themeReset .h5 a:focus, .themeReset .h6 a,
    .themeReset .h6 a:hover,
    .themeReset .h6 a:focus {
      color: #0b1016;
      text-decoration: none; }
  .themeReset a,
  .themeReset .feather {
    color: #f96417; }
  .themeReset a:hover,
  .themeReset a:focus {
    color: #d74d06; }
  .themeReset .button, .themeReset .ctaFixed .ctaFixed__button, .ctaFixed .themeReset .ctaFixed__button {
    background-color: #f96417;
    border-color: #f96417;
    color: #fff; }
    .themeReset .button:hover, .themeReset .ctaFixed .ctaFixed__button:hover, .ctaFixed .themeReset .ctaFixed__button:hover, .themeReset .button:focus, .themeReset .ctaFixed .ctaFixed__button:focus, .ctaFixed .themeReset .ctaFixed__button:focus {
      background-color: #091a41;
      border-color: #091a41;
      color: #fff; }
    .themeReset .button--ghost {
      background-color: transparent;
      border-color: #f96417;
      color: #f96417; }
      .themeReset .button--ghost:hover, .themeReset .button--ghost:focus {
        background-color: transparent;
        border-color: #091a41;
        color: #091a41; }
    .themeReset .button--link {
      background-color: transparent;
      color: #f96417; }
      .themeReset .button--link:hover, .themeReset .button--link:focus {
        background-color: transparent;
        color: #091a41; }
        .themeReset .button--link:hover::before, .themeReset .button--link:focus::before {
          background-color: #091a41; }
      .themeReset .button--link::before {
        background-color: #f96417; }
      .themeReset .button--link::after {
        background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='16' height='10' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.586 5L.293 8.293a1 1 0 1 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4A1 1 0 1 0 .293 1.707L3.586 5z' fill='rgb(249, 100, 23)'/%3E%3Cpath d='M13.586 5l-3.293 3.293a1 1 0 0 0 1.414 1.414l4-4a1 1 0 0 0 0-1.414l-4-4a1 1 0 0 0-1.414 1.414L13.586 5z' fill='rgb(9, 26, 65)'/%3E%3C/svg%3E"); }
  .themeReset .pill {
    color: #fff; }

/*
ANCHOR: Gallery post
*/
.single-post .gallery {
  display: grid;
  gap: 3rem; }
  @media (max-width: 991px) {
    .single-post .gallery {
      gap: 1.5rem;
      grid-template-columns: repeat(1, 1fr); } }
  .single-post .gallery .gallery__link {
    width: 100%;
    max-width: 100%;
    margin: 0;
    position: relative;
    margin-bottom: 0;
    max-width: unset;
    padding-top: 50%;
    width: unset;
    height: 100%; }
    .single-post .gallery .gallery__link img {
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%; }
  .single-post .gallery.gallery-columns-2 {
    grid-template-columns: repeat(2, 1fr); }
  .single-post .gallery .gallery__link:nth-child(4n+2) {
    grid-column-start: 2;
    grid-column-end: 4; }
    @media (max-width: 991px) {
      .single-post .gallery .gallery__link:nth-child(4n+2) {
        grid-column-start: unset;
        grid-column-end: unset; } }
  .single-post .gallery .gallery__link:nth-child(4n+3) {
    grid-column-start: 1;
    grid-column-end: 3; }
    @media (max-width: 991px) {
      .single-post .gallery .gallery__link:nth-child(4n+3) {
        grid-column-start: unset;
        grid-column-end: unset; } }

@media (min-width: 992px) {
  .press .text {
    padding-left: 50px; } }

@media (max-width: 991px) {
  .press .text .grid-posts__tax {
    padding-top: 30px; } }

.img-fluid {
  max-width: 100%;
  height: auto; }

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto; }

.figure {
  display: inline-block; }

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1; }

.figure-caption {
  font-size: 90%;
  color: #6c757d; }

.align-baseline {
  vertical-align: baseline !important; }

.align-top {
  vertical-align: top !important; }

.align-middle {
  vertical-align: middle !important; }

.align-bottom {
  vertical-align: bottom !important; }

.align-text-bottom {
  vertical-align: text-bottom !important; }

.align-text-top {
  vertical-align: text-top !important; }

.bg-primary {
  background-color: #007bff !important; }

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: #0062cc !important; }

.bg-secondary {
  background-color: #6c757d !important; }

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: #545b62 !important; }

.bg-success {
  background-color: #28a745 !important; }

a.bg-success:hover, a.bg-success:focus,
button.bg-success:hover,
button.bg-success:focus {
  background-color: #1e7e34 !important; }

.bg-info {
  background-color: #17a2b8 !important; }

a.bg-info:hover, a.bg-info:focus,
button.bg-info:hover,
button.bg-info:focus {
  background-color: #117a8b !important; }

.bg-warning {
  background-color: #ffc107 !important; }

a.bg-warning:hover, a.bg-warning:focus,
button.bg-warning:hover,
button.bg-warning:focus {
  background-color: #d39e00 !important; }

.bg-danger {
  background-color: #dc3545 !important; }

a.bg-danger:hover, a.bg-danger:focus,
button.bg-danger:hover,
button.bg-danger:focus {
  background-color: #bd2130 !important; }

.bg-light {
  background-color: #f8f9fa !important; }

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: #dae0e5 !important; }

.bg-dark {
  background-color: #343a40 !important; }

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: #1d2124 !important; }

.bg-white {
  background-color: #fff !important; }

.bg-transparent {
  background-color: transparent !important; }

.border {
  border: 1px solid #dee2e6 !important; }

.border-top {
  border-top: 1px solid #dee2e6 !important; }

.border-right {
  border-right: 1px solid #dee2e6 !important; }

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important; }

.border-left {
  border-left: 1px solid #dee2e6 !important; }

.border-0 {
  border: 0 !important; }

.border-top-0 {
  border-top: 0 !important; }

.border-right-0 {
  border-right: 0 !important; }

.border-bottom-0 {
  border-bottom: 0 !important; }

.border-left-0 {
  border-left: 0 !important; }

.border-primary {
  border-color: #007bff !important; }

.border-secondary {
  border-color: #6c757d !important; }

.border-success {
  border-color: #28a745 !important; }

.border-info {
  border-color: #17a2b8 !important; }

.border-warning {
  border-color: #ffc107 !important; }

.border-danger {
  border-color: #dc3545 !important; }

.border-light {
  border-color: #f8f9fa !important; }

.border-dark {
  border-color: #343a40 !important; }

.border-white {
  border-color: #fff !important; }

.rounded-sm {
  border-radius: 0.2rem !important; }

.rounded {
  border-radius: 0.25rem !important; }

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important; }

.rounded-right {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important; }

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-left {
  border-top-left-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important; }

.rounded-lg {
  border-radius: 0.3rem !important; }

.rounded-circle {
  border-radius: 50% !important; }

.rounded-pill {
  border-radius: 50rem !important; }

.rounded-0 {
  border-radius: 0 !important; }

.clearfix::after {
  display: block;
  clear: both;
  content: ""; }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden; }
  .embed-responsive::before {
    display: block;
    content: ""; }
  .embed-responsive .embed-responsive-item,
  .embed-responsive iframe,
  .embed-responsive embed,
  .embed-responsive object,
  .embed-responsive video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0; }

.embed-responsive-21by9::before {
  padding-top: 42.85714%; }

.embed-responsive-16by9::before {
  padding-top: 56.25%; }

.embed-responsive-4by3::before {
  padding-top: 75%; }

.embed-responsive-1by1::before {
  padding-top: 100%; }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.float-left {
  float: left !important; }

.float-right {
  float: right !important; }

.float-none {
  float: none !important; }

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important; }
  .float-sm-right {
    float: right !important; }
  .float-sm-none {
    float: none !important; } }

@media (min-width: 768px) {
  .float-md-left {
    float: left !important; }
  .float-md-right {
    float: right !important; }
  .float-md-none {
    float: none !important; } }

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important; }
  .float-lg-right {
    float: right !important; }
  .float-lg-none {
    float: none !important; } }

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important; }
  .float-xl-right {
    float: right !important; }
  .float-xl-none {
    float: none !important; } }

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
      -ms-user-select: all !important;
          user-select: all !important; }

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important; }

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important; }

.overflow-auto {
  overflow: auto !important; }

.overflow-hidden {
  overflow: hidden !important; }

.position-static {
  position: static !important; }

.position-relative {
  position: relative !important; }

.position-absolute {
  position: absolute !important; }

.position-fixed {
  position: fixed !important; }

.position-sticky {
  position: -webkit-sticky !important;
  position: sticky !important; }

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030; }

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030; }

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sticky-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1020; } }

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0; }

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal; }

.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important; }

.shadow {
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important; }

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
          box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important; }

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important; }

.w-25 {
  width: 25% !important; }

.w-50 {
  width: 50% !important; }

.w-75 {
  width: 75% !important; }

.w-100 {
  width: 100% !important; }

.w-auto {
  width: auto !important; }

.h-25 {
  height: 25% !important; }

.h-50 {
  height: 50% !important; }

.h-75 {
  height: 75% !important; }

.h-100 {
  height: 100% !important; }

.h-auto {
  height: auto !important; }

.mw-100 {
  max-width: 100% !important; }

.mh-100 {
  max-height: 100% !important; }

.min-vw-100 {
  min-width: 100vw !important; }

.min-vh-100 {
  min-height: 100vh !important; }

.vw-100 {
  width: 100vw !important; }

.vh-100 {
  height: 100vh !important; }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0); }

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important; }

.text-justify {
  text-align: justify !important; }

.text-wrap {
  white-space: normal !important; }

.text-nowrap {
  white-space: nowrap !important; }

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.text-left {
  text-align: left !important; }

.text-right {
  text-align: right !important; }

.text-center {
  text-align: center !important; }

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important; }
  .text-sm-right {
    text-align: right !important; }
  .text-sm-center {
    text-align: center !important; } }

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important; }
  .text-md-right {
    text-align: right !important; }
  .text-md-center {
    text-align: center !important; } }

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important; }
  .text-lg-right {
    text-align: right !important; }
  .text-lg-center {
    text-align: center !important; } }

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important; }
  .text-xl-right {
    text-align: right !important; }
  .text-xl-center {
    text-align: center !important; } }

.text-lowercase {
  text-transform: lowercase !important; }

.text-uppercase {
  text-transform: uppercase !important; }

.text-capitalize {
  text-transform: capitalize !important; }

.font-weight-light {
  font-weight: 300 !important; }

.font-weight-lighter {
  font-weight: lighter !important; }

.font-weight-normal {
  font-weight: 400 !important; }

.font-weight-bold {
  font-weight: 700 !important; }

.font-weight-bolder {
  font-weight: bolder !important; }

.font-italic {
  font-style: italic !important; }

.text-white {
  color: #fff !important; }

.text-primary {
  color: #007bff !important; }

a.text-primary:hover, a.text-primary:focus {
  color: #0056b3 !important; }

.text-secondary {
  color: #6c757d !important; }

a.text-secondary:hover, a.text-secondary:focus {
  color: #494f54 !important; }

.text-success {
  color: #28a745 !important; }

a.text-success:hover, a.text-success:focus {
  color: #19692c !important; }

.text-info {
  color: #17a2b8 !important; }

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important; }

.text-warning {
  color: #ffc107 !important; }

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important; }

.text-danger {
  color: #dc3545 !important; }

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important; }

.text-light {
  color: #f8f9fa !important; }

a.text-light:hover, a.text-light:focus {
  color: #cbd3da !important; }

.text-dark {
  color: #343a40 !important; }

a.text-dark:hover, a.text-dark:focus {
  color: #121416 !important; }

.text-body {
  color: #212529 !important; }

.text-muted {
  color: #6c757d !important; }

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0; }

.text-decoration-none {
  text-decoration: none !important; }

.text-break {
  word-break: break-word !important;
  word-wrap: break-word !important; }

.text-reset {
  color: inherit !important; }

.visible {
  visibility: visible !important; }

.invisible {
  visibility: hidden !important; }

/*!
 * Bootstrap Grid v4.5.3 (https://getbootstrap.com/)
 * Copyright 2011-2020 The Bootstrap Authors
 * Copyright 2011-2020 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
html {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -ms-overflow-style: scrollbar; }

*,
*::before,
*::after {
  -webkit-box-sizing: inherit;
          box-sizing: inherit; }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container, .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container, .container-sm, .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container, .container-sm, .container-md, .container-lg {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container, .container-sm, .container-md, .container-lg, .container-xl {
    max-width: 1140px; } }

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px; }

.no-gutters {
  margin-right: 0;
  margin-left: 0; }
  .no-gutters > .col,
  .no-gutters > [class*="col-"] {
    padding-right: 0;
    padding-left: 0; }

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px; }

.col {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  max-width: 100%; }

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 20%;
          flex: 0 0 20%;
  max-width: 20%; }

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
  max-width: 100%; }

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 8.33333%;
          flex: 0 0 8.33333%;
  max-width: 8.33333%; }

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 16.66667%;
          flex: 0 0 16.66667%;
  max-width: 16.66667%; }

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 25%;
          flex: 0 0 25%;
  max-width: 25%; }

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 33.33333%;
          flex: 0 0 33.33333%;
  max-width: 33.33333%; }

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 41.66667%;
          flex: 0 0 41.66667%;
  max-width: 41.66667%; }

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 50%;
          flex: 0 0 50%;
  max-width: 50%; }

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 58.33333%;
          flex: 0 0 58.33333%;
  max-width: 58.33333%; }

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 66.66667%;
          flex: 0 0 66.66667%;
  max-width: 66.66667%; }

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 75%;
          flex: 0 0 75%;
  max-width: 75%; }

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 83.33333%;
          flex: 0 0 83.33333%;
  max-width: 83.33333%; }

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 91.66667%;
          flex: 0 0 91.66667%;
  max-width: 91.66667%; }

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
  max-width: 100%; }

.order-first {
  -webkit-box-ordinal-group: 0;
      -ms-flex-order: -1;
          order: -1; }

.order-last {
  -webkit-box-ordinal-group: 14;
      -ms-flex-order: 13;
          order: 13; }

.order-0 {
  -webkit-box-ordinal-group: 1;
      -ms-flex-order: 0;
          order: 0; }

.order-1 {
  -webkit-box-ordinal-group: 2;
      -ms-flex-order: 1;
          order: 1; }

.order-2 {
  -webkit-box-ordinal-group: 3;
      -ms-flex-order: 2;
          order: 2; }

.order-3 {
  -webkit-box-ordinal-group: 4;
      -ms-flex-order: 3;
          order: 3; }

.order-4 {
  -webkit-box-ordinal-group: 5;
      -ms-flex-order: 4;
          order: 4; }

.order-5 {
  -webkit-box-ordinal-group: 6;
      -ms-flex-order: 5;
          order: 5; }

.order-6 {
  -webkit-box-ordinal-group: 7;
      -ms-flex-order: 6;
          order: 6; }

.order-7 {
  -webkit-box-ordinal-group: 8;
      -ms-flex-order: 7;
          order: 7; }

.order-8 {
  -webkit-box-ordinal-group: 9;
      -ms-flex-order: 8;
          order: 8; }

.order-9 {
  -webkit-box-ordinal-group: 10;
      -ms-flex-order: 9;
          order: 9; }

.order-10 {
  -webkit-box-ordinal-group: 11;
      -ms-flex-order: 10;
          order: 10; }

.order-11 {
  -webkit-box-ordinal-group: 12;
      -ms-flex-order: 11;
          order: 11; }

.order-12 {
  -webkit-box-ordinal-group: 13;
      -ms-flex-order: 12;
          order: 12; }

.offset-1 {
  margin-left: 8.33333%; }

.offset-2 {
  margin-left: 16.66667%; }

.offset-3 {
  margin-left: 25%; }

.offset-4 {
  margin-left: 33.33333%; }

.offset-5 {
  margin-left: 41.66667%; }

.offset-6 {
  margin-left: 50%; }

.offset-7 {
  margin-left: 58.33333%; }

.offset-8 {
  margin-left: 66.66667%; }

.offset-9 {
  margin-left: 75%; }

.offset-10 {
  margin-left: 83.33333%; }

.offset-11 {
  margin-left: 91.66667%; }

@media (min-width: 576px) {
  .col-sm {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-sm-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-sm-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-sm-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-sm-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-sm-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-sm-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-sm-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-sm-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-sm-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-sm-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-sm-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-sm-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-sm-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-sm-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-sm-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-sm-0 {
    margin-left: 0; }
  .offset-sm-1 {
    margin-left: 8.33333%; }
  .offset-sm-2 {
    margin-left: 16.66667%; }
  .offset-sm-3 {
    margin-left: 25%; }
  .offset-sm-4 {
    margin-left: 33.33333%; }
  .offset-sm-5 {
    margin-left: 41.66667%; }
  .offset-sm-6 {
    margin-left: 50%; }
  .offset-sm-7 {
    margin-left: 58.33333%; }
  .offset-sm-8 {
    margin-left: 66.66667%; }
  .offset-sm-9 {
    margin-left: 75%; }
  .offset-sm-10 {
    margin-left: 83.33333%; }
  .offset-sm-11 {
    margin-left: 91.66667%; } }

@media (min-width: 768px) {
  .col-md {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-md-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-md-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-md-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-md-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-md-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-md-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-md-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-md-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-md-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-md-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-md-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-md-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-md-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-md-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-md-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-md-0 {
    margin-left: 0; }
  .offset-md-1 {
    margin-left: 8.33333%; }
  .offset-md-2 {
    margin-left: 16.66667%; }
  .offset-md-3 {
    margin-left: 25%; }
  .offset-md-4 {
    margin-left: 33.33333%; }
  .offset-md-5 {
    margin-left: 41.66667%; }
  .offset-md-6 {
    margin-left: 50%; }
  .offset-md-7 {
    margin-left: 58.33333%; }
  .offset-md-8 {
    margin-left: 66.66667%; }
  .offset-md-9 {
    margin-left: 75%; }
  .offset-md-10 {
    margin-left: 83.33333%; }
  .offset-md-11 {
    margin-left: 91.66667%; } }

@media (min-width: 992px) {
  .col-lg {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-lg-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-lg-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-lg-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-lg-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-lg-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-lg-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-lg-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-lg-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-lg-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-lg-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-lg-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-lg-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-lg-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-lg-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-lg-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-lg-0 {
    margin-left: 0; }
  .offset-lg-1 {
    margin-left: 8.33333%; }
  .offset-lg-2 {
    margin-left: 16.66667%; }
  .offset-lg-3 {
    margin-left: 25%; }
  .offset-lg-4 {
    margin-left: 33.33333%; }
  .offset-lg-5 {
    margin-left: 41.66667%; }
  .offset-lg-6 {
    margin-left: 50%; }
  .offset-lg-7 {
    margin-left: 58.33333%; }
  .offset-lg-8 {
    margin-left: 66.66667%; }
  .offset-lg-9 {
    margin-left: 75%; }
  .offset-lg-10 {
    margin-left: 83.33333%; }
  .offset-lg-11 {
    margin-left: 91.66667%; } }

@media (min-width: 1200px) {
  .col-xl {
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    max-width: 100%; }
  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 20%;
            flex: 0 0 20%;
    max-width: 20%; }
  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
    max-width: 100%; }
  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 8.33333%;
            flex: 0 0 8.33333%;
    max-width: 8.33333%; }
  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 16.66667%;
            flex: 0 0 16.66667%;
    max-width: 16.66667%; }
  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 25%;
            flex: 0 0 25%;
    max-width: 25%; }
  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 33.33333%;
            flex: 0 0 33.33333%;
    max-width: 33.33333%; }
  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 41.66667%;
            flex: 0 0 41.66667%;
    max-width: 41.66667%; }
  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
    max-width: 50%; }
  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 58.33333%;
            flex: 0 0 58.33333%;
    max-width: 58.33333%; }
  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 66.66667%;
            flex: 0 0 66.66667%;
    max-width: 66.66667%; }
  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 75%;
            flex: 0 0 75%;
    max-width: 75%; }
  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 83.33333%;
            flex: 0 0 83.33333%;
    max-width: 83.33333%; }
  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 91.66667%;
            flex: 0 0 91.66667%;
    max-width: 91.66667%; }
  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
            flex: 0 0 100%;
    max-width: 100%; }
  .order-xl-first {
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1; }
  .order-xl-last {
    -webkit-box-ordinal-group: 14;
        -ms-flex-order: 13;
            order: 13; }
  .order-xl-0 {
    -webkit-box-ordinal-group: 1;
        -ms-flex-order: 0;
            order: 0; }
  .order-xl-1 {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1; }
  .order-xl-2 {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2; }
  .order-xl-3 {
    -webkit-box-ordinal-group: 4;
        -ms-flex-order: 3;
            order: 3; }
  .order-xl-4 {
    -webkit-box-ordinal-group: 5;
        -ms-flex-order: 4;
            order: 4; }
  .order-xl-5 {
    -webkit-box-ordinal-group: 6;
        -ms-flex-order: 5;
            order: 5; }
  .order-xl-6 {
    -webkit-box-ordinal-group: 7;
        -ms-flex-order: 6;
            order: 6; }
  .order-xl-7 {
    -webkit-box-ordinal-group: 8;
        -ms-flex-order: 7;
            order: 7; }
  .order-xl-8 {
    -webkit-box-ordinal-group: 9;
        -ms-flex-order: 8;
            order: 8; }
  .order-xl-9 {
    -webkit-box-ordinal-group: 10;
        -ms-flex-order: 9;
            order: 9; }
  .order-xl-10 {
    -webkit-box-ordinal-group: 11;
        -ms-flex-order: 10;
            order: 10; }
  .order-xl-11 {
    -webkit-box-ordinal-group: 12;
        -ms-flex-order: 11;
            order: 11; }
  .order-xl-12 {
    -webkit-box-ordinal-group: 13;
        -ms-flex-order: 12;
            order: 12; }
  .offset-xl-0 {
    margin-left: 0; }
  .offset-xl-1 {
    margin-left: 8.33333%; }
  .offset-xl-2 {
    margin-left: 16.66667%; }
  .offset-xl-3 {
    margin-left: 25%; }
  .offset-xl-4 {
    margin-left: 33.33333%; }
  .offset-xl-5 {
    margin-left: 41.66667%; }
  .offset-xl-6 {
    margin-left: 50%; }
  .offset-xl-7 {
    margin-left: 58.33333%; }
  .offset-xl-8 {
    margin-left: 66.66667%; }
  .offset-xl-9 {
    margin-left: 75%; }
  .offset-xl-10 {
    margin-left: 83.33333%; }
  .offset-xl-11 {
    margin-left: 91.66667%; } }

.d-none {
  display: none !important; }

.d-inline {
  display: inline !important; }

.d-inline-block {
  display: inline-block !important; }

.d-block {
  display: block !important; }

.d-table {
  display: table !important; }

.d-table-row {
  display: table-row !important; }

.d-table-cell {
  display: table-cell !important; }

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important; }

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important; }

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important; }
  .d-sm-inline {
    display: inline !important; }
  .d-sm-inline-block {
    display: inline-block !important; }
  .d-sm-block {
    display: block !important; }
  .d-sm-table {
    display: table !important; }
  .d-sm-table-row {
    display: table-row !important; }
  .d-sm-table-cell {
    display: table-cell !important; }
  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 768px) {
  .d-md-none {
    display: none !important; }
  .d-md-inline {
    display: inline !important; }
  .d-md-inline-block {
    display: inline-block !important; }
  .d-md-block {
    display: block !important; }
  .d-md-table {
    display: table !important; }
  .d-md-table-row {
    display: table-row !important; }
  .d-md-table-cell {
    display: table-cell !important; }
  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important; }
  .d-lg-inline {
    display: inline !important; }
  .d-lg-inline-block {
    display: inline-block !important; }
  .d-lg-block {
    display: block !important; }
  .d-lg-table {
    display: table !important; }
  .d-lg-table-row {
    display: table-row !important; }
  .d-lg-table-cell {
    display: table-cell !important; }
  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important; }
  .d-xl-inline {
    display: inline !important; }
  .d-xl-inline-block {
    display: inline-block !important; }
  .d-xl-block {
    display: block !important; }
  .d-xl-table {
    display: table !important; }
  .d-xl-table-row {
    display: table-row !important; }
  .d-xl-table-cell {
    display: table-cell !important; }
  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

@media print {
  .d-print-none {
    display: none !important; }
  .d-print-inline {
    display: inline !important; }
  .d-print-inline-block {
    display: inline-block !important; }
  .d-print-block {
    display: block !important; }
  .d-print-table {
    display: table !important; }
  .d-print-table-row {
    display: table-row !important; }
  .d-print-table-cell {
    display: table-cell !important; }
  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important; }
  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important; } }

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important; }

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important; }

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important; }

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important; }

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important; }

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important; }

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important; }

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important; }

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important; }

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important; }

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important; }

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important; }

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important; }

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important; }

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important; }

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important; }

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important; }

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important; }

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important; }

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important; }

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important; }

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important; }

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important; }

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important; }

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important; }

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important; }

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important; }

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important; }

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important; }

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important; }

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important; }

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important; }

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important; }

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important; }

@media (min-width: 576px) {
  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 768px) {
  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 992px) {
  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

@media (min-width: 1200px) {
  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important; }
  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important; }
  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important; }
  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important; }
  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important; }
  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important; }
  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important; }
  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important; }
  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important; }
  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important; }
  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important; }
  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important; }
  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important; }
  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important; }
  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important; }
  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important; }
  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important; }
  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important; }
  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important; }
  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important; }
  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important; }
  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important; }
  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important; }
  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important; }
  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important; }
  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important; }
  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important; }
  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important; }
  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important; }
  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important; }
  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important; }
  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important; }
  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important; }
  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important; } }

.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 0.25rem !important; }

.mt-1,
.my-1 {
  margin-top: 0.25rem !important; }

.mr-1,
.mx-1 {
  margin-right: 0.25rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 0.25rem !important; }

.ml-1,
.mx-1 {
  margin-left: 0.25rem !important; }

.m-2 {
  margin: 0.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 0.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 0.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 0.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 0.5rem !important; }

.m-3 {
  margin: 1rem !important; }

.mt-3,
.my-3 {
  margin-top: 1rem !important; }

.mr-3,
.mx-3 {
  margin-right: 1rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 1rem !important; }

.ml-3,
.mx-3 {
  margin-left: 1rem !important; }

.m-4 {
  margin: 1.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 1.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 1.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 1.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 1.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 0.25rem !important; }

.pt-1,
.py-1 {
  padding-top: 0.25rem !important; }

.pr-1,
.px-1 {
  padding-right: 0.25rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 0.25rem !important; }

.pl-1,
.px-1 {
  padding-left: 0.25rem !important; }

.p-2 {
  padding: 0.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 0.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 0.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 0.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 0.5rem !important; }

.p-3 {
  padding: 1rem !important; }

.pt-3,
.py-3 {
  padding-top: 1rem !important; }

.pr-3,
.px-3 {
  padding-right: 1rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 1rem !important; }

.pl-3,
.px-3 {
  padding-left: 1rem !important; }

.p-4 {
  padding: 1.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 1.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 1.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 1.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 1.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.m-n1 {
  margin: -0.25rem !important; }

.mt-n1,
.my-n1 {
  margin-top: -0.25rem !important; }

.mr-n1,
.mx-n1 {
  margin-right: -0.25rem !important; }

.mb-n1,
.my-n1 {
  margin-bottom: -0.25rem !important; }

.ml-n1,
.mx-n1 {
  margin-left: -0.25rem !important; }

.m-n2 {
  margin: -0.5rem !important; }

.mt-n2,
.my-n2 {
  margin-top: -0.5rem !important; }

.mr-n2,
.mx-n2 {
  margin-right: -0.5rem !important; }

.mb-n2,
.my-n2 {
  margin-bottom: -0.5rem !important; }

.ml-n2,
.mx-n2 {
  margin-left: -0.5rem !important; }

.m-n3 {
  margin: -1rem !important; }

.mt-n3,
.my-n3 {
  margin-top: -1rem !important; }

.mr-n3,
.mx-n3 {
  margin-right: -1rem !important; }

.mb-n3,
.my-n3 {
  margin-bottom: -1rem !important; }

.ml-n3,
.mx-n3 {
  margin-left: -1rem !important; }

.m-n4 {
  margin: -1.5rem !important; }

.mt-n4,
.my-n4 {
  margin-top: -1.5rem !important; }

.mr-n4,
.mx-n4 {
  margin-right: -1.5rem !important; }

.mb-n4,
.my-n4 {
  margin-bottom: -1.5rem !important; }

.ml-n4,
.mx-n4 {
  margin-left: -1.5rem !important; }

.m-n5 {
  margin: -3rem !important; }

.mt-n5,
.my-n5 {
  margin-top: -3rem !important; }

.mr-n5,
.mx-n5 {
  margin-right: -3rem !important; }

.mb-n5,
.my-n5 {
  margin-bottom: -3rem !important; }

.ml-n5,
.mx-n5 {
  margin-left: -3rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 0.25rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.25rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.25rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.25rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.25rem !important; }
  .m-sm-2 {
    margin: 0.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 0.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 0.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 0.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 0.5rem !important; }
  .m-sm-3 {
    margin: 1rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1rem !important; }
  .m-sm-4 {
    margin: 1.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 1.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 1.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 1.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 0.25rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.25rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.25rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.25rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.25rem !important; }
  .p-sm-2 {
    padding: 0.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 0.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 0.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 0.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 0.5rem !important; }
  .p-sm-3 {
    padding: 1rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1rem !important; }
  .p-sm-4 {
    padding: 1.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 1.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 1.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 1.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .m-sm-n1 {
    margin: -0.25rem !important; }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.25rem !important; }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.25rem !important; }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.25rem !important; }
  .m-sm-n2 {
    margin: -0.5rem !important; }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -0.5rem !important; }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -0.5rem !important; }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -0.5rem !important; }
  .m-sm-n3 {
    margin: -1rem !important; }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1rem !important; }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1rem !important; }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1rem !important; }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1rem !important; }
  .m-sm-n4 {
    margin: -1.5rem !important; }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -1.5rem !important; }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -1.5rem !important; }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -1.5rem !important; }
  .m-sm-n5 {
    margin: -3rem !important; }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -3rem !important; }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -3rem !important; }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -3rem !important; }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -3rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 0.25rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.25rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.25rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.25rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.25rem !important; }
  .m-md-2 {
    margin: 0.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 0.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 0.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 0.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 0.5rem !important; }
  .m-md-3 {
    margin: 1rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1rem !important; }
  .m-md-4 {
    margin: 1.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 1.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 1.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 1.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 1.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 0.25rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.25rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.25rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.25rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.25rem !important; }
  .p-md-2 {
    padding: 0.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 0.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 0.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 0.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 0.5rem !important; }
  .p-md-3 {
    padding: 1rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1rem !important; }
  .p-md-4 {
    padding: 1.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 1.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 1.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 1.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 1.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .m-md-n1 {
    margin: -0.25rem !important; }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.25rem !important; }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.25rem !important; }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.25rem !important; }
  .m-md-n2 {
    margin: -0.5rem !important; }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -0.5rem !important; }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -0.5rem !important; }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -0.5rem !important; }
  .m-md-n3 {
    margin: -1rem !important; }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1rem !important; }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1rem !important; }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1rem !important; }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1rem !important; }
  .m-md-n4 {
    margin: -1.5rem !important; }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -1.5rem !important; }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -1.5rem !important; }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -1.5rem !important; }
  .m-md-n5 {
    margin: -3rem !important; }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -3rem !important; }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -3rem !important; }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -3rem !important; }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -3rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 0.25rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.25rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.25rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.25rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.25rem !important; }
  .m-lg-2 {
    margin: 0.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 0.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 0.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 0.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 0.5rem !important; }
  .m-lg-3 {
    margin: 1rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1rem !important; }
  .m-lg-4 {
    margin: 1.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 1.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 1.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 1.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 0.25rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.25rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.25rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.25rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.25rem !important; }
  .p-lg-2 {
    padding: 0.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 0.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 0.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 0.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 0.5rem !important; }
  .p-lg-3 {
    padding: 1rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1rem !important; }
  .p-lg-4 {
    padding: 1.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 1.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 1.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 1.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .m-lg-n1 {
    margin: -0.25rem !important; }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.25rem !important; }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.25rem !important; }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.25rem !important; }
  .m-lg-n2 {
    margin: -0.5rem !important; }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -0.5rem !important; }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -0.5rem !important; }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -0.5rem !important; }
  .m-lg-n3 {
    margin: -1rem !important; }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1rem !important; }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1rem !important; }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1rem !important; }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1rem !important; }
  .m-lg-n4 {
    margin: -1.5rem !important; }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -1.5rem !important; }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -1.5rem !important; }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -1.5rem !important; }
  .m-lg-n5 {
    margin: -3rem !important; }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -3rem !important; }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -3rem !important; }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -3rem !important; }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -3rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0 !important; }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important; }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important; }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important; }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important; }
  .m-xl-1 {
    margin: 0.25rem !important; }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.25rem !important; }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.25rem !important; }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.25rem !important; }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.25rem !important; }
  .m-xl-2 {
    margin: 0.5rem !important; }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 0.5rem !important; }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 0.5rem !important; }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 0.5rem !important; }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 0.5rem !important; }
  .m-xl-3 {
    margin: 1rem !important; }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1rem !important; }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1rem !important; }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1rem !important; }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1rem !important; }
  .m-xl-4 {
    margin: 1.5rem !important; }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 1.5rem !important; }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 1.5rem !important; }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 1.5rem !important; }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 1.5rem !important; }
  .m-xl-5 {
    margin: 3rem !important; }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 3rem !important; }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 3rem !important; }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 3rem !important; }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 3rem !important; }
  .p-xl-0 {
    padding: 0 !important; }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important; }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important; }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important; }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important; }
  .p-xl-1 {
    padding: 0.25rem !important; }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.25rem !important; }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.25rem !important; }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.25rem !important; }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.25rem !important; }
  .p-xl-2 {
    padding: 0.5rem !important; }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 0.5rem !important; }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 0.5rem !important; }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 0.5rem !important; }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 0.5rem !important; }
  .p-xl-3 {
    padding: 1rem !important; }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1rem !important; }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1rem !important; }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1rem !important; }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1rem !important; }
  .p-xl-4 {
    padding: 1.5rem !important; }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 1.5rem !important; }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 1.5rem !important; }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 1.5rem !important; }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 1.5rem !important; }
  .p-xl-5 {
    padding: 3rem !important; }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 3rem !important; }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 3rem !important; }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 3rem !important; }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 3rem !important; }
  .m-xl-n1 {
    margin: -0.25rem !important; }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.25rem !important; }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.25rem !important; }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.25rem !important; }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.25rem !important; }
  .m-xl-n2 {
    margin: -0.5rem !important; }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -0.5rem !important; }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -0.5rem !important; }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -0.5rem !important; }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -0.5rem !important; }
  .m-xl-n3 {
    margin: -1rem !important; }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1rem !important; }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1rem !important; }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1rem !important; }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1rem !important; }
  .m-xl-n4 {
    margin: -1.5rem !important; }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -1.5rem !important; }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -1.5rem !important; }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -1.5rem !important; }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -1.5rem !important; }
  .m-xl-n5 {
    margin: -3rem !important; }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -3rem !important; }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -3rem !important; }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -3rem !important; }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -3rem !important; }
  .m-xl-auto {
    margin: auto !important; }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important; }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important; }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important; }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important; } }

[is='flynt-block-not-found'] .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  gap: 40px; }
  [is='flynt-block-not-found'] .content > :first-child {
    margin-top: 0;
    padding-top: 0; }
  [is='flynt-block-not-found'] .content > :last-child {
    margin-bottom: 0;
    padding-bottom: 0; }
  @media (max-width: 991px) {
    [is='flynt-block-not-found'] .content {
      margin-top: 50px; } }

[is='flynt-block-not-found'] h1 {
  margin-bottom: 2rem; }

[is='flynt-block-not-found'] .button.button--link, [is='flynt-block-not-found'] .ctaFixed .button--link.ctaFixed__button, .ctaFixed [is='flynt-block-not-found'] .button--link.ctaFixed__button {
  font-size: 1.6rem;
  color: #000000; }
  [is='flynt-block-not-found'] .button.button--link::before, [is='flynt-block-not-found'] .ctaFixed .button--link.ctaFixed__button::before, .ctaFixed [is='flynt-block-not-found'] .button--link.ctaFixed__button::before {
    background-color: #000000; }
  [is='flynt-block-not-found'] .button.button--link::after, [is='flynt-block-not-found'] .ctaFixed .button--link.ctaFixed__button::after, .ctaFixed [is='flynt-block-not-found'] .button--link.ctaFixed__button::after {
    content: none; }

[is='flynt-block-video-oembed'] .figure {
  margin-bottom: 0; }
  [is='flynt-block-video-oembed'] .figure-image {
    background: rgba(0, 0, 0, 0.03); }
    [is='flynt-block-video-oembed'] .figure-image--isHidden {
      visibility: hidden; }

[is='flynt-block-video-oembed'] .video-playButton {
  cursor: pointer;
  display: block;
  height: 100%;
  left: 0;
  outline: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2; }
  [is='flynt-block-video-oembed'] .video-playButton::before {
    content: '';
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    background-color: rgba(51, 51, 51, 0.5);
    border: 3px solid #fff;
    border-radius: 50%;
    height: 60px;
    margin-left: -30px;
    margin-top: -30px;
    width: 60px; }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .video-playButton::before {
        height: 100px;
        margin-left: -50px;
        margin-top: -50px;
        width: 100px; } }
  [is='flynt-block-video-oembed'] .video-playButton::after {
    content: '';
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transition: -webkit-transform 0.3s ease;
    transition: -webkit-transform 0.3s ease;
    transition: transform 0.3s ease;
    transition: transform 0.3s ease, -webkit-transform 0.3s ease;
    border-bottom: 10px solid transparent;
    border-left: 20px solid #fff;
    border-top: 10px solid transparent;
    height: 0;
    margin-left: -9px;
    margin-top: -10px;
    width: 0; }
    @media (min-width: 768px) {
      [is='flynt-block-video-oembed'] .video-playButton::after {
        border-bottom: 20px solid transparent;
        border-left: 40px solid #fff;
        border-top: 20px solid transparent;
        margin-left: -18px;
        margin-top: -20px; } }
  [is='flynt-block-video-oembed'] .video-playButton:hover::before, [is='flynt-block-video-oembed'] .video-playButton:hover::after {
    -webkit-transform: scale(1.1);
            transform: scale(1.1); }

[is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton::before {
  content: '';
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  background-color: rgba(51, 51, 51, 0.5);
  border: 3px solid #fff;
  border-radius: 50%;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
  width: 40px; }
  @media (min-width: 768px) {
    [is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton::before {
      height: 60px;
      margin-left: -30px;
      margin-top: -30px;
      width: 60px; } }

[is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton::after {
  content: '';
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transition: -webkit-transform 0.3s ease;
  transition: -webkit-transform 0.3s ease;
  transition: transform 0.3s ease;
  transition: transform 0.3s ease, -webkit-transform 0.3s ease;
  border-bottom: 5px solid transparent;
  border-left: 10px solid #fff;
  border-top: 5px solid transparent;
  height: 0;
  margin-left: -4.5px;
  margin-top: -5px;
  width: 0; }
  @media (min-width: 768px) {
    [is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton::after {
      border-bottom: 10px solid transparent;
      border-left: 20px solid #fff;
      border-top: 10px solid transparent;
      margin-left: -9px;
      margin-top: -10px; } }

[is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton:hover::before, [is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video-playButton:hover::after {
  -webkit-transform: scale(1.1);
          transform: scale(1.1); }

[is='flynt-block-video-oembed'] .video-loader {
  -webkit-animation: loading 1.1s infinite linear;
          animation: loading 1.1s infinite linear;
  border-bottom: 4px solid rgba(255, 255, 255, 0.2);
  border-left: 4px solid #fff;
  border-right: 4px solid rgba(255, 255, 255, 0.2);
  border-top: 4px solid rgba(255, 255, 255, 0.2);
  display: none;
  left: 50%;
  margin-left: -30px;
  margin-top: -30px;
  position: absolute;
  text-indent: -9999em;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 1; }
  @media (min-width: 768px) {
    [is='flynt-block-video-oembed'] .video-loader {
      margin-left: -50px;
      margin-top: -50px; } }

[is='flynt-block-video-oembed'] .video-loader,
[is='flynt-block-video-oembed'] .video-loader::after {
  border-radius: 50%;
  height: 60px;
  width: 60px; }
  @media (min-width: 768px) {
    [is='flynt-block-video-oembed'] .video-loader,
    [is='flynt-block-video-oembed'] .video-loader::after {
      height: 100px;
      width: 100px; } }

@-webkit-keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg); } }

[is='flynt-block-video-oembed'] .video {
  margin: 0 auto;
  position: relative; }
  [is='flynt-block-video-oembed'] .video-player {
    height: 0;
    padding-bottom: 56.25%;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 10; }
  [is='flynt-block-video-oembed'] .video iframe {
    display: none;
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%; }

[is='flynt-block-video-oembed'] .video-player--isLoading {
  background: rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.2s ease-out;
  transition: background 0.2s ease-out; }
  [is='flynt-block-video-oembed'] .video-player--isLoading .video-loader {
    display: block; }
  [is='flynt-block-video-oembed'] .video-player--isLoading .video-playButton {
    display: none; }

[is='flynt-block-video-oembed'] .video-player--isLoaded .video-loader,
[is='flynt-block-video-oembed'] .video-player--isLoaded .video-playButton {
  display: none; }

[is='flynt-block-video-oembed'] .video-player--isLoaded iframe {
  display: block; }

[is='flynt-block-video-oembed'].flyntComponent--sizeSmall .video {
  max-width: 375px; }

[is='flynt-block-video-oembed'].flyntComponent--sizeMedium .video {
  max-width: 600px; }

[is='flynt-block-video-oembed'].flyntComponent--sizeLarge .video {
  max-width: 800px; }

[is='flynt-block-video-oembed'].flyntComponent--sizeFull .figure-caption {
  margin-left: 0.625rem; }

[is='breadcrumbs'] .section {
  margin-bottom: 2rem; }

[is='breadcrumbs'] .breadcrumbs {
  font-size: 1rem;
  text-transform: uppercase;
  text-align: center; }
  @media (min-width: 576px) {
    [is='breadcrumbs'] .breadcrumbs {
      font-size: 1.2rem; } }

[is='breadcrumbs'] .breadcrumbs__separator {
  margin: 0 3px; }

@media (min-width: 1200px) {
  [is='carousel-post']:not(:hover) .slick-arrow {
    opacity: 0; } }

[is='carousel-post'] .carousel-post__title * {
  font-family: "Baskerville Regular";
  color: #000000; }

[is='carousel-post'] .carousel-post__header + .carousel-post__carousel {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    [is='carousel-post'] .carousel-post__header + .carousel-post__carousel {
      margin-top: 6rem; } }

[is='carousel-post'] .slick-list {
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 12vw; }

[is='carousel-post'] .slick-slide {
  padding: 0 15px; }

[is='carousel-post'] .carousel-post__item-image {
  position: relative; }
  [is='carousel-post'] .carousel-post__item-image::before {
    content: "";
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: black;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.501838)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.501838) 0%, rgba(255, 255, 255, 0) 100%); }
  [is='carousel-post'] .carousel-post__item-image figure {
    position: relative;
    margin-bottom: 0;
    max-width: unset;
    padding-top: 100%;
    width: unset;
    height: 100%; }
    [is='carousel-post'] .carousel-post__item-image figure img {
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%; }
    [is='carousel-post'] .carousel-post__item-image figure a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }

[is='carousel-post'] .carousel-post__item-info {
  margin-top: 15px; }

[is='carousel-post'] .carousel-post__item-title {
  font-family: "Barlow Regular";
  font-size: 2rem;
  line-height: 3rem;
  color: #000000;
  margin-top: 15px; }

[is='carousel-post'] button.slick-arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 30px);
  height: 30px;
  width: 30px;
  font-size: 0;
  line-height: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  [is='carousel-post'] button.slick-arrow.slick-prev {
    background-image: url(/wp-content/themes/farmerbit/dist/Components/CarouselPost/Images/arrow-left.svg);
    left: 15px; }
  [is='carousel-post'] button.slick-arrow.slick-next {
    background-image: url(/wp-content/themes/farmerbit/dist/Components/CarouselPost/Images/arrow-right.svg);
    right: 50px; }
    @media (max-width: 575px) {
      [is='carousel-post'] button.slick-arrow.slick-next {
        right: 5px !important; } }
    @media (min-width: 576px) and (max-width: 991px) {
      [is='carousel-post'] button.slick-arrow.slick-next {
        right: 15px !important; } }
  [is='carousel-post'] button.slick-arrow:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  [is='carousel-post'] button.slick-arrow.slick-disabled {
    display: none !important; }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__carousel {
    overflow-x: auto;
    margin-right: unset !important; } }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-bottom: 2rem; } }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__list .carousel-post__item {
    width: calc((100vw - 90px) / 2); } }

@media (max-width: 767px) {
  [is='carousel-post'] .carousel-post__list .carousel-post__item {
    width: calc(100vw - 60px); } }

[is='carousel-post'] .carousel-post__list .carousel-post__item:not(:first-child) {
  margin-left: 30px; }

@media (min-width: 1200px) {
  [is='carousel-post']:not(:hover) .slick-arrow {
    opacity: 0; } }

[is='carousel-post'] .carousel-post__title * {
  font-family: "Baskerville Regular";
  color: #000000; }

[is='carousel-post'] .carousel-post__header + .carousel-post__carousel {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    [is='carousel-post'] .carousel-post__header + .carousel-post__carousel {
      margin-top: 6rem; } }

[is='carousel-post'] .slick-list {
  margin-left: -15px;
  margin-right: -15px;
  padding-right: 12vw; }

[is='carousel-post'] .slick-slide {
  padding: 0 15px; }

[is='carousel-post'] .carousel-post__item-image {
  position: relative; }
  [is='carousel-post'] .carousel-post__item-image::before {
    content: "";
    position: absolute;
    top: 30%;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    background: black;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.501838)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.501838) 0%, rgba(255, 255, 255, 0) 100%); }
  [is='carousel-post'] .carousel-post__item-image figure {
    position: relative;
    margin-bottom: 0;
    max-width: unset;
    padding-top: 100%;
    width: unset;
    height: 100%; }
    [is='carousel-post'] .carousel-post__item-image figure img {
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%; }
    [is='carousel-post'] .carousel-post__item-image figure a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }

[is='carousel-post'] .carousel-post__item-info {
  margin-top: 15px; }

[is='carousel-post'] .carousel-post__item-title {
  font-family: "Barlow Regular";
  font-size: 2rem;
  line-height: 3rem;
  color: #000000;
  margin-top: 15px; }

[is='carousel-post'] button.slick-arrow {
  position: absolute;
  z-index: 2;
  top: calc(50% - 30px);
  height: 30px;
  width: 30px;
  font-size: 0;
  line-height: 0;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  [is='carousel-post'] button.slick-arrow.slick-prev {
    background-image: url(/wp-content/themes/farmerbit/dist/Components/CarouselPost/Images/arrow-left.svg);
    left: 15px; }
  [is='carousel-post'] button.slick-arrow.slick-next {
    background-image: url(/wp-content/themes/farmerbit/dist/Components/CarouselPost/Images/arrow-right.svg);
    right: 50px; }
    @media (max-width: 575px) {
      [is='carousel-post'] button.slick-arrow.slick-next {
        right: 5px !important; } }
    @media (min-width: 576px) and (max-width: 991px) {
      [is='carousel-post'] button.slick-arrow.slick-next {
        right: 15px !important; } }
  [is='carousel-post'] button.slick-arrow:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }
  [is='carousel-post'] button.slick-arrow.slick-disabled {
    display: none !important; }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__carousel {
    overflow-x: auto;
    margin-right: unset !important; } }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__list {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    padding-bottom: 2rem; } }

@media (max-width: 1025px) {
  [is='carousel-post'] .carousel-post__list .carousel-post__item {
    width: calc((100vw - 90px) / 2); } }

@media (max-width: 767px) {
  [is='carousel-post'] .carousel-post__list .carousel-post__item {
    width: calc(100vw - 60px); } }

[is='carousel-post'] .carousel-post__list .carousel-post__item:not(:first-child) {
  margin-left: 30px; }

[is='columns-footer'] {
  position: relative; }
  [is='columns-footer'] .bg-footer {
    position: relative;
    right: 0;
    left: 0;
    bottom: -10vw;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100vw;
    height: 27vw;
    z-index: 1; }
  [is='columns-footer'] span.font-regular {
    font-family: "Barlow" !important; }
  [is='columns-footer'] .payments-footer .footer-col .footer-col__description p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    gap: 40px; }
    @media (max-width: 767px) {
      [is='columns-footer'] .payments-footer .footer-col .footer-col__description p {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start; } }
    [is='columns-footer'] .payments-footer .footer-col .footer-col__description p img {
      height: 22px;
      width: auto; }
  [is='columns-footer'] p:not(:last-child) {
    margin-bottom: 1rem; }
    @media (min-width: 992px) {
      [is='columns-footer'] p:not(:last-child) {
        margin-bottom: 2rem; } }
  [is='columns-footer'] .columns-footer {
    position: relative;
    z-index: 1;
    margin-top: -80px; }
    @media (max-width: 991px) {
      [is='columns-footer'] .columns-footer {
        margin-top: 0; } }
    [is='columns-footer'] .columns-footer .container-lg {
      position: relative;
      z-index: 1; }
    [is='columns-footer'] .columns-footer::before {
      content: '';
      position: absolute;
      background-color: #151B1B;
      right: 0;
      left: 0;
      bottom: 0;
      top: 27vw;
      width: 100%;
      z-index: -1; }
    [is='columns-footer'] .columns-footer .footer-col:nth-child(1) .footer-col__description {
      margin-top: 1rem; }
      @media (min-width: 576px) {
        [is='columns-footer'] .columns-footer .footer-col:nth-child(1) .footer-col__description {
          margin-top: 2rem; } }
  [is='columns-footer'] .nav-logo img {
    height: 50px;
    width: auto; }
  [is='columns-footer'] .footer-col__title {
    font-family: "Barlow Bold";
    font-size: 1.6rem; }
    @media (min-width: 576px) {
      [is='columns-footer'] .footer-col__title {
        font-size: 1.8rem; } }
  [is='columns-footer'] .footer-col__description {
    font-size: 1.4rem; }
    @media (min-width: 576px) {
      [is='columns-footer'] .footer-col__description {
        font-size: 1.6rem; } }
  @media (max-width: 767px) {
    [is='columns-footer'] .columns-footer__col:not(:first-child) {
      margin-top: 4rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [is='columns-footer'] .columns-footer__col:not(:first-child):not(:nth-child(2)) {
      margin-top: 4rem; } }
  [is='columns-footer'] .columns-footer-sub {
    padding-top: 10rem;
    padding-bottom: 10rem;
    position: relative;
    z-index: 1;
    background-color: #151B1B; }
    @media (max-width: 991px) {
      [is='columns-footer'] .columns-footer-sub {
        padding-top: 5rem;
        padding-bottom: 5rem; } }
    [is='columns-footer'] .columns-footer-sub .footer-col__description {
      font-size: 1.2rem; }
      @media (min-width: 576px) {
        [is='columns-footer'] .columns-footer-sub .footer-col__description {
          font-size: 1.4rem; } }
    [is='columns-footer'] .columns-footer-sub .columns-footer__col:not(:first-child) {
      margin-top: 0rem; }
    [is='columns-footer'] .columns-footer-sub .columns-footer__col:nth-child(3), [is='columns-footer'] .columns-footer-sub .columns-footer__col:nth-child(4) {
      margin-top: 3rem; }
      @media (min-width: 767px) {
        [is='columns-footer'] .columns-footer-sub .columns-footer__col:nth-child(3), [is='columns-footer'] .columns-footer-sub .columns-footer__col:nth-child(4) {
          margin-top: 0; } }
    [is='columns-footer'] .columns-footer-sub a {
      text-decoration: none; }

/* ICONE FIXED CONTATTI */
.contact-btn {
  right: 30px;
  bottom: -200px;
  z-index: 2;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out; }
  @media (max-width: 767px) {
    .contact-btn {
      right: 15px; } }

.contact-btn .contact-btn__contacts {
  z-index: 3; }

.contact-btn .contact-btn__phone {
  z-index: 2; }

.contact-btn .contact-btn__mail {
  z-index: 1; }

.contact-btn__style {
  background-color: #43545B;
  height: 50px;
  width: 50px;
  cursor: pointer;
  bottom: -50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s;
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0);
  box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0); }

@media (max-width: 991px) {
  .contact-btn__maps {
    position: fixed !important;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 15px;
    width: 100%; }
    .contact-btn__maps img {
      margin-right: 10px; }
    .contact-btn__maps span {
      text-transform: uppercase;
      font-size: 1.3rem;
      font-weight: normal;
      letter-spacing: 1px; }
    .contact-btn__maps:hover span {
      color: #44545B; } }

.contact-btn__phone {
  position: relative; }
  .contact-btn__phone a {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
    z-index: 2; }

.contact-btn__style.label, #show-contacts {
  -webkit-box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 13px -2px rgba(0, 0, 0, 0.4); }

.contact-btn__style img {
  height: 20px;
  width: auto;
  -webkit-filter: unset;
          filter: unset;
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s; }

.contact-btn__style:hover {
  background-color: #EBE4E0; }

.contact-btn__style:hover img {
  -webkit-filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(97%) contrast(103%);
          filter: brightness(0) saturate(100%) invert(0%) sepia(0%) saturate(0%) hue-rotate(333deg) brightness(97%) contrast(103%); }

.contact-btn__style a {
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.contact-btn__phone {
  bottom: -50px;
  -webkit-transition: all ease-out 0.3s;
  transition: all ease-out 0.3s; }

.contact-btn__mail {
  bottom: -50px;
  -webkit-transition: all ease-out 0.2s;
  transition: all ease-out 0.2s; }

.contact-btn__mail a img {
  height: 14px;
  width: auto; }

.move-up {
  bottom: 20px; }

.move-up-2x {
  bottom: 90px; }

.move-up-3x {
  bottom: 160px; }

.move-up-4x {
  bottom: 230px; }

.contact-btn.scroll-up {
  bottom: 80px; }

.contact-btn__style-before .label-fixed {
  display: none;
  position: absolute;
  left: -90px;
  background-color: #F5EE2F;
  border-radius: 5px;
  -webkit-transition: all ease-out .2s;
  transition: all ease-out .2s;
  color: #000;
  line-height: 23px;
  font-size: 11px;
  text-align: center;
  min-width: 70px;
  text-transform: uppercase; }

.contact-btn__style-before.label .label-fixed {
  display: none; }

.booking-btn {
  margin-bottom: 20px; }
  @media (max-width: 991px) {
    .booking-btn {
      margin-bottom: 40px;
      width: 50px; } }

/* POPUP LOADER */
.loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex; }
  .loader svg {
    width: 60px;
    margin: auto; }
  .loader:not(.show) {
    display: none; }

/* POPUP MAIL */
.popup-contact__content .popup-contact__title .form_contatti {
  margin-bottom: 0; }

.popup-content button {
  border-color: transparent;
  background: none; }

.popup-close-btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding-bottom: 30px; }

.popup-close-btn button {
  border-color: transparent;
  background: none;
  cursor: pointer; }

.popup-close-btn button img {
  height: 30px; }

@media (max-width: 992px) {
  .popup-close-btn button img {
    margin-right: 15px;
    height: 23px; } }

.popup-contact__title span.span {
  padding-left: 15px; }

.popup-contact__title span.popup-asstech__title {
  padding-left: 0; }

div.popup-contact__mail, div.popup-contact__info, div.popup-contact__offerte {
  display: none;
  position: fixed;
  z-index: 6;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(96, 96, 96, 0.6);
  -webkit-transition: all ease-in 0.5s;
  transition: all ease-in 0.5s; }

div.popup-contact__mail .popup-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto; }

div.popup-contact__info .popup-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto; }

div.popup-contact__offerte .popup-overflow {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto; }

@media (max-width: 992px) {
  div.popup-contact__mail {
    width: 100vw; }
  div.popup-contact__info {
    width: 100vw; }
  div.popup-contact__offerte {
    width: 100vw; } }

.popup-contact__title {
  margin-bottom: 4rem; }

.popup-contact__content {
  background-color: #fefefe;
  padding: 30px;
  min-width: 40%;
  width: 800px;
  max-width: calc(100vw - 60px);
  margin: 6rem auto 0;
  text-align: left; }

.popup-contact__content .popup-contact__title span {
  text-align: left; }

@media (max-width: 992px) {
  .popup-contact__content {
    text-align: center;
    margin-top: 90px;
    margin-bottom: 90px;
    padding: 15px 0 0 0; } }

@media (max-width: 480px) {
  .popup-contact__content {
    max-width: calc(100vw - 20px); } }

[is='form'] [type="checkbox"] + label {
  color: #000; }

button#hide-popup-contact img:hover {
  -webkit-filter: brightness(0) saturate(100%);
          filter: brightness(0) saturate(100%); }

button#hide-popup-contact-menu img {
  -webkit-filter: brightness(0) saturate(100%);
          filter: brightness(0) saturate(100%); }

button#hide-popup-contact-menu img:hover {
  -webkit-filter: invert(59%) sepia(60%) saturate(478%) hue-rotate(111deg) brightness(92%) contrast(87%);
          filter: invert(59%) sepia(60%) saturate(478%) hue-rotate(111deg) brightness(92%) contrast(87%); }

[is='contact-info'] {
  font-size: 1.5rem; }
  [is='contact-info'] .contact-info__title {
    font-family: "Barlow Bold"; }
  @media (max-width: 767px) {
    [is='contact-info'] .contact-info__item {
      margin-top: 30px; } }
  @media (max-width: 767px) {
    [is='contact-info'] .contact-info__itemt:first-child {
      margin-top: 0; } }

[is='double-text-image'] .double-text-image__title * {
  color: #000000;
  font-family: "Baskerville Regular"; }
  @media (max-width: 575px) {
    [is='double-text-image'] .double-text-image__title * {
      font-size: 2.5rem;
      line-height: 3.5rem; } }

[is='double-text-image'] .double-text-image__title + .double-text-image__description {
  margin-top: 2rem; }
  @media (min-width: 768px) {
    [is='double-text-image'] .double-text-image__title + .double-text-image__description {
      margin-top: 4rem; } }

@media (max-width: 767px) {
  [is='double-text-image'] .double-text-image__image {
    margin-top: 4rem; } }

@media (max-width: 767px) {
  [is='double-text-image'] .double-text-image__image img {
    width: 400px;
    max-width: 100%;
    margin: auto; } }

[is='double-text-image'] .double-text-image__row2 {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end; }
  @media (max-width: 767px) {
    [is='double-text-image'] .double-text-image__row2 {
      -webkit-box-orient: vertical;
      -webkit-box-direction: reverse;
          -ms-flex-direction: column-reverse;
              flex-direction: column-reverse; } }
  @media (min-width: 768px) {
    [is='double-text-image'] .double-text-image__row2 {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: reverse;
          -ms-flex-direction: row-reverse;
              flex-direction: row-reverse; } }

[is='double-text-image'] .double-text-image__row1 + .double-text-image__row2 {
  margin-top: 4rem; }
  @media (min-width: 992px) {
    [is='double-text-image'] .double-text-image__row1 + .double-text-image__row2 {
      margin-top: -6rem; } }

@media (min-width: 768px) {
  [is='double-text-image'] .double-text-image__row1 .double-text-image__text {
    margin-top: 4rem; } }

@media (min-width: 992px) {
  [is='double-text-image'] .double-text-image__row1 .double-text-image__text {
    margin-top: 8rem; } }

@media (min-width: 768px) {
  [is='double-text-image'] .double-text-image__row1 {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: reverse;
        -ms-flex-direction: row-reverse;
            flex-direction: row-reverse; } }

@media (min-width: 768px) {
  [is='double-text-image'] .button, [is='double-text-image'] .ctaFixed .ctaFixed__button, .ctaFixed [is='double-text-image'] .ctaFixed__button {
    margin-top: 4rem; } }

.popup-contact__mail .form-div {
  margin-bottom: 0; }

.popup-contact__info .form-div {
  margin-bottom: 0; }

.popup-contact__offerte .form-div {
  margin-bottom: 0; }

[is='form'] {
  position: relative; }
  @media (min-width: 768px) {
    [is='form'] .text-md-right {
      text-align: right; } }
  [is='form'] .form__description, [is='form'] .form__cta, [is='form'] .form__form {
    margin-top: 2rem; }
    @media (min-width: 992px) {
      [is='form'] .form__description, [is='form'] .form__cta, [is='form'] .form__form {
        margin-top: 3rem; } }
  [is='form'].themeLightgrey {
    background-color: #aaa; }
  [is='form'] .fc-block {
    position: relative; }
  [is='form'] .fc-block__bg {
    width: 50%;
    position: absolute;
    height: rem(160px); }
  [is='form']:last-child .fc-block__bg {
    bottom: 0; }
  [is='form'] .fc-block__bg--right {
    right: 0; }
  [is='form'] .fc-block__description {
    line-height: 2; }
  [is='form'] .form__title h1, [is='form'] .form__title h2, [is='form'] .form__title h3, [is='form'] .form__title span {
    font-size: rem(20px);
    line-height: rem(30px); }
  @media (max-width: 767.98px) {
    [is='form'].themeLightgrey .fc-block__title.text-right {
      text-align: left !important; }
    [is='form'] .fc-block__bg {
      bottom: rem(-30px); } }
  @media (min-width: 768px) {
    [is='form'] .fc-block__bg {
      bottom: rem(-5px); } }
  [is='form'] [type="tel"], [is='form'] [type="url"], [is='form'] [type="text"], [is='form'] [type="date"], [is='form'] [type="email"], [is='form'] [type="search"], [is='form'] [type="number"], [is='form'] [type="password"], [is='form'] textarea, [is='form'] select, [is='form'] .fakeInput, [is='form'] .select {
    border: 0;
    border-bottom: 1px solid #000;
    padding: 18px 18px;
    color: #000000;
    width: 100%;
    cursor: pointer;
    background: transparent;
    font-size: 1.6rem;
    margin-bottom: 1.5rem;
    border-radius: 0px;
    line-height: normal; }
    @media (min-width: 576px) {
      [is='form'] [type="tel"], [is='form'] [type="url"], [is='form'] [type="text"], [is='form'] [type="date"], [is='form'] [type="email"], [is='form'] [type="search"], [is='form'] [type="number"], [is='form'] [type="password"], [is='form'] textarea, [is='form'] select, [is='form'] .fakeInput, [is='form'] .select {
        margin-bottom: 2.5rem; } }
    [is='form'] [type="tel"]::-webkit-input-placeholder, [is='form'] [type="url"]::-webkit-input-placeholder, [is='form'] [type="text"]::-webkit-input-placeholder, [is='form'] [type="date"]::-webkit-input-placeholder, [is='form'] [type="email"]::-webkit-input-placeholder, [is='form'] [type="search"]::-webkit-input-placeholder, [is='form'] [type="number"]::-webkit-input-placeholder, [is='form'] [type="password"]::-webkit-input-placeholder, [is='form'] textarea::-webkit-input-placeholder, [is='form'] select::-webkit-input-placeholder, [is='form'] .fakeInput::-webkit-input-placeholder, [is='form'] .select::-webkit-input-placeholder {
      color: #000;
      font-size: 1.6rem; }
    [is='form'] [type="tel"]::-moz-placeholder, [is='form'] [type="url"]::-moz-placeholder, [is='form'] [type="text"]::-moz-placeholder, [is='form'] [type="date"]::-moz-placeholder, [is='form'] [type="email"]::-moz-placeholder, [is='form'] [type="search"]::-moz-placeholder, [is='form'] [type="number"]::-moz-placeholder, [is='form'] [type="password"]::-moz-placeholder, [is='form'] textarea::-moz-placeholder, [is='form'] select::-moz-placeholder, [is='form'] .fakeInput::-moz-placeholder, [is='form'] .select::-moz-placeholder {
      color: #000;
      font-size: 1.6rem; }
    [is='form'] [type="tel"]:-ms-input-placeholder, [is='form'] [type="url"]:-ms-input-placeholder, [is='form'] [type="text"]:-ms-input-placeholder, [is='form'] [type="date"]:-ms-input-placeholder, [is='form'] [type="email"]:-ms-input-placeholder, [is='form'] [type="search"]:-ms-input-placeholder, [is='form'] [type="number"]:-ms-input-placeholder, [is='form'] [type="password"]:-ms-input-placeholder, [is='form'] textarea:-ms-input-placeholder, [is='form'] select:-ms-input-placeholder, [is='form'] .fakeInput:-ms-input-placeholder, [is='form'] .select:-ms-input-placeholder {
      color: #000;
      font-size: 1.6rem; }
    [is='form'] [type="tel"]::-ms-input-placeholder, [is='form'] [type="url"]::-ms-input-placeholder, [is='form'] [type="text"]::-ms-input-placeholder, [is='form'] [type="date"]::-ms-input-placeholder, [is='form'] [type="email"]::-ms-input-placeholder, [is='form'] [type="search"]::-ms-input-placeholder, [is='form'] [type="number"]::-ms-input-placeholder, [is='form'] [type="password"]::-ms-input-placeholder, [is='form'] textarea::-ms-input-placeholder, [is='form'] select::-ms-input-placeholder, [is='form'] .fakeInput::-ms-input-placeholder, [is='form'] .select::-ms-input-placeholder {
      color: #000;
      font-size: 1.6rem; }
    [is='form'] [type="tel"]::placeholder, [is='form'] [type="url"]::placeholder, [is='form'] [type="text"]::placeholder, [is='form'] [type="date"]::placeholder, [is='form'] [type="email"]::placeholder, [is='form'] [type="search"]::placeholder, [is='form'] [type="number"]::placeholder, [is='form'] [type="password"]::placeholder, [is='form'] textarea::placeholder, [is='form'] select::placeholder, [is='form'] .fakeInput::placeholder, [is='form'] .select::placeholder {
      color: #000;
      font-size: 1.6rem; }
  [is='form'] form .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  [is='form'] .fakeInput {
    position: relative;
    border-style: dashed;
    padding: 11px 15px; }
    [is='form'] .fakeInput img {
      position: absolute;
      height: 20px;
      right: 10px;
      width: auto;
      top: calc(50% - 10px); }
  [is='form'] .fakeInput + input {
    display: none; }
  [is='form'] form .tui-datetime-input {
    width: 100%;
    border: 0;
    height: auto; }
  [is='form'] form .tui-datepicker-input .tui-ico-date {
    margin: -13px 0 0 0; }
    @media (min-width: 576px) {
      [is='form'] form .tui-datepicker-input .tui-ico-date {
        margin: -18px 0 0 0; } }
  [is='form'] form .tui-calendar .tui-calendar-prev-month.tui-calendar-date, [is='form'] .tui-calendar .tui-calendar-next-month.tui-calendar-date {
    visibility: visible; }
  [is='form'] form .tui-datepicker {
    z-index: 1; }
  [is='form'] form .tui-calendar th, [is='form'] form .tui-calendar td {
    height: unset;
    padding: 5px 2px; }
  [is='form'] form .tui-timepicker {
    padding: 0;
    border: 0;
    background-color: transparent;
    display: inline-block; }
  [is='form'] form #timepicker-selectbox {
    text-align: center; }
  [is='form'] .tui-timepicker-select {
    height: auto; }
  [is='form'] .tui-timepicker-column {
    vertical-align: unset; }
  [is='form'] [type="checkbox"] + label::before, [is='form'] [type="radio"] + label::before {
    background-color: transparent;
    border: 1px solid #000;
    border-radius: 0px;
    height: 20px;
    width: 20px;
    top: 2px; }
  [is='form'] [type="checkbox"]:checked + label::before, [is='form'] [type="radio"]:checked + label::before {
    background-color: #43545B;
    -webkit-box-shadow: none;
            box-shadow: none;
    border-color: #000; }
  [is='form'] .radio {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-top: -1rem; }
    [is='form'] .radio label {
      width: 50%;
      margin-top: 1rem; }
  [is='form'] [type="radio"] + label {
    width: 50%; }
  [is='form'] [type="checkbox"] + label, [is='form'] [type="radio"] + label {
    cursor: pointer; }
  [is='form'] .tooltip-error {
    display: none;
    position: relative;
    color: #e01515;
    border-radius: 4px;
    padding: 3px 10px;
    top: -24px;
    font-size: 12px;
    -webkit-transition: all .45s ease-out .6s;
    transition: all .45s ease-out .6s; }
    [is='form'] .tooltip-error.show {
      display: inline-block; }
    [is='form'] .tooltip-error.checkbox {
      top: -5px; }
  [is='form'] [type="checkbox"] + label, [is='form'] [type="radio"] + label {
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    padding-left: 3rem; }
    @media (min-width: 576px) {
      [is='form'] [type="checkbox"] + label, [is='form'] [type="radio"] + label {
        padding-left: 4rem; } }
  [is='form'] textarea {
    height: 150px;
    min-height: 100px;
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out; }
    [is='form'] textarea:focus {
      height: 180px; }
  [is='form'] #validation-output {
    text-align: center;
    margin-top: 2rem; }
    [is='form'] #validation-output .message {
      padding: 10px 15px; }
      [is='form'] #validation-output .message.success {
        background-color: #217821;
        color: #fff; }
      [is='form'] #validation-output .message.error {
        background-color: #cb1414;
        color: #fff; }
  [is='form'] .buttonSend button {
    cursor: pointer;
    margin-right: 20px; }
  [is='form'] .form__block-1 {
    margin-bottom: 4rem; }
    @media (max-width: 992px) {
      [is='form'] .form__block-1 {
        margin-bottom: 0; } }
  [is='form'] .form__block-1 {
    margin-bottom: 60px; }
    @media (min-width: 992px) {
      [is='form'] .form__block-1 {
        margin-bottom: 0; } }
  [is='form'] .side-by-side__description {
    margin-top: 2rem; }
    @media (min-width: 768px) {
      [is='form'] .side-by-side__description {
        margin-top: 4rem; } }
  [is='form'] .form--bg-grey {
    background-color: #F2F2F2;
    padding-top: 7rem;
    padding-bottom: 7rem; }
    @media (min-width: 768px) {
      [is='form'] .form--bg-grey {
        padding-top: 10rem;
        padding-bottom: 10rem; } }
  @media (min-width: 992px) {
    [is='form'] .col-lg-6.form__block-1.text {
      padding-right: 6rem; } }
  @media (min-width: 768px) {
    [is='form'] .col-md-6.form__block-1.text {
      padding-right: 6rem; } }
  [is='form'] .side-by-side__title * {
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding-top: 0;
    margin-bottom: 0;
    font-family: "Barlow Regular"; }
    @media (min-width: 768px) {
      [is='form'] .side-by-side__title * {
        font-size: 2rem;
        line-height: 3rem; } }
    @media (min-width: 992px) {
      [is='form'] .side-by-side__title * {
        font-size: 2.1rem;
        line-height: 3.1rem; } }
    [is='form'] .side-by-side__title *:not(:last-child) {
      margin-bottom: 2rem; }
    [is='form'] .side-by-side__title *:not(:first-child) {
      margin-top: 4rem; }
  [is='form'] .form__block-1.text {
    margin-bottom: 4rem; }
  [is='form'] .step-back, [is='form'] .step-front {
    border-color: #86766C;
    color: #86766C;
    margin-top: 1rem; }
    @media (min-width: 576px) {
      [is='form'] .step-back, [is='form'] .step-front {
        margin-top: 2rem; } }
  [is='form'] div.step:not(.show) {
    display: none; }
  [is='form'] div.step:first-of-type .step-back .button, [is='form'] div.step:first-of-type .step-back .ctaFixed .ctaFixed__button, .ctaFixed [is='form'] div.step:first-of-type .step-back .ctaFixed__button {
    display: none; }
  [is='form'] .select {
    position: relative; }
    [is='form'] .select .label img {
      position: absolute;
      right: 10px;
      width: 17px;
      height: 10px;
      top: calc(50% - 5px);
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out; }
    [is='form'] .select.open .options {
      display: block; }
    [is='form'] .select.open .label img {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg); }
    [is='form'] .select .options {
      display: none;
      position: absolute;
      z-index: 1;
      left: -1px;
      right: -1px;
      top: calc(100% - 2px);
      background-color: #fff;
      border: 1px solid #000;
      border-radius: 0px;
      overflow: hidden;
      -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6);
      box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.6); }
      [is='form'] .select .options .item {
        padding: 10px 15px;
        cursor: pointer; }
        [is='form'] .select .options .item:hover {
          background-color: #ececec; }
  @media (max-width: 575px) {
    [is='form'] .button, [is='form'] .ctaFixed .ctaFixed__button, .ctaFixed [is='form'] .ctaFixed__button {
      font-size: 1.4rem; } }
  [is='form'] .col-form .send img {
    height: 40px;
    width: auto;
    display: block; }
  [is='form'] .col-form .send:not(.show) {
    display: none; }
  [is='form'] .col-form .send .description span {
    font-family: "Barlow Regular"; }

[is='gallery'] .gallery-loadMore {
  margin-top: 4rem;
  display: inline-block;
  font-family: "Barlow Bold";
  color: #000000;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: 2px;
  cursor: pointer; }

[is='gallery'] .gallery-list__item {
  height: 25vw;
  background-size: cover;
  background-position: center;
  position: relative; }
  @media (min-width: 1200px) {
    [is='gallery'] .gallery-list__item {
      height: 350px; } }
  [is='gallery'] .gallery-list__item a {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1; }

[is='gallery'] .gallery-grid {
  display: grid;
  gap: 1.5rem; }
  @media (min-width: 576px) {
    [is='gallery'] .gallery-grid {
      gap: 3rem; } }

[is='gallery'].gallery--template-2 .gallery-list__item:first-child {
  grid-column-start: 1;
  grid-column-end: 4; }

[is='gallery'].gallery--template-2 .gallery-list__item:nth-child(4n+3) {
  grid-column-start: 2;
  grid-column-end: 4; }

[is='gallery'].gallery--template-2 .gallery-list__item:nth-child(4n+4) {
  grid-column-start: 1;
  grid-column-end: 3; }

[is='gallery'].gallery--template-1 .gallery-list__item:nth-child(4n+2) {
  grid-column-start: 2;
  grid-column-end: 4; }

[is='gallery'].gallery--template-1 .gallery-list__item:nth-child(4n+3) {
  grid-column-start: 1;
  grid-column-end: 3; }

[is='gallery'] .gallery-header__title * {
  font-family: "Baskerville Regular";
  color: #000000; }

[is='gallery'] .gallery-header__title + .gallery-header__description {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    [is='gallery'] .gallery-header__title + .gallery-header__description {
      margin-top: 6rem; } }

[is='gallery'] .gallery-header + .gallery-grid {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    [is='gallery'] .gallery-header + .gallery-grid {
      margin-top: 6rem; } }

@media (max-width: 767px) {
  [is='gallery'] {
    overflow: hidden; }
    [is='gallery'] .slick-list {
      padding: 0 20px;
      overflow: visible;
      margin-left: -10px;
      margin-right: -10px; }
      [is='gallery'] .slick-list .slick-slide {
        margin-left: 10px;
        margin-right: 10px; } }

[is='google-maps'] {
  position: relative; }
  [is='google-maps'] .google-maps {
    overflow: hidden; }
  [is='google-maps'] #map {
    min-height: 500px;
    height: 70vh;
    max-height: 700px; }
    [is='google-maps'] #map .gm-style .gm-style-iw {
      font-size: 15px; }

[is='grid-post-list'] {
  /*
  // ANCHOR: list post
  */
  /*
  // ANCHOR: list reference
  */ }
  @media (max-width: 767px) {
    [is='grid-post-list'] .grid-posts__item .grid-posts__image figure {
      position: relative;
      margin-bottom: 0;
      max-width: unset;
      padding-top: 100%;
      width: unset;
      height: 100%; }
      [is='grid-post-list'] .grid-posts__item .grid-posts__image figure img {
        position: absolute;
        top: 0;
        left: 0;
        -o-object-fit: cover;
        object-fit: cover;
        width: 100%;
        height: 100%; } }
  [is='grid-post-list'] .button-theme {
    margin-top: 2rem; }
    @media (min-width: 768px) {
      [is='grid-post-list'] .button-theme {
        margin-top: 4rem; } }
  [is='grid-post-list'] .grid-posts__tax * {
    font-family: "Barlow Regular"; }
  [is='grid-post-list'] .grid-posts__last.row {
    margin-bottom: 3rem; }
    @media (min-width: 768px) {
      [is='grid-post-list'] .grid-posts__last.row {
        margin-bottom: 3rem; } }
    @media (min-width: 992px) {
      [is='grid-post-list'] .grid-posts__last.row {
        margin-bottom: 4rem; } }
    [is='grid-post-list'] .grid-posts__last.row .grid-posts__description {
      margin-top: 2rem; }
    [is='grid-post-list'] .grid-posts__last.row .image figure {
      position: relative; }
      [is='grid-post-list'] .grid-posts__last.row .image figure a {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1; }
    @media (max-width: 767px) {
      [is='grid-post-list'] .grid-posts__last.row .image figure {
        position: relative;
        margin-bottom: 0;
        max-width: unset;
        padding-top: 100%;
        width: unset;
        height: 100%; }
        [is='grid-post-list'] .grid-posts__last.row .image figure img {
          position: absolute;
          top: 0;
          left: 0;
          -o-object-fit: cover;
          object-fit: cover;
          width: 100%;
          height: 100%; }
        [is='grid-post-list'] .grid-posts__last.row .image figure a {
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: 1; } }
    [is='grid-post-list'] .grid-posts__last.row .grid-posts__image {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 15px;
      right: 15px;
      background-size: cover;
      background-position: center; }
      @media (min-width: 768px) {
        [is='grid-post-list'] .grid-posts__last.row .grid-posts__image {
          right: 0; } }
    [is='grid-post-list'] .grid-posts__last.row .text {
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      @media (min-width: 768px) {
        [is='grid-post-list'] .grid-posts__last.row .text {
          padding-left: 50px; } }
      @media (max-width: 767px) {
        [is='grid-post-list'] .grid-posts__last.row .text {
          padding-top: 3rem;
          padding-bottom: 3rem; } }
      [is='grid-post-list'] .grid-posts__last.row .text::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 15px;
        right: 15px;
        background-color: transparent; }
        @media (min-width: 768px) {
          [is='grid-post-list'] .grid-posts__last.row .text::before {
            left: 0; } }
      [is='grid-post-list'] .grid-posts__last.row .text * {
        position: relative; }
  [is='grid-post-list'] .grid-post-list__top {
    margin-bottom: 4rem; }
    @media (min-width: 768px) {
      [is='grid-post-list'] .grid-post-list__top {
        margin-bottom: 6rem; } }
    [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title + .grid-post-list__top-description {
      margin-top: 2rem; }
      @media (min-width: 768px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title + .grid-post-list__top-description {
          margin-top: 4rem; } }
      @media (min-width: 992px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title + .grid-post-list__top-description {
          margin-top: 6rem; } }
    @media (min-width: 576px) {
      [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-description {
        font-size: 1.8rem;
        line-height: 2.8rem; } }
    [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title * {
      font-size: 2.6rem;
      line-height: 3.6rem;
      padding-top: 0;
      margin-bottom: 0;
      font-family: "Barlow Bold"; }
      @media (min-width: 576px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title * {
          font-size: 3rem;
          line-height: 4rem; } }
      @media (min-width: 768px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title * {
          font-size: 4rem;
          line-height: 5rem; } }
      @media (min-width: 992px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title * {
          font-size: 4.5rem;
          line-height: 5.5rem; } }
      @media (min-width: 1200px) {
        [is='grid-post-list'] .grid-post-list__top .grid-post-list__top-title * {
          font-size: 5rem;
          line-height: 6rem; } }
  [is='grid-post-list'] .grid-post-list--post [data-columns]::before, [is='grid-post-list'] .grid-post-list--press [data-columns]::before {
    content: '1 .column.col-12'; }
    @media (min-width: 576px) {
      [is='grid-post-list'] .grid-post-list--post [data-columns]::before, [is='grid-post-list'] .grid-post-list--press [data-columns]::before {
        content: '2 .column.col-sm-6'; } }
  [is='grid-post-list'] .grid-post-list--post .column, [is='grid-post-list'] .grid-post-list--press .column {
    float: left; }
  [is='grid-post-list'] .grid-post-list--post .grid-posts__image, [is='grid-post-list'] .grid-post-list--press .grid-posts__image {
    position: relative; }
    [is='grid-post-list'] .grid-post-list--post .grid-posts__image a, [is='grid-post-list'] .grid-post-list--press .grid-posts__image a {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 1; }
    [is='grid-post-list'] .grid-post-list--post .grid-posts__image img.grid-posts__image-play, [is='grid-post-list'] .grid-post-list--press .grid-posts__image img.grid-posts__image-play {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      width: 60px;
      left: calc(50% - 30px);
      top: calc(50% - 30px); }
      @media (min-width: 576px) {
        [is='grid-post-list'] .grid-post-list--post .grid-posts__image img.grid-posts__image-play, [is='grid-post-list'] .grid-post-list--press .grid-posts__image img.grid-posts__image-play {
          width: 100px;
          left: calc(50% - 50px);
          top: calc(50% - 50px); } }
  [is='grid-post-list'] .grid-post-list--post .grid-posts__info-post, [is='grid-post-list'] .grid-post-list--press .grid-posts__info-post {
    margin-top: 2rem; }
    @media (min-width: 768) {
      [is='grid-post-list'] .grid-post-list--post .grid-posts__info-post, [is='grid-post-list'] .grid-post-list--press .grid-posts__info-post {
        margin-top: 3rem; } }
    [is='grid-post-list'] .grid-post-list--post .grid-posts__info-post .separator, [is='grid-post-list'] .grid-post-list--press .grid-posts__info-post .separator {
      margin: 0 5px; }
  [is='grid-post-list'] .grid-post-list--post .grid-posts__title, [is='grid-post-list'] .grid-post-list--press .grid-posts__title {
    font-family: "Barlow Bold";
    margin-top: 1.5rem; }
  [is='grid-post-list'] .grid-post-list--post .grid-posts__link, [is='grid-post-list'] .grid-post-list--press .grid-posts__link {
    font-family: "Barlow Bold";
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 1rem; }
  @media (max-width: 767px) {
    [is='grid-post-list'] .grid-post-list--post .column .grid-posts__item, [is='grid-post-list'] .grid-post-list--press .column .grid-posts__item {
      margin-top: 4rem; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [is='grid-post-list'] .grid-post-list--post .column .grid-posts__item, [is='grid-post-list'] .grid-post-list--press .column .grid-posts__item {
      margin-top: 6rem; } }
  @media (min-width: 992px) {
    [is='grid-post-list'] .grid-post-list--post .column .grid-posts__item, [is='grid-post-list'] .grid-post-list--press .column .grid-posts__item {
      margin-top: 8rem; } }
  @media (max-width: 575px) {
    [is='grid-post-list'] .grid-post-list--referenza .filtro-blog.filtro-blog--onlyCat .select {
      width: 180px; } }
  [is='grid-post-list'] .grid-post-list--referenza [data-columns]::before {
    content: '1 .column.col-12'; }
    @media (min-width: 576px) {
      [is='grid-post-list'] .grid-post-list--referenza [data-columns]::before {
        content: '2 .column.col-sm-6'; } }
  [is='grid-post-list'] .grid-post-list--referenza .column {
    float: left; }
  [is='grid-post-list'] .grid-post-list--referenza .grid-posts__image {
    position: relative;
    cursor: pointer; }
  [is='grid-post-list'] .grid-post-list--referenza .grid-posts__info-post {
    margin-top: 2rem; }
    @media (min-width: 768) {
      [is='grid-post-list'] .grid-post-list--referenza .grid-posts__info-post {
        margin-top: 3rem; } }
    [is='grid-post-list'] .grid-post-list--referenza .grid-posts__info-post .separator {
      margin: 0 5px; }
    [is='grid-post-list'] .grid-post-list--referenza .grid-posts__info-post .category {
      text-transform: uppercase; }
  [is='grid-post-list'] .grid-post-list--referenza .grid-posts__tax {
    font-size: 14px; }
  [is='grid-post-list'] .grid-post-list--referenza .grid-posts__title {
    font-family: "Barlow Bold";
    font-size: 18px;
    margin-top: 1rem; }
    @media (min-width: 768) {
      [is='grid-post-list'] .grid-post-list--referenza .grid-posts__title {
        margin-top: 2rem; } }
  [is='grid-post-list'] .grid-post-list--referenza .grid-posts__link {
    font-family: "Barlow Bold";
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 1rem; }
  @media (max-width: 767px) {
    [is='grid-post-list'] .grid-post-list--referenza .column .grid-posts__item {
      margin-top: 4rem; }
    [is='grid-post-list'] .grid-post-list--referenza .column .grid-posts__item:first-child {
      margin-top: 0; } }
  @media (min-width: 768px) and (max-width: 991px) {
    [is='grid-post-list'] .grid-post-list--referenza .column .grid-posts__item {
      margin-top: 6rem; }
    [is='grid-post-list'] .grid-post-list--referenza .column:nth-child(1) .grid-posts__item:first-child, [is='grid-post-list'] .grid-post-list--referenza .column:nth-child(2) .grid-posts__item:first-child {
      margin-top: 0; } }
  @media (min-width: 992px) {
    [is='grid-post-list'] .grid-post-list--referenza .column .grid-posts__item {
      margin-top: 6rem; }
    [is='grid-post-list'] .grid-post-list--referenza .column:nth-child(1) .grid-posts__item:first-child, [is='grid-post-list'] .grid-post-list--referenza .column:nth-child(2) .grid-posts__item:first-child, [is='grid-post-list'] .grid-post-list--referenza .column:nth-child(3) .grid-posts__item:first-child {
      margin-top: 0; } }
  @media (max-width: 575px) {
    [is='grid-post-list'] .grid-post-list--referenza .column:last-child .grid-posts__item:first-child {
      margin-top: 0; } }
  [is='grid-post-list'] .filtro-blog {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    margin-bottom: 4rem;
    /*
	// ANCHOR: onlyCat
	*/ }
    @media (min-width: 768px) {
      [is='grid-post-list'] .filtro-blog {
        margin-bottom: 6rem; } }
    [is='grid-post-list'] .filtro-blog .select {
      position: relative;
      display: inline-block;
      text-align: left;
      width: 115px; }
      @media (min-width: 321px) {
        [is='grid-post-list'] .filtro-blog .select {
          width: 125px; } }
      @media (min-width: 576px) {
        [is='grid-post-list'] .filtro-blog .select {
          width: 180px; } }
      @media (min-width: 768px) {
        [is='grid-post-list'] .filtro-blog .select {
          width: unset; } }
    [is='grid-post-list'] .filtro-blog .select__title {
      color: #000;
      text-transform: uppercase;
      cursor: pointer;
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      padding: 15px 0; }
    [is='grid-post-list'] .filtro-blog .select__arrow {
      height: 8px;
      width: auto;
      -ms-flex-item-align: center;
          align-self: center;
      margin-left: auto; }
    [is='grid-post-list'] .filtro-blog .select__option {
      display: none;
      position: absolute;
      background-color: #fff;
      padding: 1rem 1.5rem;
      left: 0;
      right: 0;
      top: 100%;
      z-index: 2;
      -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
      box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2); }
      [is='grid-post-list'] .filtro-blog .select__option a.item {
        display: block;
        width: 100%;
        text-decoration: none;
        padding: 5px 0;
        font-size: 1.4rem; }
        @media (min-width: 576px) {
          [is='grid-post-list'] .filtro-blog .select__option a.item {
            font-size: 1.6rem; } }
        [is='grid-post-list'] .filtro-blog .select__option a.item:hover {
          text-decoration: underline; }
        [is='grid-post-list'] .filtro-blog .select__option a.item.active {
          font-family: "Barlow Bold"; }
      @media (min-width: 768px) {
        [is='grid-post-list'] .filtro-blog .select__option {
          width: 200px; } }
    [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__categorie {
      text-align: left; }
      @media (min-width: 768px) {
        [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__categorie .select__option {
          right: unset; } }
    [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__archivio {
      text-align: right; }
      [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__archivio .select__option {
        text-align: right; }
        @media (min-width: 768px) {
          [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__archivio .select__option {
            left: unset; } }
    [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__categorie, [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .filtro-blog__archivio {
      width: 50%; }
    @media only screen and (min-width: 768px) {
      [is='grid-post-list'] .filtro-blog.filtro-blog--catDate {
        margin-bottom: 6rem; }
        [is='grid-post-list'] .filtro-blog.filtro-blog--catDate .select__arrow {
          width: 20px;
          display: inline-block;
          margin-left: 1.5rem; } }
    [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat .select {
      display: block; }
    [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat .filtro-blog__categorie {
      display: inline-block;
      width: 100%; }
    [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat .filtro-blog__categorie-title {
      margin-bottom: 1rem;
      font-family: "Barlow Bold";
      text-transform: uppercase; }
      @media (min-width: 1200px) {
        [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat .filtro-blog__categorie-title {
          display: inline-block;
          margin-right: 4rem; } }
    [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat ul {
      padding: 0;
      margin: 0; }
      @media (min-width: 1200px) {
        [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat ul {
          display: inline-block; } }
      [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat ul li {
        display: inline-block;
        text-transform: lowercase;
        margin-right: auto; }
        [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat ul li.active {
          color: #000000;
          font-family: "Barlow Bold"; }
        [is='grid-post-list'] .filtro-blog.filtro-blog--onlyCat ul li:not(:last-child) {
          margin-right: 2rem; }
  [is='grid-post-list'] .grid-post-list--referenza .filtro-blog.filtro-blog--onlyCat ul li {
    text-transform: none; }
    [is='grid-post-list'] .grid-post-list--referenza .filtro-blog.filtro-blog--onlyCat ul li.active {
      color: #000; }
  [is='grid-post-list'] .pagination--load-more {
    margin-top: 4rem;
    width: 100%;
    padding: 0 15px;
    text-align: center;
    font-family: "Barlow Bold";
    font-size: 16px;
    text-transform: uppercase; }

[is='hero-home'] {
  position: relative; }
  [is='hero-home'] .hero-home {
    position: relative;
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    [is='hero-home'] .hero-home::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.337);
      z-index: 1; }
    [is='hero-home'] .hero-home .container-lg_ps {
      max-width: 1400px;
      width: 100%;
      padding-right: 30px;
      padding-left: 30px;
      margin-right: auto;
      margin-left: auto; }
    [is='hero-home'] .hero-home .container-cols {
      width: 100%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row; }
      @media (max-width: 991px) {
        [is='hero-home'] .hero-home .container-cols {
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; } }
      [is='hero-home'] .hero-home .container-cols .hero-home__col {
        position: static;
        width: 33.33%;
        min-height: 150px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center; }
        @media (max-width: 991px) {
          [is='hero-home'] .hero-home .container-cols .hero-home__col {
            min-height: 90px; } }
        [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__bg {
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          z-index: 0;
          background-position: center;
          background-repeat: no-repeat;
          background-size: cover;
          opacity: 0;
          -webkit-transition: all 1s;
          transition: all 1s; }
        [is='hero-home'] .hero-home .container-cols .hero-home__col:hover .hero-home__bg {
          opacity: 1; }
        [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__col-content {
          position: relative;
          z-index: 2;
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-pack: center;
              -ms-flex-pack: center;
                  justify-content: center;
          width: 100%; }
          [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__col-content .hero-home__title {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center; }
            [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__col-content .hero-home__title h2 {
              color: #fff;
              font-family: "Baskerville Regular";
              -webkit-transition: all .2s ease-out;
              transition: all .2s ease-out; }
          [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__col-content a {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0; }
          [is='hero-home'] .hero-home .container-cols .hero-home__col .hero-home__col-content:hover .hero-home__title h2 {
            font-family: "Baskerville Italic"; }
      [is='hero-home'] .hero-home .container-cols .hero-home__col:first-child .hero-home__col-content {
        position: relative; }
        [is='hero-home'] .hero-home .container-cols .hero-home__col:first-child .hero-home__col-content::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          height: 160px;
          width: 1px;
          background-color: #fff;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out; }
          @media (max-width: 991px) {
            [is='hero-home'] .hero-home .container-cols .hero-home__col:first-child .hero-home__col-content::after {
              bottom: 0;
              right: unset;
              width: 50px;
              height: 1px;
              top: unset;
              -webkit-transform: unset;
              transform: unset; } }
        [is='hero-home'] .hero-home .container-cols .hero-home__col:first-child .hero-home__col-content:hover::after {
          height: 120px; }
          @media (max-width: 991px) {
            [is='hero-home'] .hero-home .container-cols .hero-home__col:first-child .hero-home__col-content:hover::after {
              height: 1px; } }
      [is='hero-home'] .hero-home .container-cols .hero-home__col:nth-child(2) .hero-home__col-content {
        position: relative; }
        [is='hero-home'] .hero-home .container-cols .hero-home__col:nth-child(2) .hero-home__col-content::after {
          content: '';
          position: absolute;
          right: 0;
          top: 50%;
          -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
          height: 160px;
          width: 1px;
          background-color: #fff;
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out; }
          @media (max-width: 991px) {
            [is='hero-home'] .hero-home .container-cols .hero-home__col:nth-child(2) .hero-home__col-content::after {
              bottom: 0;
              right: unset;
              width: 50px;
              height: 1px;
              top: unset;
              -webkit-transform: unset;
              transform: unset; } }
        [is='hero-home'] .hero-home .container-cols .hero-home__col:nth-child(2) .hero-home__col-content:hover::after {
          height: 120px; }
          @media (max-width: 991px) {
            [is='hero-home'] .hero-home .container-cols .hero-home__col:nth-child(2) .hero-home__col-content:hover::after {
              height: 1px; } }

[is='hero-slider'] {
  position: relative; }
  [is='hero-slider'] .grid-posts__tax {
    color: #fff;
    text-align: center; }
  [is='hero-slider'] .of-cover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  [is='hero-slider'] .slide::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 25%;
    background: white;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(255, 255, 255, 0)), to(rgba(0, 0, 0, 0.697917)));
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.697917) 100%); }
  [is='hero-slider'] .slide::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1;
    height: 45%;
    background: black;
    background: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.697917)), to(rgba(255, 255, 255, 0)));
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.697917) 0%, rgba(255, 255, 255, 0) 100%); }
  [is='hero-slider'] .slider .slide {
    height: 65vh;
    background-size: cover;
    background-position: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    min-height: 100vh; }
    [is='hero-slider'] .slider .slide figure {
      height: 100%; }
  [is='hero-slider'] .flex-center {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 30px; }
    @media (max-width: 991px) {
      [is='hero-slider'] .flex-center {
        gap: 15px; } }
  [is='hero-slider'] .slider-claim__title {
    color: #fff;
    font-family: "Barlow Bold";
    text-transform: uppercase; }
  [is='hero-slider'] .slider-claim {
    position: relative;
    top: -100px; }
  [is='hero-slider'] .slider-point {
    position: absolute;
    top: 50%;
    left: 47%; }
    [is='hero-slider'] .slider-point span {
      font-family: "Barlow Bold";
      color: #fff;
      margin-left: 100px;
      display: block;
      margin-bottom: 15px;
      text-transform: uppercase; }
  [is='hero-slider'] .slide-title {
    -ms-flex-item-align: center;
        align-self: center;
    position: relative;
    text-align: center; }
    @media (max-width: 575px) {
      [is='hero-slider'] .slide-title {
        padding: 0 15px; } }
    [is='hero-slider'] .slide-title * {
      color: #fff;
      font-family: "Baskerville Regular"; }
  [is='hero-slider'] .slide-breadcrumbs {
    color: #fff;
    text-align: center;
    text-transform: uppercase;
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-family: "Barlow Regular"; }
    @media (min-width: 576px) {
      [is='hero-slider'] .slide-breadcrumbs {
        font-size: 1.2rem; } }
  [is='hero-slider'] .breadcrumbs span *, [is='hero-slider'] .breadcrumbs .breadcrumbs__separator {
    font-family: "Barlow Regular";
    color: #fff; }
  [is='hero-slider'] .slide-play {
    position: absolute;
    width: 60px;
    height: auto;
    left: 50%;
    top: 50%;
    cursor: pointer;
    z-index: 1;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    [is='hero-slider'] .slide-play.hide {
      display: none; }
    @media (min-width: 576px) {
      [is='hero-slider'] .slide-play {
        width: 80px; } }
    @media (min-width: 992px) {
      [is='hero-slider'] .slide-play {
        width: 90px; } }
    @media (min-width: 1200px) {
      [is='hero-slider'] .slide-play {
        width: 100px; } }
  [is='hero-slider'] .slick-arrow {
    position: absolute;
    z-index: 1;
    font-size: 0;
    line-height: 0;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    width: 30px;
    height: 30px;
    cursor: pointer;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    top: 50%; }
    [is='hero-slider'] .slick-arrow.slick-disabled {
      display: none !important; }
  [is='hero-slider'] .slick-prev {
    left: 1.5rem;
    background-image: url("/wp-content/themes/farmerbit/dist/Components/HeroSlider/img/arrow-left.svg"); }
    @media (min-width: 576px) {
      [is='hero-slider'] .slick-prev {
        left: 4rem; } }
    @media (min-width: 992px) {
      [is='hero-slider'] .slick-prev {
        left: 6rem; } }
  [is='hero-slider'] .slick-next {
    right: 1.5rem;
    background-image: url("/wp-content/themes/farmerbit/dist/Components/HeroSlider/img/arrow-right.svg"); }
    @media (min-width: 576px) {
      [is='hero-slider'] .slick-next {
        right: 4rem; } }
    @media (min-width: 992px) {
      [is='hero-slider'] .slick-next {
        right: 6rem; } }
  [is='hero-slider'] .slide-video {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    -webkit-transition: all 1s ease-out;
    transition: all 1s ease-out; }
    [is='hero-slider'] .slide-video.show {
      opacity: 1; }
  [is='hero-slider'] .slide-mute {
    position: absolute;
    top: 15px;
    right: 30px;
    z-index: 1;
    color: #fff;
    cursor: pointer;
    font-family: "Barlow Bold";
    text-shadow: 0 0 3px black; }
    @media (min-width: 576px) {
      [is='hero-slider'] .slide-mute {
        bottom: 30px;
        right: 30px;
        top: unset; } }
    @media (min-width: 992px) {
      [is='hero-slider'] .slide-mute {
        bottom: 60px;
        right: 60px; } }
    [is='hero-slider'] .slide-mute.off .on {
      display: none; }
    [is='hero-slider'] .slide-mute.on .off {
      display: none; }
  [is='hero-slider'] .slide-hasVideo.paused .slide-video {
    opacity: 0; }
  [is='hero-slider'] .slide-hasVideo.paused .slide-mute {
    display: none; }

@-webkit-keyframes header-line {
  0% {
    -webkit-transform-origin: center top;
    transform-origin: center top;
    -webkit-transform: scaleY(0);
    transform: scaleY(0); }
  24% {
    -webkit-transform-origin: center top;
    transform-origin: center top; }
  25% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  50% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; }
  100% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: center bottom;
    transform-origin: center bottom; } }
  [is='hero-slider'] .go-to {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 50%;
    text-align: center;
    cursor: pointer;
    font-family: "Barlow Regular";
    font-size: 1.4rem;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%); }
    [is='hero-slider'] .go-to__link {
      padding: 0 1rem;
      display: inline-block;
      line-height: 1;
      font-size: 0; }
    [is='hero-slider'] .go-to__line {
      display: inline-block;
      width: 1px;
      background-color: white;
      height: 5rem;
      -webkit-animation: header-line 2.6s infinite;
      animation: header-line 2.6s infinite; }
    [is='hero-slider'] .go-to__link, [is='hero-slider'] .go-to__line {
      vertical-align: bottom; }
    [is='hero-slider'] .go-to__title {
      color: white;
      display: block;
      text-transform: uppercase;
      text-decoration: none;
      line-height: 1;
      margin-bottom: 1.5rem; }
      [is='hero-slider'] .go-to__title:hover, [is='hero-slider'] .go-to__title:active, [is='hero-slider'] .go-to__title:visited, [is='hero-slider'] .go-to__title:focus {
        color: white; }

[is='info-list'] .info-list__title {
  margin-bottom: 3rem; }

[is='info-list'] .row + .info-list__list {
  margin-top: 4rem; }
  @media (min-width: 768px) {
    [is='info-list'] .row + .info-list__list {
      margin-top: 6rem; } }

[is='info-list'] .info-list__items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px; }

[is='info-list'] .info-list__item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  gap: 20px;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start; }

[is='single-block'] {
  /*--------------------------------------------
	Helpers
	--------------------------------------------*/ }
  [is='single-block'] .tris-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 50px;
    padding-bottom: 50px; }
    [is='single-block'] .tris-btns a.tris-btn {
      font-weight: 800;
      border-bottom: 1px solid #000;
      padding-bottom: 3px;
      cursor: pointer; }
  [is='single-block'] .single-block__icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-bottom: 50px; }
    @media (max-width: 991px) {
      [is='single-block'] .single-block__icon {
        margin-bottom: 30px; } }
  @media (min-width: 992px) {
    [is='single-block'].boxBottomX .single-block::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8rem;
      background-color: #F2F2F2;
      z-index: -1; } }
  @media (min-width: 992px) and (max-width: 1199px) {
    [is='single-block'].boxBottomX .single-block .text {
      padding-top: 10rem;
      padding-bottom: 10rem; }
    [is='single-block'].boxBottomX .single-block::after {
      height: 6rem; } }
  @media (max-width: 991px) {
    [is='single-block'].boxBottomX .single-block {
      margin-bottom: 6rem; } }
  [is='single-block'] .single-block--bgColor {
    padding-top: 6rem;
    padding-bottom: 6rem; }
    @media (min-width: 768px) {
      [is='single-block'] .single-block--bgColor {
        padding-top: 8rem;
        padding-bottom: 8rem; } }
  [is='single-block'] .single-block__title * {
    padding-top: 0;
    margin-bottom: 0;
    font-family: "Baskerville Regular";
    font-weight: 400; }
  [is='single-block'] .single-block {
    position: relative; }
  [is='single-block'] .single-block__title + .single-block__description {
    margin-top: 2rem; }
    @media (min-width: 768px) {
      [is='single-block'] .single-block__title + .single-block__description {
        margin-top: 4rem; } }
  [is='single-block'] .single-block__description h2 {
    color: #000000; }
    [is='single-block'] .single-block__description h2:not(:last-child) {
      margin-bottom: 2rem; }
      @media (min-width: 992px) {
        [is='single-block'] .single-block__description h2:not(:last-child) {
          margin-bottom: 2rem; } }
    [is='single-block'] .single-block__description h2:not(:first-child) {
      margin-top: 4rem; }
      @media (min-width: 992px) {
        [is='single-block'] .single-block__description h2:not(:first-child) {
          margin-bottom: 2rem; } }
  [is='single-block'] .single-block__description h3 {
    font-size: 1.8rem;
    line-height: 2.8rem;
    padding-top: 0;
    margin-bottom: 0;
    font-family: "Barlow Medium"; }
    @media (min-width: 768px) {
      [is='single-block'] .single-block__description h3 {
        font-size: 2rem;
        line-height: 3rem; } }
    @media (min-width: 992px) {
      [is='single-block'] .single-block__description h3 {
        font-size: 2.1rem;
        line-height: 3.1rem; } }
    [is='single-block'] .single-block__description h3:not(:last-child) {
      margin-bottom: 2rem; }
    [is='single-block'] .single-block__description h3:not(:first-child) {
      margin-top: 4rem; }
    html.single-post [is='single-block'] .single-block__description h3 {
      font-family: "Baskerville Regular";
      color: #000000; }
  [is='single-block'] .single-block__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  [is='single-block'] .single-block__figure {
    position: relative;
    height: 100%; }
  @media (min-width: 768px) {
    [is='single-block'] .single-block__link {
      margin-top: 4rem; } }
  [is='single-block'] .single-block__link-cover {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; }
  [is='single-block'] .single-block__image {
    display: block;
    width: 100%; }
  [is='single-block'] .single-block__figure-mobile {
    margin-bottom: 2rem;
    margin-top: 2.5rem; }
    @media (min-width: 768px) {
      [is='single-block'] .single-block__figure-mobile {
        margin-top: 4.5rem;
        margin-bottom: 4rem; } }
  @media (min-width: 992px) {
    [is='single-block'] .left.text {
      padding-right: 6rem; } }
  @media (min-width: 992px) {
    [is='single-block'] .right.text {
      padding-left: 6rem; } }
  @media (max-width: 767px) {
    [is='single-block'] .right[class^=col-md-] {
      margin-top: 6rem; } }
  @media (max-width: 991px) {
    [is='single-block'] .right[class^=col-lg-] {
      margin-top: 6rem; } }
  @media (max-width: 991px) {
    [is='single-block'] .right[class^=col-lg-][class^=col-md-] {
      margin-top: 0; } }
  @media (max-width: 767px) {
    [is='single-block'] .right[class^=col-lg-][class^=col-md-] {
      margin-top: 6rem; } }
  @media (max-width: 991px) {
    [is='single-block'] .same-height .image {
      display: none; } }
  [is='single-block'] .of-cover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  [is='single-block'] .strech-lg-right .of-cover {
    height: 300px; }
    @media (min-width: 992px) {
      [is='single-block'] .strech-lg-right .of-cover {
        height: 600px; } }
  [is='single-block'] .of-contain {
    -o-object-fit: contain;
       object-fit: contain; }
  @media (min-width: 768px) {
    [is='single-block'] .single-block__body {
      position: relative;
      top: 50%;
      -webkit-transform: translateY(-50%);
              transform: translateY(-50%); } }
  @media (min-width: 992px) {
    [is='single-block'] {
      /* Helpers */ }
      [is='single-block'] .abs-lg-valign {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      [is='single-block'] .strech-lg-left,
      [is='single-block'] .strech-lg-right {
        position: relative;
        width: 50%; }
      [is='single-block'] .strech-lg-left {
        right: 50%;
        left: 0; }
      [is='single-block'] .strech-lg-right {
        left: 50%;
        right: 0; }
      [is='single-block'] .left-lg-auto {
        left: auto; }
      [is='single-block'] .single-block__figure-mobile {
        display: none; } }
  @media (max-width: 991px) {
    [is='single-block'] .strech-lg-right, [is='single-block'] .strech-lg-left {
      display: none; } }
  [is='single-block'] .single-block__pdf {
    margin-top: 4rem; }
    @media (min-width: 768px) {
      [is='single-block'] .single-block__pdf {
        margin-top: 6rem; } }
    @media (min-width: 992px) {
      [is='single-block'] .single-block__pdf {
        margin-top: 8rem; } }
    [is='single-block'] .single-block__pdf a {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    [is='single-block'] .single-block__pdf img {
      max-width: 40px;
      max-height: 40px;
      margin-right: 2rem; }
      @media (min-width: 992px) {
        [is='single-block'] .single-block__pdf img {
          max-width: 60px;
          max-height: 60px; } }
    [is='single-block'] .single-block__pdf span {
      font-family: "Barlow Bold";
      text-transform: uppercase; }
  [is='single-block'] .taxonomy-header a {
    font-family: "Barlow Bold";
    text-transform: uppercase; }
    [is='single-block'] .taxonomy-header a img {
      height: 11px;
      width: auto;
      margin-right: 1rem;
      display: inline-block; }

[is='flynt-list-components'] .components {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 100%; }
  [is='flynt-list-components'] .components > * {
    width: calc((100%) - (0 / 1 * 24px)); }
    [is='flynt-list-components'] .components > *:nth-child(n) {
      margin-right: 24px;
      margin-top: 0; }
    [is='flynt-list-components'] .components > *:nth-child(1n + 2) {
      margin-top: 48px; }
    [is='flynt-list-components'] .components > *:nth-child(1n) {
      margin-right: 0; }
    [is='flynt-list-components'] .components > *:last-child {
      margin-right: 0; }
  @media (min-width: 768px) {
    [is='flynt-list-components'] .components {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 100%; }
      [is='flynt-list-components'] .components > * {
        width: calc((50%) - (1 / 2 * 24px)); }
        [is='flynt-list-components'] .components > *:nth-child(n) {
          margin-right: 24px;
          margin-top: 0; }
        [is='flynt-list-components'] .components > *:nth-child(1n + 3) {
          margin-top: 48px; }
        [is='flynt-list-components'] .components > *:nth-child(2n) {
          margin-right: 0; }
        [is='flynt-list-components'] .components > *:last-child {
          margin-right: 0; } }
  @media (min-width: 1024px) {
    [is='flynt-list-components'] .components {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 100%; }
      [is='flynt-list-components'] .components > * {
        width: calc((33.33333%) - (2 / 3 * 24px)); }
        [is='flynt-list-components'] .components > *:nth-child(n) {
          margin-right: 24px;
          margin-top: 0; }
        [is='flynt-list-components'] .components > *:nth-child(1n + 4) {
          margin-top: 48px; }
        [is='flynt-list-components'] .components > *:nth-child(3n) {
          margin-right: 0; }
        [is='flynt-list-components'] .components > *:last-child {
          margin-right: 0; } }

[is='flynt-list-components'] .component {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  max-width: 600px; }
  [is='flynt-list-components'] .component-imageWrapper {
    background-color: #fff;
    border-bottom: 1px solid #d8d8d8;
    height: 0;
    overflow: hidden;
    padding-bottom: 62.5%;
    position: relative;
    width: 100%; }
    [is='flynt-list-components'] .component-imageWrapper .image {
      position: absolute; }
      [is='flynt-list-components'] .component-imageWrapper .image--isSmallerThanWrapper {
        font-family: 'object-fit: contain';
        height: 100%;
        -o-object-fit: contain;
           object-fit: contain; }
  [is='flynt-list-components'] .component-link {
    display: block;
    text-decoration: none;
    width: 100%; }
  [is='flynt-list-components'] .component-description {
    color: #000000;
    position: relative; }
    [is='flynt-list-components'] .component-description > :first-child {
      margin-top: 0;
      padding-top: 0; }
    [is='flynt-list-components'] .component-description > :last-child {
      margin-bottom: 0;
      padding-bottom: 0; }
    [is='flynt-list-components'] .component-description h1, [is='flynt-list-components'] .component-description h2, [is='flynt-list-components'] .component-description h3, [is='flynt-list-components'] .component-description h4, [is='flynt-list-components'] .component-description h5, [is='flynt-list-components'] .component-description h6, [is='flynt-list-components'] .component-description .h1, [is='flynt-list-components'] .component-description .h2, [is='flynt-list-components'] .component-description .h3, [is='flynt-list-components'] .component-description .h4, [is='flynt-list-components'] .component-description .h5, [is='flynt-list-components'] .component-description .h6 {
      color: #0b1016; }
  [is='flynt-list-components'] .component-footer {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -ms-flex-item-align: end;
        align-self: flex-end;
    border-top: 1px solid #d8d8d8;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 56px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 0 20px;
    width: 100%; }
    [is='flynt-list-components'] .component-footer .component-ctaSecondary {
      color: #7b838d;
      text-decoration: none; }
      [is='flynt-list-components'] .component-footer .component-ctaSecondary .feather {
        color: inherit;
        margin-right: 2px; }
      [is='flynt-list-components'] .component-footer .component-ctaSecondary:hover {
        color: #f96417; }
    [is='flynt-list-components'] .component-footer .component-ctaPrimary {
      float: right;
      margin-left: auto;
      padding: 0.9rem 0 1.1rem; }
      [is='flynt-list-components'] .component-footer .component-ctaPrimary::before {
        bottom: 1.1rem; }
    @media (min-width: 1024px) {
      [is='flynt-list-components'] .component-footer {
        padding: 0 30px; } }

@media (min-width: 992px) {
  [is='list-rooms'] .list-rooms [is="side-by-side"]:not(:first-child) {
    margin-top: -6rem; } }

[is='list-w-point'] .list-w-point__title {
  margin-bottom: 4rem; }
  [is='list-w-point'] .list-w-point__title * {
    font-family: "Baskerville Regular";
    color: #000000; }

[is='list-w-point'] .list-w-point__item-title {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 1.6rem;
  line-height: 2.6rem; }
  [is='list-w-point'] .list-w-point__item-title img {
    height: 40px;
    width: 40px;
    display: block;
    vertical-align: sub;
    margin-bottom: 1rem; }
    @media (min-width: 768px) {
      [is='list-w-point'] .list-w-point__item-title img {
        height: 24px;
        width: 24px;
        margin-right: 1rem;
        margin-bottom: unset;
        display: inline-block; } }

[is='list-w-point'] .list-w-point__item-title + .list-w-point__item-content {
  margin-top: 2rem; }

[is='list-w-point'] .list-w-point__item:not(:first-child) {
  margin-top: 4rem; }

[is="menu"] .menu-title__main {
  margin-bottom: 50px; }
  @media (max-width: 991px) {
    [is="menu"] .menu-title__main {
      margin-bottom: 30px; } }

[is="menu"] .color-grey {
  color: #484747; }

[is="menu"] .bold {
  font-family: 'Barlow Bold'; }

[is="menu"] .menu-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  gap: 40px; }
  [is="menu"] .menu-list .menu-voce {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between; }
    [is="menu"] .menu-list .menu-voce .menu-voce__text {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      gap: 5px;
      width: 80%; }
    [is="menu"] .menu-list .menu-voce .menu-voce__price {
      width: 20%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: end; }
      @media (max-width: 767px) {
        [is="menu"] .menu-list .menu-voce .menu-voce__price {
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: start; } }

[is='menu-navigation'] {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 5;
  max-width: 100vw; }
  [is='menu-navigation'] .menu-navigation {
    padding: 15px 0;
    font-family: "Barlow Regular";
    font-size: 1.4rem;
    -webkit-transition: all .35s ease-out;
    transition: all .35s ease-out; }
    [is='menu-navigation'] .menu-navigation.scroll {
      padding: 10px 0;
      background-color: #F8F8F8;
      -webkit-box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75);
              box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75); }
      @media (min-width: 992px) {
        [is='menu-navigation'] .menu-navigation.scroll {
          padding: 15px 0; } }
    [is='menu-navigation'] .menu-navigation > .container-fluid, [is='menu-navigation'] .menu-navigation > .container-sm, [is='menu-navigation'] .menu-navigation > .container-md, [is='menu-navigation'] .menu-navigation > .container-lg, [is='menu-navigation'] .menu-navigation > .container-xl {
      max-width: 1600px; }
  [is='menu-navigation'] .menu-navigation:not(.scroll) .logo-sticky {
    display: none; }
  [is='menu-navigation'] .menu-navigation.scroll .logo, [is='menu-navigation'] .menu-navigation.scroll .logo--white {
    display: none; }
  [is='menu-navigation'] .menu-navigation.scroll .nav-wpml__lang-active .nav-wpml__dropdown span {
    color: #000000; }
  [is='menu-navigation'] .menu-navigation.scroll .nav-text b, [is='menu-navigation'] .menu-navigation.scroll .nav-text strong {
    color: #333; }
  [is='menu-navigation'] .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  [is='menu-navigation'] .nav-logo img {
    height: 45px;
    width: auto;
    margin: auto; }
    @media (min-width: 576px) {
      [is='menu-navigation'] .nav-logo img {
        height: 50px; } }
    @media (min-width: 768px) {
      [is='menu-navigation'] .nav-logo img {
        height: 60px; } }
  [is='menu-navigation'] .nav-logo .logo--white {
    display: none; }
  [is='menu-navigation'] .nav-logo .logo-sticky {
    height: 40px; }
    @media (min-width: 576px) {
      [is='menu-navigation'] .nav-logo .logo-sticky {
        height: 45px; } }
    @media (min-width: 768px) {
      [is='menu-navigation'] .nav-logo .logo-sticky {
        height: 50px; } }
  [is='menu-navigation'] .nav-hamburger {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    cursor: pointer; }
    [is='menu-navigation'] .nav-hamburger .hamburger-label {
      font-size: 1.4rem;
      margin-left: 1rem; }
    [is='menu-navigation'] .nav-hamburger:hover .hamburger span:nth-child(1), [is='menu-navigation'] .nav-hamburger:hover .hamburger span:nth-child(2), [is='menu-navigation'] .nav-hamburger:hover .hamburger span:nth-child(3) {
      width: 100%; }
    [is='menu-navigation'] .nav-hamburger .hamburger {
      width: 30px;
      height: 19px;
      position: relative;
      z-index: 2;
      -webkit-transform: rotate(360deg) scaleX(-1);
              transform: rotate(360deg) scaleX(-1);
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out; }
      @media (min-width: 576px) {
        [is='menu-navigation'] .nav-hamburger .hamburger {
          width: 35px; } }
      [is='menu-navigation'] .nav-hamburger .hamburger span {
        background-color: #000;
        height: 1px;
        width: 100%;
        position: absolute;
        border-radius: 4px;
        top: calc(50% - 0.5px);
        -webkit-transition: all .35s ease-out;
        transition: all .35s ease-out; }
        [is='menu-navigation'] .nav-hamburger .hamburger span:first-child {
          top: 0;
          width: 35%; }
        [is='menu-navigation'] .nav-hamburger .hamburger span:nth-child(2) {
          width: 70%;
          left: 0;
          top: calc(50% - 0.5px);
          -webkit-transition: all .2s ease-out;
          transition: all .2s ease-out; }
        [is='menu-navigation'] .nav-hamburger .hamburger span:last-child {
          bottom: 0;
          top: unset;
          left: 0;
          width: 50%; }
    [is='menu-navigation'] .nav-hamburger .hamburger[aria-expanded="false"] {
      opacity: 0; }
  [is='menu-navigation'] .nav-wpml span {
    text-transform: uppercase;
    display: block;
    cursor: pointer;
    font-size: 1.4rem; }
  [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active {
    position: relative;
    padding: 1rem 0; }
    [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active > span {
      display: inline-block;
      cursor: pointer; }
    [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active > svg {
      display: inline-block;
      position: relative;
      top: -1px;
      cursor: pointer; }
      [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active > svg g {
        fill: #000; }
    [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active .nav-wpml__dropdown {
      position: absolute;
      left: -8px;
      background-color: #fff;
      padding: 0;
      top: 100%;
      text-align: center;
      width: 55px;
      -webkit-box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.5);
      box-shadow: 0px 0px 15px -2px rgba(0, 0, 0, 0.5); }
      [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active .nav-wpml__dropdown a {
        width: 100%;
        display: block;
        padding: 1rem; }
    [is='menu-navigation'] .nav-wpml .nav-wpml__lang-active:not(.open) .nav-wpml__dropdown {
      display: none; }
  [is='menu-navigation'] .nav-search img {
    height: 20px;
    margin-left: auto;
    cursor: pointer; }
    @media (min-width: 576px) {
      [is='menu-navigation'] .nav-search img {
        height: 25px; } }
  [is='menu-navigation'] .nav-search img.light {
    display: none; }
  [is='menu-navigation'] .nav-text a:not(:first-child) {
    margin-left: 6rem; }
  [is='menu-navigation'] .nav-text b, [is='menu-navigation'] .nav-text strong {
    color: #333; }
  [is='menu-navigation'] .links-menu {
    position: absolute;
    right: 30px;
    top: 37px;
    font-size: 1.4rem;
    line-height: 1.4rem; }
    @media (min-width: 768px) {
      [is='menu-navigation'] .links-menu {
        top: 42px; } }
    @media (min-width: 992px) {
      [is='menu-navigation'] .links-menu {
        right: 60px; } }
    [is='menu-navigation'] .links-menu a:not(:last-child) {
      margin-right: 15px; }
      @media (min-width: 992px) {
        [is='menu-navigation'] .links-menu a:not(:last-child) {
          margin-right: 40px; } }
  [is='menu-navigation'] .content-hamburger {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 9;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 30px 0;
    background-color: #fff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    -webkit-transition: all .5s ease-out;
    transition: all .5s ease-out; }
    [is='menu-navigation'] .content-hamburger::before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-color: #092531b3; }
    @media (min-width: 768px) {
      [is='menu-navigation'] .content-hamburger {
        padding: 0; } }
  [is='menu-navigation']:not(.menu-navigation--menuIsOpen) .content-hamburger .close {
    opacity: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
  [is='menu-navigation']:not(.menu-navigation--menuIsOpen) .content-hamburger__image, [is='menu-navigation']:not(.menu-navigation--menuIsOpen) .content-hamburger__menu {
    opacity: 0;
    -webkit-transform: translateY(-10vw);
    transform: translateY(-10vw); }
  [is='menu-navigation']:not(.menu-navigation--menuIsOpen) .content-hamburger {
    top: -105vh; }
  [is='menu-navigation']:not(.menu-navigation--menuIsOpen) .content-hamburger__menu {
    -webkit-transition: all .25s ease-out;
    transition: all .25s ease-out; }
  [is='menu-navigation'] .content-hamburger__menu {
    max-height: 85vh;
    overflow-y: auto;
    width: 100%;
    padding: 0 30px;
    z-index: 2;
    -webkit-transition: all .25s ease-out .45s;
    transition: all .25s ease-out .45s; }
    @media (min-width: 768px) {
      [is='menu-navigation'] .content-hamburger__menu {
        width: 100%;
        padding: 0 60px; } }
    @media (min-width: 992px) {
      [is='menu-navigation'] .content-hamburger__menu {
        padding: 0 90px; } }
    [is='menu-navigation'] .content-hamburger__menu .hamburger__menu-bottom {
      margin-top: 2rem; }
      @media (max-width: 575px) {
        [is='menu-navigation'] .content-hamburger__menu .hamburger__menu-bottom {
          font-size: 1.4rem;
          line-height: 2.4rem; } }
    [is='menu-navigation'] .content-hamburger__menu ul {
      margin: 0;
      padding: 0;
      list-style: none; }
    [is='menu-navigation'] .content-hamburger__menu li {
      font-size: 20px;
      line-height: 3rem;
      padding-left: 0;
      margin-top: 10px;
      background: none;
      font-family: 'Baskerville Regular';
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -ms-flex-wrap: wrap;
          flex-wrap: wrap; }
      @media (min-width: 768px) {
        [is='menu-navigation'] .content-hamburger__menu li {
          font-size: 32px;
          line-height: 5rem; } }
      @media (min-width: 992px) {
        [is='menu-navigation'] .content-hamburger__menu li {
          min-width: 350px;
          width: 45%; } }
      [is='menu-navigation'] .content-hamburger__menu li .dropdown {
        width: 30px;
        height: 30px;
        display: inline-block;
        cursor: pointer;
        background-image: url(/wp-content/themes/farmerbit/Components/MenuNavigation/Assets/dropdown.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: 16px;
        margin-left: 20px;
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out; }
        @media (max-width: 991px) {
          [is='menu-navigation'] .content-hamburger__menu li .dropdown {
            left: unset;
            top: 7px;
            width: 18px;
            height: 18px;
            margin-left: 30px;
            -webkit-transform: rotate(0deg);
            transform: rotate(0deg); } }
      [is='menu-navigation'] .content-hamburger__menu li:first-child {
        margin-top: 0; }
      [is='menu-navigation'] .content-hamburger__menu li.menu-item--current > a {
        border-bottom: 1px solid #fff; }
      [is='menu-navigation'] .content-hamburger__menu li a {
        color: #fff; }
      [is='menu-navigation'] .content-hamburger__menu li.open .dropdown {
        -webkit-transform: rotate(90deg);
        transform: rotate(90deg); }
        @media (max-width: 991px) {
          [is='menu-navigation'] .content-hamburger__menu li.open .dropdown {
            -webkit-transform: rotate(180deg);
            transform: rotate(180deg); } }
    [is='menu-navigation'] .content-hamburger__menu ul.sub-menu {
      opacity: 1;
      -webkit-transition: all .5s ease-out;
      transition: all .5s ease-out; }
      @media (min-width: 992px) {
        [is='menu-navigation'] .content-hamburger__menu ul.sub-menu {
          position: absolute;
          left: 100%;
          width: 100%;
          top: 10px; } }
      @media (max-width: 991px) {
        [is='menu-navigation'] .content-hamburger__menu ul.sub-menu {
          padding: 10px 0;
          width: 100%; } }
      @media (max-width: 991px) {
        [is='menu-navigation'] .content-hamburger__menu ul.sub-menu {
          display: block; } }
      [is='menu-navigation'] .content-hamburger__menu ul.sub-menu li {
        font-size: 1.6rem;
        line-height: 2rem;
        font-family: "Barlow Regular"; }
        [is='menu-navigation'] .content-hamburger__menu ul.sub-menu li:first-child {
          margin-top: 6px; }
        @media (min-width: 768px) {
          [is='menu-navigation'] .content-hamburger__menu ul.sub-menu li {
            font-size: 1.8rem;
            line-height: 2.5rem; } }
        [is='menu-navigation'] .content-hamburger__menu ul.sub-menu li.menu-item--current {
          font-family: "Barlow Regular"; }
    [is='menu-navigation'] .content-hamburger__menu li:not(.open) ul.sub-menu {
      opacity: 0; }
      @media (max-width: 991px) {
        [is='menu-navigation'] .content-hamburger__menu li:not(.open) ul.sub-menu {
          display: none; } }
  [is='menu-navigation'] .content-hamburger__image {
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center center;
    -webkit-transition: all .25s ease-out .25s;
    transition: all .25s ease-out .25s; }
    @media (max-width: 767px) {
      [is='menu-navigation'] .content-hamburger__image {
        display: none; } }
  [is='menu-navigation'] .close {
    width: 25px;
    height: 25px;
    position: absolute;
    left: 30px;
    top: 30px;
    cursor: pointer;
    -webkit-transition: all .2s ease-out .4s;
    transition: all .2s ease-out .4s; }
    @media (min-width: 768px) {
      [is='menu-navigation'] .close {
        width: 35px;
        height: 35px; } }
    @media (min-width: 992px) {
      [is='menu-navigation'] .close {
        left: 60px; } }
    @media (min-width: 1200px) {
      [is='menu-navigation'] .close {
        width: 40px;
        height: 40px; } }
    @media (min-width: 1600px) {
      [is='menu-navigation'] .close {
        left: calc((100vw - 1600px) / 2 + 30px);
        top: 60px; } }
    [is='menu-navigation'] .close .line {
      position: absolute;
      width: 100%;
      height: 3px;
      background-color: #fff;
      border-radius: 2px;
      top: calc(50% - 1px);
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out; }
    [is='menu-navigation'] .close .line:first-child {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    [is='menu-navigation'] .close .line:last-child {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
  [is='menu-navigation'] .popup-search {
    position: fixed;
    top: -60px;
    left: 0;
    right: 0;
    z-index: 9;
    background-color: #fff;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    opacity: 0;
    visibility: hidden;
    -webkit-transition: top 0.4s ease-out 0.4s, opacity 0.4s ease-out 0.4s, visibility 0.1s ease 0.85s;
    transition: top 0.4s ease-out 0.4s, opacity 0.4s ease-out 0.4s, visibility 0.1s ease 0.85s; }
    [is='menu-navigation'] .popup-search.open {
      opacity: 1;
      top: 0px;
      visibility: visible;
      -webkit-transition: top .4s ease-out, opacity .4s ease-out, visibility .1s ease;
      transition: top .4s ease-out, opacity .4s ease-out, visibility .1s ease; }
    [is='menu-navigation'] .popup-search .popup-search__container {
      width: 800px;
      max-width: 90%;
      margin: auto;
      -ms-flex-item-align: center;
          align-self: center;
      text-align: center;
      opacity: 0;
      -webkit-transition: all .4s ease-out;
      transition: all .4s ease-out; }
    [is='menu-navigation'] .popup-search.open .popup-search__container {
      opacity: 1;
      -webkit-transition: all .4s ease-out .6s;
      transition: all .4s ease-out .6s; }
    [is='menu-navigation'] .popup-search .popup-search__title {
      font-family: "Barlow Bold";
      margin-bottom: 2rem;
      font-size: 2rem; }
      @media (min-width: 576px) {
        [is='menu-navigation'] .popup-search .popup-search__title {
          margin-bottom: 4rem; } }
    [is='menu-navigation'] .popup-search .popup-search__form__form input {
      font-size: 16px;
      line-height: 28px;
      border: 0;
      border-bottom: 1px solid #000;
      background-color: transparent;
      padding: 15px 25px;
      cursor: pointer;
      font-weight: 300;
      width: calc(100% - 30px);
      text-align: center;
      margin-bottom: 2rem; }
      @media (min-width: 576px) {
        [is='menu-navigation'] .popup-search .popup-search__form__form input {
          margin-right: 2rem;
          text-align: left;
          margin-right: 1rem;
          margin-bottom: 0; } }
      [is='menu-navigation'] .popup-search .popup-search__form__form input input:focus {
        border-bottom: 2px solid #000; }
      [is='menu-navigation'] .popup-search .popup-search__form__form input button {
        background-color: transparent;
        border: 0; }
    [is='menu-navigation'] .popup-search .close_menu .line {
      background-color: #000; }
    [is='menu-navigation'] .popup-search button {
      cursor: pointer; }
  [is='menu-navigation'] .menu-item--hasChildren {
    position: relative; }
    [is='menu-navigation'] .menu-item--hasChildren:hover {
      z-index: 2; }
  [is='menu-navigation'].menu-navigation--menuIsOpen .content-hamburger::before {
    content: ""; }
  [is='menu-navigation'] .nav-menuDesktop ul {
    list-style: none;
    margin: 0;
    padding: 0; }
    [is='menu-navigation'] .nav-menuDesktop ul li {
      display: inline-block; }
      [is='menu-navigation'] .nav-menuDesktop ul li.menu-item--current > a {
        color: #000000; }
      [is='menu-navigation'] .nav-menuDesktop ul li a {
        text-decoration: none;
        font-family: "Barlow Medium";
        color: #000000; }
      [is='menu-navigation'] .nav-menuDesktop ul li:not(:first-child) {
        margin-left: 1.5rem; }
        @media (min-width: 1200px) {
          [is='menu-navigation'] .nav-menuDesktop ul li:not(:first-child) {
            margin-left: 2rem; } }
      [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 70px;
        background-color: #fff;
        -webkit-box-shadow: 0px 27px 20px -30px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 27px 20px -30px rgba(0, 0, 0, 0.75);
        margin-top: -15px;
        opacity: 0;
        visibility: hidden;
        padding-top: 40px;
        padding-bottom: 40px;
        -webkit-transition: opacity .2s ease-out .2s, margin-top .2s ease-out .2s, visibility .01s linear .4s;
        transition: opacity .2s ease-out .2s, margin-top .2s ease-out .2s, visibility .01s linear .4s; }
        [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu a {
          font-family: "Barlow Regular"; }
        [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu li {
          width: 100%;
          margin-left: 0; }
          [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu li:not(:first-child) {
            margin-top: 5px; }
        [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu .sub-menu-image {
          position: absolute;
          right: 0;
          top: 0;
          bottom: 0;
          width: 30vw;
          background-position: center;
          background-size: cover; }
        [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu ul {
          padding-right: 30vw; }
      [is='menu-navigation'] .nav-menuDesktop ul li .sub-menu__title {
        font-family: "Barlow Medium";
        margin-bottom: 2rem; }
      [is='menu-navigation'] .nav-menuDesktop ul li.menu-item--hasChildren:hover .sub-menu {
        opacity: 1;
        visibility: visible;
        margin-top: 0;
        -webkit-transition: opacity .2s ease-out .2s, margin-top .2s ease-out .2s, visibility .01s linear .2s;
        transition: opacity .2s ease-out .2s, margin-top .2s ease-out .2s, visibility .01s linear .2s; }
  [is='menu-navigation'] .nav-text__content a {
    font-family: "Barlow Regular"; }
  [is='menu-navigation'] .book-arrow {
    background-image: url("/assets/images/arrow-right.svg"); }

.spaceMenu {
  height: 90px; }
  body.page-backgroundMenu .spaceMenu {
    background-color: #86766C; }

body.menu-fixed .spaceMenu {
  display: none; }

body.menu-white .menu-navigation:not(.scroll) {
  color: #fff; }
  body.menu-white .menu-navigation:not(.scroll) .nav-hamburger .hamburger span {
    background-color: #fff; }
  body.menu-white .menu-navigation:not(.scroll) .nav-wpml svg g {
    fill: #fff; }
  body.menu-white .menu-navigation:not(.scroll) .nav-wpml__dropdown {
    color: #000; }
  body.menu-white .menu-navigation:not(.scroll) .nav-logo .logo--white {
    display: block; }
  body.menu-white .menu-navigation:not(.scroll) .nav-logo .logo {
    display: none; }
  body.menu-white .menu-navigation:not(.scroll) .nav-text b, body.menu-white .menu-navigation:not(.scroll) .nav-text strong {
    color: #fff; }

[is="popup"] .popup {
  position: fixed;
  top: 0;
  height: 100vh;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  opacity: 1;
  background-color: rgba(0, 0, 0, 0.4);
  -webkit-transition: all .4s ease-out, opacity .4s ease-out .4s;
  transition: all .4s ease-out, opacity .4s ease-out .4s; }
  [is="popup"] .popup:not(.open) {
    top: -105vh;
    opacity: 0;
    -webkit-transition: all .4s ease-out .4s, opacity .4s ease-out;
    transition: all .4s ease-out .4s, opacity .4s ease-out; }
  [is="popup"] .popup .content {
    margin: auto;
    padding: 60px 20px;
    width: 900px;
    text-align: center;
    max-width: calc(100% - 40px);
    position: relative;
    background-color: #E2D8C9;
    -webkit-box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75); }
    @media (min-width: 321px) {
      [is="popup"] .popup .content {
        padding: 60px 30px;
        max-width: calc(100% - 60px); } }
    @media (min-width: 992px) {
      [is="popup"] .popup .content {
        padding: 60px; } }
    [is="popup"] .popup .content .close {
      width: 25px;
      height: 25px;
      position: absolute;
      right: 15px;
      top: 15px;
      cursor: pointer;
      -webkit-transition: all .2s ease-out .4s;
      transition: all .2s ease-out .4s; }
      @media (min-width: 768px) {
        [is="popup"] .popup .content .close {
          width: 35px;
          height: 35px; } }
      @media (min-width: 992px) {
        [is="popup"] .popup .content .close {
          right: 20px;
          top: 20px; } }
      @media (min-width: 1200px) {
        [is="popup"] .popup .content .close {
          width: 40px;
          height: 40px; } }
      [is="popup"] .popup .content .close .line {
        position: absolute;
        width: 100%;
        height: 2px;
        background-color: #820000;
        border-radius: 2px;
        top: calc(50% - 1px);
        left: 0;
        -webkit-transition: all .25s ease-out;
        transition: all .25s ease-out; }
      [is="popup"] .popup .content .close .line:first-child {
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg); }
      [is="popup"] .popup .content .close .line:last-child {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg); }
    [is="popup"] .popup .content .title {
      font-family: "Baskerville Regular";
      color: #820000;
      text-align: center; }
    [is="popup"] .popup .content .description {
      margin-top: 20px; }
      @media (min-width: 768px) {
        [is="popup"] .popup .content .description {
          margin-bottom: 30px; } }

[is='product-block'] .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

@media (min-width: 992px) {
  [is='product-block'].boxBottomX .product-block::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 8rem;
    background-color: #F2F2F2;
    z-index: -1; } }

@media (min-width: 992px) and (max-width: 1199px) {
  [is='product-block'].boxBottomX .product-block .text {
    padding-top: 10rem;
    padding-bottom: 10rem; }
  [is='product-block'].boxBottomX .product-block::after {
    height: 6rem; } }

@media (max-width: 991px) {
  [is='product-block'].boxBottomX .product-block {
    margin-bottom: 6rem; } }

[is='product-block'] .product-block--bgColor {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: #D9F9FF; }
  @media (min-width: 768px) {
    [is='product-block'] .product-block--bgColor {
      padding-top: 8rem;
      padding-bottom: 8rem; } }

[is='product-block'] .product-block__body {
  position: relative;
  z-index: 1; }
  [is='product-block'] .product-block__body .product-block__link:not(:last-child) {
    margin-right: 1.5rem; }

[is='product-block'] .product-block__title * {
  padding-top: 0;
  margin-bottom: 0;
  font-family: "Barlow Bold";
  font-weight: 400;
  color: #EBE4E0; }

[is='product-block'] .product-block {
  position: relative; }

[is='product-block'] .product-block--invertCol .row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
      -ms-flex-direction: row-reverse;
          flex-direction: row-reverse; }

@media (min-width: 768px) {
  [is='product-block'] .product-block--invertCol .product-block__body {
    padding-left: 4.5rem; } }

@media (min-width: 768px) {
  [is='product-block'] .product-block:not(.product-block--invertCol) .product-block__body {
    padding-right: 4.5rem; } }

[is='product-block'] .product-block__title + .product-block__description {
  margin-top: 2rem; }
  @media (min-width: 768px) {
    [is='product-block'] .product-block__title + .product-block__description {
      margin-top: 4rem; } }

[is='product-block'] .product-block__description:not(.product-block__description--style-2) * {
  font-family: "Barlow Medium"; }
  @media (min-width: 576px) {
    [is='product-block'] .product-block__description:not(.product-block__description--style-2) * {
      font-size: 2rem;
      line-height: 3rem; } }
  @media (min-width: 992px) {
    [is='product-block'] .product-block__description:not(.product-block__description--style-2) * {
      font-size: 2.5rem;
      line-height: 3.5rem; } }

[is='product-block'] .product-block__description h2 {
  font-size: 2rem;
  line-height: 3rem;
  padding-top: 0;
  margin-bottom: 0;
  font-family: "Barlow Medium"; }
  @media (min-width: 768px) {
    [is='product-block'] .product-block__description h2 {
      font-size: 2.4rem;
      line-height: 3.4rem; } }
  @media (min-width: 992px) {
    [is='product-block'] .product-block__description h2 {
      font-size: 2.8rem;
      line-height: 3.8rem; } }
  [is='product-block'] .product-block__description h2:not(:last-child) {
    margin-bottom: 4rem; }
    @media (min-width: 992px) {
      [is='product-block'] .product-block__description h2:not(:last-child) {
        margin-bottom: 6rem; } }
  [is='product-block'] .product-block__description h2:not(:first-child) {
    margin-top: 4rem; }
    @media (min-width: 992px) {
      [is='product-block'] .product-block__description h2:not(:first-child) {
        margin-bottom: 6rem; } }

[is='product-block'] .product-block__description h3 {
  font-size: 1.8rem;
  line-height: 2.8rem;
  padding-top: 0;
  margin-bottom: 0;
  font-family: "Barlow Medium"; }
  @media (min-width: 768px) {
    [is='product-block'] .product-block__description h3 {
      font-size: 2rem;
      line-height: 3rem; } }
  @media (min-width: 992px) {
    [is='product-block'] .product-block__description h3 {
      font-size: 2.1rem;
      line-height: 3.1rem; } }
  [is='product-block'] .product-block__description h3:not(:last-child) {
    margin-bottom: 2rem; }
  [is='product-block'] .product-block__description h3:not(:first-child) {
    margin-top: 4rem; }

[is='product-block'] .product-block__bg {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }

[is='product-block'] .product-block__figure-mobile {
  margin-bottom: 2rem;
  margin-top: 2.5rem; }
  @media (min-width: 768px) {
    [is='product-block'] .product-block__figure-mobile {
      margin-top: 4.5rem;
      margin-bottom: 4rem; } }

@media (min-width: 992px) {
  [is='product-block'] .left.text {
    padding-right: 6rem; } }

@media (min-width: 992px) {
  [is='product-block'] .right.text {
    padding-left: 6rem; } }

@media (max-width: 767px) {
  [is='product-block'] .right[class^=col-md-] {
    margin-top: 6rem; } }

@media (max-width: 991px) {
  [is='product-block'] .right[class^=col-lg-] {
    margin-top: 6rem; } }

@media (max-width: 991px) {
  [is='product-block'] .right[class^=col-lg-][class^=col-md-] {
    margin-top: 0; } }

@media (max-width: 767px) {
  [is='product-block'] .right[class^=col-lg-][class^=col-md-] {
    margin-top: 6rem; } }

@media (max-width: 991px) {
  [is='product-block'] .same-height .image {
    display: none; } }

[is='product-block'] .product-block__image {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative; }
  @media (max-width: 767px) {
    [is='product-block'] .product-block__image.col-md-6 {
      margin-top: 4rem; } }
  [is='product-block'] .product-block__image .product-block__image-product {
    margin: 60px auto auto;
    z-index: 1; }
    @media (min-width: 768px) {
      [is='product-block'] .product-block__image .product-block__image-product {
        margin: 100px auto auto; } }
  [is='product-block'] .product-block__image .product-block__image_ani {
    position: absolute;
    left: 15px;
    right: 15px;
    top: 20px;
    z-index: 0;
    -webkit-animation: float 6s ease-in-out infinite;
            animation: float 6s ease-in-out infinite;
    -webkit-transition: all .4s ease-out;
    transition: all .4s ease-out; }
    [is='product-block'] .product-block__image .product-block__image_ani:not(.onView) {
      opacity: 0; }
    [is='product-block'] .product-block__image .product-block__image_ani.product-block__image_ani--2 {
      -webkit-transition-delay: .2s;
              transition-delay: .2s;
      -webkit-animation: float 6s ease-in-out infinite .8s;
              animation: float 6s ease-in-out infinite .8s; }
    [is='product-block'] .product-block__image .product-block__image_ani.product-block__image_ani--3 {
      -webkit-transition-delay: .4s;
              transition-delay: .4s;
      -webkit-animation: float 6s ease-in-out infinite 1.2s;
              animation: float 6s ease-in-out infinite 1.2s; }

[is='product-block'] .product-block__onde.product-block__onde-bottom {
  width: 100%;
  height: 9vw;
  background-size: cover;
  background-position: top center;
  position: absolute;
  bottom: 0; }

@-webkit-keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); }
  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px); }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); } }

@keyframes float {
  0% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); }
  50% {
    -webkit-transform: translatey(-20px);
            transform: translatey(-20px); }
  100% {
    -webkit-transform: translatey(0px);
            transform: translatey(0px); } }

[is='section-tris'] {
  position: relative; }
  [is='section-tris'] .font-text {
    font-family: "Barlow" !important; }
  [is='section-tris'] .section-tris .section-tris__container {
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1800px; }
    @media (min-width: 992px) {
      [is='section-tris'] .section-tris .section-tris__container {
        padding-left: 0;
        padding-right: 0; } }
  [is='section-tris'] .section-tris .section-tris__cols {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row; }
    @media (max-width: 991px) {
      [is='section-tris'] .section-tris .section-tris__cols {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
    [is='section-tris'] .section-tris .section-tris__cols .section-tris__col {
      width: 33.33%;
      height: 40vw;
      overflow: hidden;
      position: relative;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center; }
      [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .link-section {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 2; }
      [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__bg {
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        -webkit-transition: all .2s;
        transition: all .2s;
        position: absolute; }
        [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__bg::before {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.337);
          z-index: 1; }
      @media (max-width: 991px) {
        [is='section-tris'] .section-tris .section-tris__cols .section-tris__col {
          min-height: 65vw;
          width: 100%;
          background-size: cover; } }
      [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__text {
        color: #fff;
        text-align: center;
        width: 70%; }
        @media (max-width: 991px) {
          [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__text {
            width: 80%; } }
      [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__col-content {
        position: relative;
        z-index: 2;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        gap: 25px;
        -webkit-transition: all .2s ease-out;
        transition: all .2s ease-out; }
        @media (max-width: 991px) {
          [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__col-content {
            padding: 20px 0; } }
        [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__col-content .section-tris__title {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; }
          [is='section-tris'] .section-tris .section-tris__cols .section-tris__col .section-tris__col-content .section-tris__title h2 {
            color: #fff;
            font-family: "Baskerville Regular";
            -webkit-transition: all .2s ease-out;
            transition: all .2s ease-out; }
  [is='section-tris'] .section-tris:not(.una-colonna) .section-tris__cols .section-tris__col:hover .section-tris__bg {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }
  [is='section-tris'] .section-tris:not(.una-colonna) .section-tris__cols .section-tris__col:hover .section-tris__col-content a {
    left: 0;
    border-bottom: 2px solid #fff;
    border-top: 2px solid #fff;
    border-right: 2px solid #fff; }
    [is='section-tris'] .section-tris:not(.una-colonna) .section-tris__cols .section-tris__col:hover .section-tris__col-content a span {
      left: 0;
      color: #fff; }
    [is='section-tris'] .section-tris:not(.una-colonna) .section-tris__cols .section-tris__col:hover .section-tris__col-content a::before {
      height: 50%;
      background-color: #fff; }
    [is='section-tris'] .section-tris:not(.una-colonna) .section-tris__cols .section-tris__col:hover .section-tris__col-content a::after {
      height: 50%;
      background-color: #fff; }
  [is='section-tris'] .section-tris.una-colonna .section-tris__cols .section-tris__col .link-section {
    display: none; }
  [is='section-tris'] .section-tris.una-colonna .section-tris__cols .section-tris__col .section-tris__col-content a:hover {
    opacity: 1; }
    [is='section-tris'] .section-tris.una-colonna .section-tris__cols .section-tris__col .section-tris__col-content a:hover span {
      color: #fff; }
  [is='section-tris'] .section-tris.una-colonna .section-tris__cols .section-tris__col:hover .section-tris__bg {
    -webkit-transform: scale(1);
    transform: scale(1); }

[is='side-by-side'] {
  /*--------------------------------------------
	Helpers
	--------------------------------------------*/ }
  [is='side-by-side'] .left .side-by-side__figure-mobile {
    position: relative; }
    [is='side-by-side'] .left .side-by-side__figure-mobile .figure-shape-mobile {
      background-color: transparent;
      border: 1px solid #d2c8c2;
      position: absolute;
      height: 100%;
      width: 100%;
      right: -10px;
      bottom: -10px; }
  [is='side-by-side'] .right .side-by-side__figure-mobile {
    position: relative; }
    [is='side-by-side'] .right .side-by-side__figure-mobile .figure-shape-mobile {
      background-color: transparent;
      border: 1px solid #d2c8c2;
      position: absolute;
      height: 100%;
      width: 100%;
      left: -10px;
      bottom: -10px; }
  @media (max-width: 991px) {
    [is='side-by-side']#ps-info .side-by-side {
      padding: 50px 0; } }
  [is='side-by-side']#ps-info .figure-shape {
    display: none; }
  @media (max-width: 991px) {
    [is='side-by-side']#ps-info .row .image {
      margin: 4rem auto 0;
      display: block; } }
  [is='side-by-side'] .tab-info .tab-title__main {
    padding-bottom: 50px; }
    @media (max-width: 767px) {
      [is='side-by-side'] .tab-info .tab-title__main {
        padding-bottom: 30px; } }
  [is='side-by-side'] .tab-info .tablist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    gap: 30px; }
    [is='side-by-side'] .tab-info .tablist .tablist-item {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      width: 45%; }
      @media (max-width: 767px) {
        [is='side-by-side'] .tab-info .tablist .tablist-item {
          width: 100%;
          padding-bottom: 5px;
          border-bottom: 1px solid #43545b8a; } }
      [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__img {
        width: 19%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; }
        @media (max-width: 767px) {
          [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__img {
            width: 10%;
            -webkit-box-align: start;
                -ms-flex-align: start;
                    align-items: flex-start; } }
        [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__img img {
          height: 30px;
          width: 30px; }
          @media (max-width: 767px) {
            [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__img img {
              height: 25px;
              width: 25px; } }
      [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__text {
        width: 80%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; }
        @media (max-width: 767px) {
          [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__text {
            width: 90%; } }
        [is='side-by-side'] .tab-info .tablist .tablist-item .tablist-item__text .barlow-bold {
          font-weight: 800; }
  [is='side-by-side'] .room-price {
    font-weight: 800;
    margin-left: 40px; }
    @media (max-width: 767px) {
      [is='side-by-side'] .room-price {
        margin-left: 20px; } }
  [is='side-by-side'] .room-services {
    margin-top: 3rem;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 30px; }
    @media (max-width: 470px) {
      [is='side-by-side'] .room-services {
        margin-top: 2rem; } }
    [is='side-by-side'] .room-services .room-service {
      width: 45%;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -ms-flex-wrap: nowrap;
          flex-wrap: nowrap;
      gap: 20px; }
      @media (max-width: 470px) {
        [is='side-by-side'] .room-services .room-service {
          width: 100%; } }
  [is='side-by-side'] .left.image .side-by-side__figure .figure-shape {
    background-color: transparent;
    border: 2px solid #d2c8c2;
    position: absolute;
    height: 100%;
    width: 100%;
    left: -40px;
    bottom: -40px; }
    @media (max-width: 991px) {
      [is='side-by-side'] .left.image .side-by-side__figure .figure-shape {
        left: -20px;
        bottom: -20px; } }
  [is='side-by-side'] .right.image .side-by-side__figure .figure-shape {
    background-color: transparent;
    border: 2px solid #d2c8c2;
    position: absolute;
    height: 100%;
    width: 100%;
    right: -40px;
    bottom: -40px; }
  @media (max-width: 991px) {
    [is='side-by-side'] .side-by-side--default .right {
      margin-top: 6rem; } }
  @media (max-width: 767px) {
    [is='side-by-side'] .side-by-side--default .right {
      margin-top: 4rem; } }
  [is='side-by-side'] .side-by-side--default .right.text .side-by-side__link {
    background-color: #EBE4E0;
    color: #fff;
    border-radius: 0;
    text-align: center;
    padding: 10px 15px;
    width: 100%;
    max-width: 300px; }
    @media (max-width: 575px) {
      [is='side-by-side'] .side-by-side--default .right.text .side-by-side__link {
        max-width: 100%; } }
    [is='side-by-side'] .side-by-side--default .right.text .side-by-side__link::before {
      content: unset; }
  @media (min-width: 992px) {
    [is='side-by-side'] .side-by-side--timeline .text {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 12rem;
      padding-bottom: 12rem; }
    [is='side-by-side'] .side-by-side--timeline .left.image {
      padding-right: 3rem; }
    [is='side-by-side'] .side-by-side--timeline .right.image {
      padding-left: 3rem; } }
  [is='side-by-side'] .side-by-side--timeline .side-by-side__title * {
    font-family: "Baskerville Regular"; }
  [is='side-by-side'] .side-by-side--timeline .side-by-side__timeline {
    font-size: 25rem;
    position: absolute;
    top: 5rem;
    color: #C9C0B0;
    font-family: "Barlow Regular"; }
    [is='side-by-side'] .side-by-side--timeline .side-by-side__timeline.aos-animate {
      opacity: 0.4; }
  [is='side-by-side'] .side-by-side--timeline .right.text .side-by-side__timeline {
    left: -6rem; }
  [is='side-by-side'] .side-by-side--timeline .left.text .side-by-side__timeline {
    right: -6rem; }
  [is='side-by-side'] .side-by-side--timeline .side-by-side__body {
    position: relative; }
  [is='side-by-side'] .side-by-side--timeline .image {
    position: relative;
    z-index: 1; }
  @media (min-width: 992px) {
    [is='side-by-side'] .side-by-side--bigImage-text .text {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 12rem;
      padding-bottom: 12rem; }
    [is='side-by-side'] .side-by-side--bigImage-text .left.image {
      padding-right: 3rem; }
    [is='side-by-side'] .side-by-side--bigImage-text .right.image {
      padding-left: 3rem; } }
  [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title * {
    font-family: "Baskerville Regular"; }
    @media (max-width: 575px) {
      [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title * {
        font-size: 2.5rem !important;
        line-height: 3.5rem !important; } }
  @media (min-width: 992px) {
    [is='side-by-side'] .side-by-side--room .left.image {
      padding-right: 3rem; }
    [is='side-by-side'] .side-by-side--room .right.image {
      padding-left: 3rem; }
    [is='side-by-side'] .side-by-side--room .text {
      padding-left: 6rem;
      padding-right: 6rem;
      padding-top: 12rem;
      padding-bottom: 12rem; } }
  [is='side-by-side'] .side-by-side--room .side-by-side__title * {
    font-family: "Baskerville Regular"; }
    @media (max-width: 575px) {
      [is='side-by-side'] .side-by-side--room .side-by-side__title * {
        font-size: 2.5rem !important;
        line-height: 3.5rem !important; } }
  [is='side-by-side'] .side-by-side__price {
    margin-top: 2rem;
    font-family: "Barlow Regular";
    color: #000; }
  [is='side-by-side'] .side-by-side__price + .side-by-side__description {
    margin-top: 2rem; }
  [is='side-by-side'] .side-by-side--textWhite .side-by-side__title *, [is='side-by-side'] .side-by-side--textWhite .side-by-side__description *, [is='side-by-side'] .side-by-side--textWhite .side-by-side__link {
    color: #fff; }
  [is='side-by-side'] .side-by-side--textWhite .side-by-side__link::before {
    border-color: #fff; }
  @media (max-width: 991px) {
    [is='side-by-side'] .row .image {
      display: none; } }
  [is='side-by-side'] .side-by-side__title * {
    color: #000000;
    font-family: "Barlow Regular"; }
  [is='side-by-side'] #map {
    left: 0;
    height: 300px; }
    @media (min-width: 992px) {
      [is='side-by-side'] #map {
        height: 350px; } }
    @media (min-width: 1200px) {
      [is='side-by-side'] #map {
        height: 450px; } }
  [is='side-by-side'] .right.maps {
    margin-top: 4rem; }
    @media (min-width: 768px) {
      [is='side-by-side'] .right.maps {
        margin-top: 6rem; } }
    @media (min-width: 992px) {
      [is='side-by-side'] .right.maps {
        margin-top: 0; } }
  @media (min-width: 992px) {
    [is='side-by-side'].boxBottomX .side-by-side::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 8rem;
      background-color: #F2F2F2;
      z-index: -1; }
    [is='side-by-side'].boxBottomX .side-by-side .text {
      padding-bottom: 10rem;
      margin-bottom: 8rem; } }
  @media (max-width: 991px) {
    [is='side-by-side'].boxBottomX .side-by-side {
      margin-bottom: 8rem; } }
  [is='side-by-side'] .side-by-side {
    position: relative; }
  @media (max-width: 991px) {
    [is='side-by-side'] .side-by-side__figure-mobile + .side-by-side__description {
      margin-top: 4rem; } }
  @media (max-width: 575px) {
    [is='side-by-side'] .side-by-side__figure-mobile + .side-by-side__description {
      margin-top: 2rem; } }
  [is='side-by-side'] .side-by-side__description {
    margin-top: 2rem; }
    [is='side-by-side'] .side-by-side__description h1:first-child, [is='side-by-side'] .side-by-side__description h2:first-child, [is='side-by-side'] .side-by-side__description h3:first-child, [is='side-by-side'] .side-by-side__description h4:first-child, [is='side-by-side'] .side-by-side__description h5:first-child, [is='side-by-side'] .side-by-side__description h6:first-child {
      margin-bottom: 4rem; }
  [is='side-by-side'] .side-by-side__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0; }
  [is='side-by-side'] .side-by-side__figure {
    position: relative;
    margin-bottom: 0;
    max-width: unset;
    padding-top: 106%;
    width: unset;
    height: 100%; }
  [is='side-by-side'] .side-by-side__link-cover {
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2; }
  [is='side-by-side'] .side-by-side__link {
    margin-top: 4rem; }
  [is='side-by-side'] .side-by-side__image {
    position: absolute;
    top: 0;
    left: 0;
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%; }
    [is='side-by-side'] .side-by-side__image.of-contain {
      margin: auto; }
  [is='side-by-side'] .side-by-side__figure-mobile {
    margin-top: 2rem;
    position: relative;
    margin-bottom: 0;
    max-width: unset;
    padding-top: 70%;
    width: unset;
    height: 100%; }
    [is='side-by-side'] .side-by-side__figure-mobile img {
      position: absolute;
      top: 0;
      left: 0;
      -o-object-fit: cover;
      object-fit: cover;
      width: 100%;
      height: 100%; }
    @media (min-width: 576px) {
      [is='side-by-side'] .side-by-side__figure-mobile {
        margin-top: 4rem; } }
  @media (min-width: 992px) {
    [is='side-by-side'] .left.text {
      padding-right: 6rem; } }
  @media (min-width: 992px) {
    [is='side-by-side'] .right.text {
      padding-left: 6rem; } }
  @media (max-width: 991px) {
    [is='side-by-side'] .right[class^=col-lg-][class^=col-md-] {
      margin-top: 0; } }
  @media (max-width: 991px) {
    [is='side-by-side'] .same-height .image {
      display: none; } }
  [is='side-by-side'] .of-cover {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
       object-fit: cover; }
  [is='side-by-side'] .strech-lg-right .of-cover {
    height: 300px; }
    @media (min-width: 992px) {
      [is='side-by-side'] .strech-lg-right .of-cover {
        height: 350px; } }
    @media (min-width: 1200px) {
      [is='side-by-side'] .strech-lg-right .of-cover {
        height: 450px; } }
  [is='side-by-side'] .of-contain {
    -o-object-fit: contain;
       object-fit: contain; }
  @media (min-width: 992px) {
    [is='side-by-side'] {
      /* Helpers */ }
      [is='side-by-side'] .abs-lg-valign {
        position: absolute;
        left: 0;
        right: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%); }
      [is='side-by-side'] .strech-lg-left,
      [is='side-by-side'] .strech-lg-right {
        position: relative;
        width: 47%; }
      [is='side-by-side'] .strech-lg-left {
        right: 50%;
        left: 0; }
      [is='side-by-side'] .strech-lg-right {
        left: 50%;
        right: 0; }
      [is='side-by-side'] .left-lg-auto {
        left: auto; }
      [is='side-by-side'] .side-by-side__figure-mobile {
        display: none; } }
  @media (max-width: 991px) {
    [is='side-by-side'] .strech-lg-right, [is='side-by-side'] .strech-lg-left {
      display: none; } }
  [is='side-by-side'] a[data-fancybox] {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 60px;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%); }
    @media (min-width: 576px) {
      [is='side-by-side'] a[data-fancybox] {
        width: 80px; } }
    @media (min-width: 992px) {
      [is='side-by-side'] a[data-fancybox] {
        width: 90px; } }
    @media (min-width: 1200px) {
      [is='side-by-side'] a[data-fancybox] {
        width: 100px; } }
  [is='side-by-side'] .side-by-side--banner {
    padding-top: 6rem;
    padding-bottom: 6rem; }
    @media (min-width: 992px) {
      [is='side-by-side'] .side-by-side--banner {
        padding-top: 0;
        padding-bottom: 0; } }
    [is='side-by-side'] .side-by-side--banner .side-by-side__title * {
      font-family: "Baskerville Regular"; }
      @media (max-width: 575px) {
        [is='side-by-side'] .side-by-side--banner .side-by-side__title * {
          font-size: 2.5rem !important;
          line-height: 3.5rem !important; } }
    [is='side-by-side'] .side-by-side--banner .side-by-side__title *, [is='side-by-side'] .side-by-side--banner .side-by-side__description *, [is='side-by-side'] .side-by-side--banner .side-by-side__link, [is='side-by-side'] .side-by-side--banner .side-by-side__link * {
      color: #fff; }
    [is='side-by-side'] .side-by-side--banner .side-by-side__link::before {
      border-color: #fff; }
    [is='side-by-side'] .side-by-side--banner .side-by-side__figure-mobile {
      position: absolute;
      bottom: -6rem;
      right: 4rem;
      height: 110%; }
      [is='side-by-side'] .side-by-side--banner .side-by-side__figure-mobile img {
        max-height: 100%;
        opacity: 0.2; }
    [is='side-by-side'] .side-by-side--banner .side-by-side__description {
      position: relative; }

[is="table"] .tablist {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  gap: 30px; }
  [is="table"] .tablist .tablist-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    width: 48%; }
    @media (max-width: 767px) {
      [is="table"] .tablist .tablist-item {
        width: 100%; } }
    [is="table"] .tablist .tablist-item .tablist-item__img {
      width: 60px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 767px) {
        [is="table"] .tablist .tablist-item .tablist-item__img {
          -webkit-box-align: start;
              -ms-flex-align: start;
                  align-items: flex-start; } }
      [is="table"] .tablist .tablist-item .tablist-item__img img {
        height: 32px;
        width: 32px; }
        @media (max-width: 767px) {
          [is="table"] .tablist .tablist-item .tablist-item__img img {
            height: 30px;
            width: 30px; } }
    [is="table"] .tablist .tablist-item .tablist-item__text {
      width: calc(100% - 60px);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }

[is="table"] .header {
  margin-bottom: 4rem; }
  [is="table"] .header .link .button, [is="table"] .header .link .ctaFixed .ctaFixed__button, .ctaFixed [is="table"] .header .link .ctaFixed__button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 6px 0 6px 6px; }
  [is="table"] .header .link:hover a {
    opacity: 1; }
  [is="table"] .header .link:hover img {
    -webkit-filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(125deg) brightness(110%) contrast(107%);
            filter: invert(100%) sepia(0%) saturate(7500%) hue-rotate(125deg) brightness(110%) contrast(107%); }
  [is="table"] .header .link:hover figure::before {
    background-color: #EBE4E0; }
  [is="table"] .header .link figure {
    position: relative;
    padding: 8px;
    display: inline-block;
    margin-right: 2rem;
    margin-bottom: 0; }
    [is="table"] .header .link figure::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border: 2px solid #EBE4E0;
      -webkit-transition: all .2s ease-out;
      transition: all .2s ease-out;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
  [is="table"] .header .link img {
    width: 16px;
    height: 16px;
    display: block;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }

[is="table"] .list-sections {
  position: relative; }
  [is="table"] .list-sections ul {
    list-style: none;
    border-bottom: 1px solid #979797;
    padding-bottom: 5px;
    margin-bottom: 4rem; }
    @media (max-width: 767px) {
      [is="table"] .list-sections ul {
        overflow: auto;
        white-space: nowrap; } }
    [is="table"] .list-sections ul li {
      font-family: "Barlow Regular";
      position: relative;
      display: inline-block;
      cursor: pointer;
      padding-left: 0;
      background-image: none; }
      [is="table"] .list-sections ul li:not(:last-child) {
        margin-right: 3rem; }
        @media (min-width: 768px) {
          [is="table"] .list-sections ul li:not(:last-child) {
            margin-right: 6rem; } }
        @media (min-width: 992px) {
          [is="table"] .list-sections ul li:not(:last-child) {
            margin-right: 10rem; } }
      [is="table"] .list-sections ul li.active {
        color: #9DBAC0; }
  [is="table"] .list-sections .baetta {
    position: absolute;
    left: 0;
    bottom: -8px;
    width: 18px;
    height: 18px;
    background-color: #9DBAC0;
    border-radius: 50%;
    -webkit-transition: all .8s ease-out;
    transition: all .8s ease-out; }
    @media (max-width: 767px) {
      [is="table"] .list-sections .baetta {
        display: none; } }
    @media (max-width: 575px) {
      [is="table"] .list-sections .baetta {
        bottom: -5px;
        width: 10px;
        height: 10px; } }

[is="table"] .content-tabs {
  padding-top: 15px; }
  [is="table"] .content-tabs .tab {
    margin-top: -2rem; }
  [is="table"] .content-tabs .tab__description, [is="table"] .content-tabs .tab__2col, [is="table"] .content-tabs .tab__table {
    margin-top: 2rem; }
  [is="table"] .content-tabs .tab:not(.active) {
    display: none; }
  [is="table"] .content-tabs .tab__table {
    width: 100%;
    max-width: 800px; }
  [is="table"] .content-tabs .table__header ul, [is="table"] .content-tabs .table__body ul {
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: distribute;
        justify-content: space-around; }
    [is="table"] .content-tabs .table__header ul li, [is="table"] .content-tabs .table__body ul li {
      padding: 0.6rem 1rem;
      width: 100%;
      font-size: 1.5rem;
      line-height: 2.5rem;
      text-align: center; }
      @media (min-width: 576px) {
        [is="table"] .content-tabs .table__header ul li, [is="table"] .content-tabs .table__body ul li {
          font-size: 1.6rem;
          line-height: 2.6rem; } }
      [is="table"] .content-tabs .table__header ul li:first-child, [is="table"] .content-tabs .table__body ul li:first-child {
        width: 140%;
        text-align: left; }
  [is="table"] .content-tabs .table__header ul {
    background-color: #EBE4E0;
    color: #fff; }
  [is="table"] .content-tabs .table__body li {
    border-bottom: 1px solid #979797; }
    [is="table"] .content-tabs .table__body li a {
      text-decoration: none; }

div.scrollbar-track-x {
  height: 4px; }

div.scrollbar-thumb {
  height: 4px; }

ul[data-scrollbar] {
  position: relative; }

div.scroll-content {
  padding-bottom: 20px; }
  div.scroll-content li:not(:first-child) {
    margin-top: 0; }

[is="widget-booking"] #bukly-booking-form--panel .row .col-12:not(:last-child) {
  margin-bottom: 2rem; }

[is="widget-booking"] .button--color {
  background-color: #DA3B3B;
  color: #fff;
  border-radius: 0;
  padding: 12px 20px;
  width: 100%;
  letter-spacing: 3px;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  [is="widget-booking"] .button--color::before {
    content: none; }
  [is="widget-booking"] .button--color:hover {
    background-color: #e25d5d;
    color: #fff; }

[is="widget-booking"] input.input {
  width: 100%; }

.flatpickr-calendar select, .flatpickr-calendar input {
  min-height: unset; }

@media print {
  *,
  *::before,
  *::after {
    background: transparent !important;
    -webkit-box-shadow: none !important;
            box-shadow: none !important;
    color: #000 !important;
    /* Black prints faster */
    text-shadow: none !important; }
  .mainHeader,
  .mainFooter,
  .wpFooter {
    display: none !important; }
  a,
  a:visited {
    text-decoration: underline; }
  [href]::after {
    content: "(" attr(href) ")";
    display: inline; }
  /* prevent link overflowing parent elements */
  .button[href], .ctaFixed .ctaFixed__button[href] {
    max-width: 100%; }
  abbr[title]::after {
    /* stylelint-disable-line selector-no-qualifying-type */
    content: "(" attr(title) ")"; }
  /**
  * Don't show links that are fragment identifiers,
  * or use the `javascript:` pseudo protocol
  */
  [href^='#']::after,
  [href^='javascript:']::after {
    content: ''; }
  pre {
    white-space: pre-wrap !important; }
  pre,
  blockquote {
    border: 1px solid #999;
    page-break-inside: avoid; }
  /**
  * Printing Tables:
  * https://web.archive.org/web/20180815150934/http://css-discuss.incutio.com/wiki/Printing_Tables
  */
  thead {
    display: table-header-group; }
  tr,
  img {
    page-break-inside: avoid; }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3; }
  h2,
  h3 {
    page-break-after: avoid; } }

.container,
.container-fluid,
.container-sm,
.container-md,
.container-lg,
.container-xl, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 30px;
  padding-left: 30px;
  margin-right: auto;
  margin-left: auto; }

html {
  font-family: "Barlow Regular"; }

body {
  background-color: #F8F8F8;
  -webkit-transition: opacity .6s ease-out;
  transition: opacity .6s ease-out; }

@media (min-width: 1600px) {
  div.container-fluid:not(.container-lg), div.container-sm:not(.container-lg), div.container-md:not(.container-lg), div.container-lg:not(.container-lg), div.container-xl:not(.container-lg) {
    max-width: 1600px; } }

p:last-child {
  margin-bottom: 0; }

html:not(.home) .spaceMenu {
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    html:not(.home) .spaceMenu {
      margin-bottom: 8rem; } }

b, strong {
  font-family: "Barlow Bold"; }

p b, p strong {
  font-family: "Barlow Medium"; }

:focus {
  outline: none; }

.title1, [is='hero-slider'] .slide-title * {
  font-size: 3rem;
  line-height: 4rem; }
  @media (min-width: 576px) {
    .title1, [is='hero-slider'] .slide-title * {
      font-size: 3.5rem;
      line-height: 4.5rem; } }
  @media (min-width: 768px) {
    .title1, [is='hero-slider'] .slide-title * {
      font-size: 4rem;
      line-height: 5rem; } }
  @media (min-width: 992px) {
    .title1, [is='hero-slider'] .slide-title * {
      font-size: 5rem;
      line-height: 6rem; } }
  @media (min-width: 1200px) {
    .title1, [is='hero-slider'] .slide-title * {
      font-size: 6rem;
      line-height: 7rem; } }

.title2, [is='side-by-side'] .side-by-side--timeline .side-by-side__title *, [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title *, [is='side-by-side'] .side-by-side--room .side-by-side__title *, [is='side-by-side'] .side-by-side__description h1, [is='side-by-side'] .side-by-side__description h2, [is='side-by-side'] .side-by-side__description h3, [is='side-by-side'] .side-by-side__description h4, [is='side-by-side'] .side-by-side__description h5, [is='side-by-side'] .side-by-side__description h6, [is='side-by-side'] .side-by-side--banner .side-by-side__title *, p h2, .ctaFixed .left {
  font-size: 2rem;
  line-height: 3rem;
  padding-top: 0;
  margin-bottom: 0; }
  @media (min-width: 576px) {
    .title2, [is='side-by-side'] .side-by-side--timeline .side-by-side__title *, [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title *, [is='side-by-side'] .side-by-side--room .side-by-side__title *, [is='side-by-side'] .side-by-side__description h1, [is='side-by-side'] .side-by-side__description h2, [is='side-by-side'] .side-by-side__description h3, [is='side-by-side'] .side-by-side__description h4, [is='side-by-side'] .side-by-side__description h5, [is='side-by-side'] .side-by-side__description h6, [is='side-by-side'] .side-by-side--banner .side-by-side__title *, p h2, .ctaFixed .left {
      font-size: 2.5rem;
      line-height: 3.5rem; } }
  @media (min-width: 768px) {
    .title2, [is='side-by-side'] .side-by-side--timeline .side-by-side__title *, [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title *, [is='side-by-side'] .side-by-side--room .side-by-side__title *, [is='side-by-side'] .side-by-side__description h1, [is='side-by-side'] .side-by-side__description h2, [is='side-by-side'] .side-by-side__description h3, [is='side-by-side'] .side-by-side__description h4, [is='side-by-side'] .side-by-side__description h5, [is='side-by-side'] .side-by-side__description h6, [is='side-by-side'] .side-by-side--banner .side-by-side__title *, p h2, .ctaFixed .left {
      font-size: 3.5rem;
      line-height: 4.5rem; } }
  @media (min-width: 992px) {
    .title2, [is='side-by-side'] .side-by-side--timeline .side-by-side__title *, [is='side-by-side'] .side-by-side--bigImage-text .side-by-side__title *, [is='side-by-side'] .side-by-side--room .side-by-side__title *, [is='side-by-side'] .side-by-side__description h1, [is='side-by-side'] .side-by-side__description h2, [is='side-by-side'] .side-by-side__description h3, [is='side-by-side'] .side-by-side__description h4, [is='side-by-side'] .side-by-side__description h5, [is='side-by-side'] .side-by-side__description h6, [is='side-by-side'] .side-by-side--banner .side-by-side__title *, p h2, .ctaFixed .left {
      font-size: 4.5rem;
      line-height: 5.5rem; } }

.title3, [is='single-block'] .single-block__description h2, html.single-post [is='single-block'] .single-block__description h3 {
  font-size: 2rem;
  line-height: 3rem; }
  @media (min-width: 576px) {
    .title3, [is='single-block'] .single-block__description h2, html.single-post [is='single-block'] .single-block__description h3 {
      font-size: 2.5rem;
      line-height: 3.5rem; } }
  @media (min-width: 768px) {
    .title3, [is='single-block'] .single-block__description h2, html.single-post [is='single-block'] .single-block__description h3 {
      font-size: 3rem;
      line-height: 4rem; } }
  @media (min-width: 992px) {
    .title3, [is='single-block'] .single-block__description h2, html.single-post [is='single-block'] .single-block__description h3 {
      font-size: 3.5rem;
      line-height: 4.5rem; } }

.title4, [is='side-by-side'] .side-by-side__title * {
  font-size: 1.8rem;
  line-height: 2.8rem; }
  @media (min-width: 576px) {
    .title4, [is='side-by-side'] .side-by-side__title * {
      font-size: 2rem;
      line-height: 3rem; } }
  @media (min-width: 768px) {
    .title4, [is='side-by-side'] .side-by-side__title * {
      font-size: 2.5rem;
      line-height: 3.5rem; } }
  @media (min-width: 992px) {
    .title4, [is='side-by-side'] .side-by-side__title * {
      font-size: 3rem;
      line-height: 4rem; } }

p h3 {
  font-size: 1.6rem;
  line-height: 2.6rem;
  padding-top: 0;
  margin-bottom: 0;
  font-family: "Barlow Medium"; }
  @media (min-width: 768px) {
    p h3 {
      font-size: 1.8rem;
      line-height: 2.8rem; } }
  @media (min-width: 992px) {
    p h3 {
      font-size: 2rem;
      line-height: 3rem; } }

/*
// ANCHOR: background page
*/
.backgroundPage {
  background-color: #86766C;
  position: absolute;
  top: 0;
  height: 100vh;
  left: 0;
  width: 100vw; }
  .backgroundPage .effectBottom {
    width: 100%;
    height: 9vw;
    background-size: cover;
    position: absolute;
    bottom: -4.5vw;
    background-position: center; }

/*
// ANCHOR: list ul li
*/
ul {
  margin: 0;
  padding: 0;
  list-style: none; }
  ul li {
    padding-left: 3rem;
    background-image: url(images/icon-list.svg);
    background-repeat: no-repeat;
    background-position: 0px 8px;
    background-size: 18px; }
    ul li:not(:first-child) {
      margin-top: 1rem; }

/*
// ANCHOR: Button
*/
.button, .ctaFixed .ctaFixed__button {
  font-family: "Barlow Bold";
  font-size: 1.4rem;
  text-transform: uppercase;
  margin-top: 2rem;
  display: inline-block;
  position: relative;
  line-height: unset;
  padding: 0;
  background: none;
  border: 0;
  color: #000000;
  padding-bottom: 5px; }
  .button::before, .ctaFixed .ctaFixed__button::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 55%;
    border-bottom: 2px solid #DA3B3B;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
  .button:hover, .ctaFixed .ctaFixed__button:hover {
    background: none;
    color: #000000; }
    .button:hover::before, .ctaFixed .ctaFixed__button:hover::before {
      width: 100%; }

/*
// ANCHOR: Section
*/
.section {
  margin-bottom: 6rem; }
  @media (min-width: 768px) {
    .section {
      margin-bottom: 8rem; } }
  @media (min-width: 992px) {
    .section {
      margin-bottom: 10rem; } }

/*
// ANCHOR: product
*/
.single-product .backgroundProduct {
  background-color: #86766C;
  position: absolute;
  top: 0;
  height: 150vh;
  left: 0;
  width: 100vw; }
  .single-product .backgroundProduct .effectBottom {
    width: 100%;
    height: 9vw;
    background-size: cover;
    position: absolute;
    bottom: -4.5vw;
    background-position: center; }

.single-product .tableProduct table th {
  font-family: "Barlow Medium"; }

@media (min-width: 992px) {
  .single-product .tableProduct table td, .single-product .tableProduct table th {
    border: 2px solid #fff; } }

.single-product .tableProduct table thead {
  background-color: #D8D8D8; }
  @media (max-width: 991px) {
    .single-product .tableProduct table thead {
      display: none; } }

.single-product .tableProduct table tbody tr {
  background-color: #F1F1F1; }
  @media (max-width: 991px) {
    .single-product .tableProduct table tbody tr {
      display: block; } }
  @media (max-width: 991px) {
    .single-product .tableProduct table tbody tr:not(:first-child) {
      margin-top: 4rem; } }
  @media (max-width: 991px) {
    .single-product .tableProduct table tbody tr td {
      display: block;
      text-align: right;
      border-bottom: 1px dotted #ccc; } }
  .single-product .tableProduct table tbody tr td:last-child {
    border-bottom: 0; }
  .single-product .tableProduct table tbody tr td:first-child {
    font-family: "Barlow Medium"; }
  @media (max-width: 991px) {
    .single-product .tableProduct table tbody tr td:before {
      content: attr(data-label);
      float: left;
      text-transform: uppercase; } }

.single-product .videoIframeProduct .wrap-video-16-9 {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0; }
  .single-product .videoIframeProduct .wrap-video-16-9 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

@media (min-width: 992px) {
  .single-product .basicInfo-images {
    padding-right: 60px; } }

@media (max-width: 991px) {
  .single-product .basicInfo-info {
    margin-top: 8rem; } }

@media (max-width: 767px) {
  .single-product .basicInfo-info {
    margin-top: 6rem; } }

@media (max-width: 575px) {
  .single-product .basicInfo-info {
    margin-top: 4rem; } }

.single-product .basicInfo-images__big img {
  margin: auto; }

.single-product .basicInfo-images__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 6rem; }
  .single-product .basicInfo-images__list .basicInfo-images__list-image {
    display: inline-block;
    margin: auto;
    border-radius: 50%;
    padding: 10px;
    width: 100px;
    height: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    cursor: pointer;
    border: 3px solid transparent;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    @media (min-width: 576px) {
      .single-product .basicInfo-images__list .basicInfo-images__list-image {
        padding: 18px;
        width: 140px;
        height: 140px; } }
    .single-product .basicInfo-images__list .basicInfo-images__list-image.selected {
      border: 3px solid #EBE4E0; }
    .single-product .basicInfo-images__list .basicInfo-images__list-image img {
      max-height: 100%;
      margin: auto; }

.single-product .basicInfo-info__subtitle {
  margin-top: 2rem; }

.single-product .basicInfo-info__models {
  margin-top: 2rem; }

.single-product .basicInfo-info__shortDescription {
  margin-top: 2rem; }

.single-product .basicInfo-info .button, .single-product .basicInfo-info .ctaFixed .ctaFixed__button, .ctaFixed .single-product .basicInfo-info .ctaFixed__button {
  margin-top: 3rem; }
  .single-product .basicInfo-info .button svg, .single-product .basicInfo-info .ctaFixed .ctaFixed__button svg, .ctaFixed .single-product .basicInfo-info .ctaFixed__button svg {
    display: inline-block;
    margin-right: 1.5rem;
    vertical-align: middle; }
  .single-product .basicInfo-info .button:hover g, .single-product .basicInfo-info .ctaFixed .ctaFixed__button:hover g, .ctaFixed .single-product .basicInfo-info .ctaFixed__button:hover g {
    fill: #EBE4E0; }

.single-product .basicInfo-info__title * {
  color: #EBE4E0; }

.single-product .descriptionInfo h3 {
  margin-bottom: 2rem; }

.single-product .descriptionInfo .secondParagraphProduct {
  margin-top: 4rem; }
  .single-product .descriptionInfo .secondParagraphProduct [class*="col"] {
    border: 3px solid #EBE4E0;
    padding: 3rem;
    border-radius: 30px;
    background-color: #fff; }

@media (max-width: 575px) {
  .single-product .ambientProduct .ambientProduct__carousel-container {
    padding: 0; } }

.single-product .ambientProduct .ambientProduct__title + .ambientProduct__description {
  margin-top: 2rem; }

.single-product .ambientProduct .container-lg + .container-lg {
  margin-top: 6rem; }

@media (max-width: 575px) {
  .single-product .ambientProduct .ambientProduct__carousel .slick-slide {
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; } }

@media (max-width: 575px) {
  .single-product .ambientProduct .ambientProduct__carousel .slick-slide:not(.slick-current) {
    opacity: 0.5; } }

.single-product .ambientProduct .ambientProduct__carousel-item {
  font-family: "Barlow Bold";
  font-size: 1.8rem;
  line-height: 2.8rem;
  text-align: center;
  padding: 0 1.5rem; }
  @media (min-width: 576px) {
    .single-product .ambientProduct .ambientProduct__carousel-item {
      font-size: 2.2rem;
      line-height: 3.2rem; } }
  .single-product .ambientProduct .ambientProduct__carousel-item img {
    margin: auto;
    margin-bottom: 2rem; }

.single-product .ambientProduct .ambientProduct__arrow-prev {
  position: absolute;
  left: 20px;
  top: calc(50% - 15px);
  height: 30px;
  cursor: pointer;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  @media (min-width: 576px) {
    .single-product .ambientProduct .ambientProduct__arrow-prev {
      left: 0; } }
  @media (min-width: 992px) {
    .single-product .ambientProduct .ambientProduct__arrow-prev {
      left: -15px; } }
  .single-product .ambientProduct .ambientProduct__arrow-prev.disabled {
    opacity: 0;
    cursor: default; }
  .single-product .ambientProduct .ambientProduct__arrow-prev:hover {
    -webkit-transform: scale(1.05);
    transform: scale(1.05); }

.single-product .ambientProduct .ambientProduct__arrow-next {
  position: absolute;
  right: 20px;
  top: calc(50% - 15px);
  height: 30px;
  cursor: pointer;
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  @media (min-width: 576px) {
    .single-product .ambientProduct .ambientProduct__arrow-next {
      right: 0; } }
  @media (min-width: 992px) {
    .single-product .ambientProduct .ambientProduct__arrow-next {
      right: -15px; } }
  .single-product .ambientProduct .ambientProduct__arrow-next.disabled {
    opacity: 0;
    cursor: default; }
  .single-product .ambientProduct .ambientProduct__arrow-next:hover {
    -webkit-transform: scale(1.1);
    transform: scale(1.1); }

.single-product .kitSanificazione .row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.single-product .kitSanificazione .kitSanificazione__title + .kitSanificazione__description {
  margin-top: 2rem; }

.single-product .kitSanificazione .kitSanificazione__header {
  margin-bottom: 6rem; }

@media (max-width: 767px) {
  .single-product .kitSanificazione .kitSanificazione__image-big + .kitSanificazione__accessoriesIncluded {
    margin-top: 6rem; } }

.single-product .kitSanificazione .kitSanificazione__accessories-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  .single-product .kitSanificazione .kitSanificazione__accessories-item:not(:first-child) {
    margin-top: 6rem; }
    @media (min-width: 768px) {
      .single-product .kitSanificazione .kitSanificazione__accessories-item:not(:first-child) {
        margin-top: 4rem; } }
  .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-image {
    width: 100%; }
    @media (min-width: 576px) {
      .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-image {
        width: 140px; } }
    .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-image img {
      max-width: 140px;
      max-height: 140px; }
      @media (min-width: 576px) {
        .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-image img {
          margin: auto; } }
  .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-title {
    font-family: "Barlow Bold";
    font-size: 1.6rem;
    line-height: 2.6rem; }
    @media (min-width: 576px) {
      .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-title {
        font-size: 1.8rem;
        line-height: 2.8rem; } }
    @media (min-width: 992px) {
      .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-title {
        font-size: 2rem;
        line-height: 3rem; } }
  .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-title + .kitSanificazione__accessories-item-description {
    margin-top: 1rem; }
  .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-info {
    width: 100%;
    margin-top: 2rem; }
    @media (min-width: 576px) {
      .single-product .kitSanificazione .kitSanificazione__accessories-item .kitSanificazione__accessories-item-info {
        width: calc(100% - 140px);
        padding-left: 1.5rem; } }

.single-product .galelrySecondariaProduct .galelrySecondariaProduct__header + .galelrySecondariaProduct__list {
  margin-top: 4rem; }

.single-product .galelrySecondariaProduct .galelrySecondariaProduct__item {
  display: inline-block; }
  @media (min-width: 992px) {
    .single-product .galelrySecondariaProduct .galelrySecondariaProduct__item:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)) {
      margin-top: 30px; } }

.single-product .galelrySecondariaProduct .galelrySecondariaProduct__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0; }

/*
// ANCHOR: CTA Fixed
*/
.ctaFixed {
  background-color: #EBE4E0;
  padding: 1.5rem 0;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 5;
  -webkit-box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 28px -10px rgba(0, 0, 0, 0.75);
  -webkit-transition: all 0.6s cubic-bezier(0.63, 0.01, 0.35, 0.99);
  transition: all 0.6s cubic-bezier(0.63, 0.01, 0.35, 0.99); }
  @media (min-width: 576px) {
    .ctaFixed {
      padding: 3rem 0; } }
  .ctaFixed:not(.show) {
    bottom: -150px; }
  .ctaFixed .row {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
  .ctaFixed .left {
    font-family: "Barlow Bold";
    text-align: center; }
    @media (max-width: 575px) {
      .ctaFixed .left {
        margin-bottom: 1rem;
        font-size: 1.8rem;
        line-height: 2.8rem; } }
  .ctaFixed .right {
    text-align: center; }
  .ctaFixed .ctaFixed__button {
    border-color: #fff;
    margin-top: 0;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    .ctaFixed .ctaFixed__button:hover {
      background-color: rgba(255, 255, 255, 0.1);
      color: #fff;
      border-color: #fff; }
    .ctaFixed .ctaFixed__button img {
      display: inline-block;
      margin-right: 1rem;
      position: relative;
      top: 1px; }
  .ctaFixed .ctaFixed-popupContainer {
    width: 800px;
    max-width: 100%;
    margin: auto;
    background-color: #fff;
    padding: 6rem 3rem 0;
    color: #000000;
    position: relative; }
    @media (max-width: 768px) {
      .ctaFixed .ctaFixed-popupContainer {
        height: 100vh;
        overflow-y: auto; } }
    @media (min-width: 768px) {
      .ctaFixed .ctaFixed-popupContainer {
        max-width: calc(100% - 60px);
        padding: 6rem 6rem 0;
        overflow-y: auto;
        max-height: 100vh; } }
  .ctaFixed .ctaFixed-popupClose {
    width: 20px;
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    -webkit-transition: all .2s ease-out;
    transition: all .2s ease-out; }
    @media (min-width: 768px) {
      .ctaFixed .ctaFixed-popupClose {
        right: 30px;
        top: 30px; } }
    .ctaFixed .ctaFixed-popupClose:hover {
      -webkit-transform: scale(1.05);
      transform: scale(1.05); }
  .ctaFixed .ctaFixed-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.6);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex; }
    .ctaFixed .ctaFixed-popup:not(.open) {
      display: none; }
  .ctaFixed .ctaFixed-popupTitle {
    font-family: "Baskerville Regular";
    font-size: 2.5rem;
    line-height: 3.5rem;
    margin-bottom: 2rem;
    color: #820000; }
    @media (min-width: 576px) {
      .ctaFixed .ctaFixed-popupTitle {
        font-size: 3.8rem;
        line-height: 4.8rem; } }
  .ctaFixed .ctaFixed-popupDescription {
    margin-bottom: 2rem; }
    @media (min-width: 576px) {
      .ctaFixed .ctaFixed-popupDescription {
        margin-bottom: 4rem; } }
  .ctaFixed .ctaFixed-popupForm {
    margin-bottom: 6rem; }
    .ctaFixed .ctaFixed-popupForm .buttonSend button {
      margin-top: 2rem; }
      @media (min-width: 768px) {
        .ctaFixed .ctaFixed-popupForm .buttonSend button {
          margin-top: 0; } }

body.page-ctaFixed footer {
  padding-bottom: 10rem; }

/*
// ANCHOR: sidebar
*/
.sidebar {
  position: fixed;
  right: -100vw;
  width: 100vw;
  top: 0;
  bottom: 0;
  z-index: 5;
  background-color: #43545B;
  -webkit-box-shadow: -5px 0px 20px -9px rgba(0, 0, 0, 0.75);
  box-shadow: -5px 0px 20px -9px rgba(0, 0, 0, 0.75);
  -webkit-transition: all .2s ease-out;
  transition: all .2s ease-out; }
  .sidebar.open {
    right: 0; }
  @media (min-width: 576px) {
    .sidebar {
      width: 80vw;
      right: -80vw; } }
  @media (min-width: 768px) {
    .sidebar {
      width: 50vw;
      max-width: 800px;
      right: -50vw; } }
  .sidebar .title {
    font-family: "Baskerville Regular";
    color: #fff;
    margin-bottom: 2rem; }
    @media (min-width: 768px) {
      .sidebar .title {
        margin-bottom: 4rem; } }
  .sidebar .sidebar__container {
    padding: 3rem;
    margin-top: 6rem; }
    @media (min-width: 992px) {
      .sidebar .sidebar__container {
        padding: 6rem; } }
  .sidebar .close {
    width: 25px;
    height: 25px;
    position: absolute;
    right: 30px;
    top: 30px;
    cursor: pointer;
    -webkit-transition: all .2s ease-out .4s;
    transition: all .2s ease-out .4s; }
    @media (min-width: 768px) {
      .sidebar .close {
        width: 35px;
        height: 35px; } }
    @media (min-width: 1200px) {
      .sidebar .close {
        width: 40px;
        height: 40px; } }
    @media (min-width: 1600px) {
      .sidebar .close {
        right: 60px; } }
    .sidebar .close .line {
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #fff;
      border-radius: 2px;
      top: calc(50% - 1px);
      -webkit-transition: all .25s ease-out;
      transition: all .25s ease-out; }
    .sidebar .close .line:first-child {
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg); }
    .sidebar .close .line:last-child {
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg); }
